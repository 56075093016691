import React from 'react';
class CreatePayment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            postId: null,
            errorMessage: null
        };
        
    }

    CBSubscrib (UserID, EmailAddress, TradingRoom, durationUnit,Language, Price ) {
        // Définition des modules complémentaires
        const axios = require('axios');
        const sha1 = require('sha1');

        // Définition des variables divers
        var  $Ogone_sha1 = "";
        var $Price = 50;
        var $Currency = 'EUR';
        var $Lang = "fr_FR";

        /* Language for Ogone
                ar_AR (Arabic)
                cs_CZ (Czech)
                dk_DK (Danish)
                de_DE (German)
                el_GR (Greek)
                es_ES (Spanish)
                fi_FI (Finnish)
                fr_FR (French)
                he_IL (Hebrew)
                hu_(HU hungarian)
                it_IT (Italian)
                ja_JP (Japanese)
                ko_KR (Korean)
                nl_BE (Flemish)
                nl_NL (Dutch)
                no_NO (Norwegian)
                pl_PL (Polish)
                pt_PT (Portugese)
                ru_RU (Russian)
                se_SE (Swedish)
                sk_SK (Slovak)
                tr_TR (Turkish)
                zh_CN (Simplified Chinese) */
        switch (Language) {
            case 'fr':
                $Lang = "fr_FR";
                $Currency = 'EUR';
                break;
            case 'de':
                $Lang = "de_DE";
                $Currency = 'EUR';
                break;
            case 'nl':
                $Lang = "nl_NL";
                $Currency = 'EUR';
                break;
            case 'it':
                $Lang = "it_IT";
                $Currency = 'EUR';
                break;
            default :
                $Lang = "en_US";
                $Currency = 'EUR';
                break;
        }

        //Calcul de la période et initialisation des prix
        let date_ob = new Date();
        var $PERIOD_MOMENT = 1;
        var $PERIOD_UNIT = 'm';
        var $PERIOD_NUMBER = 1;
        var $STARTDATE =  new Date();
        const $InitPrice = 100;
        $STARTDATE.setDate($STARTDATE.getDate() + 2);
        var $ENDDATE =  new Date();;
        $ENDDATE.setDate($ENDDATE.getDate() + 2);
        //$PERIOD_MOMENT = date_ob.$STARTDATE;
        $PERIOD_MOMENT = $STARTDATE.getDate();
        console.log( "Period : " + $PERIOD_MOMENT);

        switch (durationUnit) {
            case 'M':
                $PERIOD_UNIT = 'm';
                $PERIOD_NUMBER = 1;
                //$STARTDATE.setMonth($STARTDATE.getMonth() + 1);
                $ENDDATE.setFullYear($ENDDATE.getFullYear() + 1);
                $Price = Math.round(Price * 2.780)*1000;
                break;
            case 'BM':
                $PERIOD_UNIT = 'm';
                $PERIOD_NUMBER = 2;
                //$STARTDATE.setMonth($STARTDATE.getMonth() + 2);
                $ENDDATE.setFullYear($ENDDATE.getFullYear() + 3);
                $Price = Math.round(Price * 5.125)*1000;
                break;
            case 'T':
                $PERIOD_UNIT = 'm';
                $PERIOD_NUMBER = 3;
                //$STARTDATE.setMonth($STARTDATE.getMonth() + 3);
                $ENDDATE.setFullYear($ENDDATE.getFullYear() + 3);
                $Price = Math.round(Price * 7.275)*1000;
                break;
            case 'S':
                $PERIOD_UNIT = 'm';
                $PERIOD_NUMBER = 6;
                //$STARTDATE.setMonth($STARTDATE.getMonth() + 6);
                $ENDDATE.setFullYear($ENDDATE.getFullYear() + 3);
                $Price = Math.round(Price * 13.750)*1000;
                break;
            case 'A':
                $PERIOD_UNIT = 'm';
                $PERIOD_NUMBER = 12;
                //$STARTDATE.setFullYear($STARTDATE.getFullYear() + 1);
                $ENDDATE.setFullYear($ENDDATE.getFullYear() + 3);
                $Price = Math.round(Price * 25)*1000;
                break;
            default :
                $PERIOD_UNIT = 'm';
                $PERIOD_NUMBER = 1;
                //$STARTDATE.setMonth($ENDDATE.getMonth() + 1);
                $ENDDATE.setFullYear($ENDDATE.getFullYear() + 1);
                $Price = Math.round(Price * 2.794)*1000;
                break;
        }
        //Adapt the Date format to Ogone requirement
        $STARTDATE = $STARTDATE.toISOString().
            replace(/T/, ' ').      // replace T with a space
            replace(/\..+/, '') ;
        $ENDDATE = $ENDDATE.toISOString().
        replace(/T/, ' ').      // replace T with a space
        replace(/\..+/, '') ;

        //Forcage du Prix et currency pour Test
        //$Price = 100;
        //$Currency = "CHF";

        //const payment methods/page specifics
        const $PSPID = 'VegaTradersPROD'; //'EFTTEST'
        const $passphrase = 'Akiba@Vegatraders' //'818972ca-d020-4f62-86cd-ef00827b496f'; // 'Akiba20230829!'
        const $pm = "CREDIT CARD";
        const $brand = "VISA";
        const $unique_id = Math.round(Math.random(790000, 795689596)*1000000000);   // Generation d'un numéro unique de subscription aléatoire
        const $itemname = 'Efficient-Trading web subcription';
        const $itemdesc = TradingRoom + "|" + durationUnit + "|" + $PERIOD_NUMBER;
        const $duration =  $PERIOD_NUMBER + "|" + durationUnit;
        var $STATUS = "1";

        // Information layout
        const $Title = "Payment via Credit Card Efficient-Trading";
        const $logo = 'http://www.vega-traders.com/images/logoOnOgone.png';
        const $BGColor = "#010000";

       
        // Post payment redirection
        // If paiement accepted point customer to the room access
        const $accept_url = 'https://www.efficient-trading.com';
        //If paiement cancel or reject point to the subscription page
        const $cancelurl = 'https://www.efficient-trading.com/abonnement';
        const $errorurl = 'https://www.efficient-trading.com/abonnement';

        // customer details
        const $email = EmailAddress;
        const $com = $itemname + "/" + $itemdesc + '/' + $duration;
        const $complus = $itemname + '/' + $itemdesc + '/' + $duration + '/' + $email;
        const $SUB_COM = $itemname + '/' + $itemdesc + '/' + $duration + '/' + $email;

        // Generate the SHA1 signature for subscription or immédiate order
        // If you add any parameters in  Ogone_sha1 order by Alphabet order.
        $Ogone_sha1 = "ACCEPTURL=" + $accept_url + $passphrase +
            "AMOUNT=" + $InitPrice + $passphrase +
            "BGCOLOR=" + $BGColor + $passphrase +
            "CANCELURL=" + $cancelurl + $passphrase +
            "COM=" + $com + $passphrase +
            "COMPLUS=" + $complus + $passphrase +
            "CURRENCY=" + $Currency + $passphrase +
            "DECLINEURL=" + $errorurl + $passphrase +
            "EMAIL=" + $email + $passphrase +
            "EXCEPTIONURL=" + $errorurl + $passphrase +
            "LANGUAGE=" + $Lang + $passphrase +
            "LOGO=" + $logo + $passphrase +
            "ORDERID=" + $unique_id + $passphrase +
            "PM=" + $pm + $passphrase +
            "PSPID=" + $PSPID + $passphrase +
            "SUBSCRIPTION_ID=" + $unique_id + $passphrase +
            "SUB_AMOUNT=" + $Price + $passphrase +
            "SUB_COM=" + $SUB_COM + $passphrase +
            "SUB_COMMENT=Efficient_Trading souscription" + $passphrase +
            "SUB_CUR=" + $Currency + $passphrase +
            "SUB_ENDDATE=" + $ENDDATE + $passphrase +
            "SUB_ORDERID=" + $unique_id + $passphrase +
            "SUB_PERIOD_MOMENT=" + $PERIOD_MOMENT + $passphrase +
            "SUB_PERIOD_NUMBER=" + $PERIOD_NUMBER + $passphrase +
            "SUB_PERIOD_UNIT=" + $PERIOD_UNIT + $passphrase +
            "SUB_STARTDATE=" + $STARTDATE + $passphrase +
            "SUB_STATUS=" + $STATUS + $passphrase +
            "TITLE=" + $Title + $passphrase;
        //Creating the signature
        $Ogone_sha1 = sha1($Ogone_sha1);

        // Send FormData to Ogone
        openWindowWithPost("https://secure.ogone.com/ncol/prod/orderstandard.asp", {
            'TITLE':$Title,
            'BGCOLOR':$BGColor,
            'LOGO':$logo,
            'LANGUAGE':$Lang,
            'ACCEPTURL':$accept_url,
            'CANCELURL':$cancelurl,
            'DECLINEURL':$errorurl,
            'EXCEPTIONURL':$errorurl,
            'ORDERID':$unique_id.toString(),
            'AMOUNT':$InitPrice.toString(),
            'CURRENCY':$Currency,
            'SUBSCRIPTION_ID':$unique_id.toString(),
            'SUB_AMOUNT':$Price.toString(),
            'SUB_CUR': $Currency,
            'SUB_COM':$SUB_COM,
            'SUB_ORDERID':$unique_id.toString(),
            'SUB_PERIOD_NUMBER':$PERIOD_NUMBER.toString(),
            'SUB_PERIOD_UNIT':$PERIOD_UNIT,
            'SUB_PERIOD_MOMENT':$PERIOD_MOMENT.toString(),
            'SUB_STARTDATE':$STARTDATE,
            'SUB_ENDDATE':$ENDDATE,
            'SUB_STATUS': $STATUS,
            'SUB_COMMENT':'Efficient_Trading souscription',
            'EMAIL':$email,
            'COM':$com,
            'COMPLUS':$complus,
            'PM':$pm,
            'PSPID':$PSPID,
            'SHASIGN':$Ogone_sha1
            });
        // Redirection vers Ogone
        function openWindowWithPost(url, data) {
            //console.log(data);
            var form = document.createElement("form");
            //form.target = "_blank";
            form.method = "POST";
            form.action = url;
            form.style.display = "none";

            for (var key in data) {
                var input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = data[key];
                form.appendChild(input);
            }
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        }

    }
    
}

export { CreatePayment }; 