import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import cookie from "js-cookie";
import axios from "axios";
import "./../styles/Autologout.scss";

// import './styles.css'
const secondsInOneHour = 60 * 60;
const timeout = 20 * 1000 * secondsInOneHour;
const promptBeforeIdle = 10 * 1000;

export default function AutoLogouts() {
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    serverCheck();
  }, []);

  // --------------To check call server hit function in every given duration
  const serverCheck = () => {
    
    let interval = setInterval(() => {
      
      handleServerCheck();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  };

  // ---------------Called when logout api is successfully hit to clear cookies and token in it
  const removeCookie = (key) => {
    if (window !== "undefined") {
      cookie.remove(key, { expires: 1 });
    }
  };
// ---------------To delete or clear cookies or token when server is down 
  function deleteCookie(cookieName) {
   
    cookie.remove(cookieName, { expires: 1 });
  }

// -----------------To Hit api to server to check, If down then it will throw error 
  const handleServerCheck = async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/user/server-check`,
      withCredentials: true,
    }).catch((err) => {
      if (err?.message === "Network Error") { {/** error then to redirect to login page and clear cookies and token */}
        deleteCookie("jwt");
        window.location = "/Login";
        alert("Logged out due to Internal Server Issue");
      }
      console.log(err);
    });
  };

  // ---------------Called when the webpage goes into idle state
  const handleLogout = async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/user/logout`,
      withCredentials: true,
    })
      .then(() => removeCookie("jwt"))
      .catch((err) => console.log(err));

    window.location = "/Login";
  };

  // ------------When idle state calls func
  const onIdle = () => {
    setOpen(false);
    handleLogout();
  };

  // ---------------Function for active state
  const onActive = () => {
    setOpen(false);
  };

  // --------------Function for promp before going to idle state
  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  
  
  return (
    <>
      <div
        className="modal"
        style={{
          display: open ? "flex" : "none",
        }}
      >
        <h3>Are you still here?</h3>
        <p>Logging out in {remaining} seconds</p>
        <button onClick={handleStillHere}>Im still here</button>
      </div>
    </>
  );
}
