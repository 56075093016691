import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
import moment from "moment";

// js et scss
import {
  updateAdress,
  updateAge,
  updateCategorieSP,
  updateCodePostal,
  updateComplmtAdress,
  updateEmail,
  updateEtatProv,
  updateFirstName,
  updateLastName,
  updatePays,
  updatePhone,
  updateRaisonS,
  updateSecondName,
  updateStatut,
  updateVille,
} from "../../actions/user.actions";

import DateParser from "../Utils";

import "./../../styles/UpdateProfil.scss";
import { LanguageContext } from "../AppContext";
import axios from "axios";
import { home } from "../../Utils/Models";
import Loader from "../Loader";

const UpdateProfil = () => {
  const userData = useSelector((state) => state.userReducer);
  const [updateForm, setUpdateForm] = useState(false);
  const dispatch = useDispatch();
  const [lastName, setlastName] = useState(userData.lastName);
  const [firstName, setfirstName] = useState(userData.firstName);
  const [secondName, setsecondName] = useState(userData.secondName);
  const [email, setEmail] = useState(userData.email);
  const [adress, setAdress] = useState(userData.adress);
  const [complmtAdress, setComplmtAdress] = useState(userData.complmtAdress);
  const [ville, setVille] = useState(userData.ville);
  const [codePostal, setCodePostal] = useState(userData.codePostal);
  const [etatProv, setEtatProv] = useState(userData.etatProv);
  const [pays, setPays] = useState(userData.pays);
  const [phone, setPhone] = useState(userData.phone);
  const [age, setAge] = useState(userData.age);
  const [categorieSP, setCategorieSP] = useState(userData.categorieSP);
  const [statut, setStatut] = useState(userData.statut);
  const [raisonS, setRaisonS] = useState(userData.raisonS);
  const [connu, setConnu] = useState(userData.connu);
  const [broker, setBroker] = useState(userData.broker);
  const [userIp, setUserIp] = useState(userData.userIp);
  const [ipAddress, setIpAddress] = useState("");
  const { language } = useContext(LanguageContext);
  const [loading, isLoading] = useState(false);
  const [dataEn, setDataEn] = useState([]);
  const [dataFr, setDataFr] = useState([]);
  const [regDataEn, setRegDataEn] = useState([]);
  const [regDataFr, setRegDataFr] = useState([]);
  const [isLangSwitch, setIsLangSwitch] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const handleUpdate = () => {
    dispatch(updateLastName(userData._id, lastName));
    dispatch(updateFirstName(userData._id, firstName));
    dispatch(updateSecondName(userData._id, secondName));
    dispatch(updateEmail(userData._id, email));
    dispatch(updateAdress(userData._id, adress));
    dispatch(updateComplmtAdress(userData._id, complmtAdress));
    dispatch(updateVille(userData._id, ville));
    dispatch(updateCodePostal(userData._id, codePostal));
    dispatch(updateEtatProv(userData._id, etatProv));
    dispatch(updatePays(userData._id, pays));
    dispatch(updatePhone(userData._id, phone));
    dispatch(updateAge(userData._id, age));
    dispatch(updateCategorieSP(userData._id, categorieSP));
    dispatch(updateStatut(userData._id, statut));
    dispatch(updateRaisonS(userData._id, raisonS));
    setUpdateForm(false);
  };

  useEffect(() => {
    fetchIp();
    getMypageData();
    getInscriptionData();
  }, []);
  useEffect(() => {
    if (!isInitialRender) {
      setIsLangSwitch(true);
      setTimeout(() => {
        setIsLangSwitch(false);
      }, 1000);
    } else {
      setIsInitialRender(false);
    }
  }, [language]);
  const fetchIp = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      setIpAddress(data.ip);
    } catch (error) {
      console.error(error);
    }
  };
  const handleCancelSubscription = (e) => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/user/cancle-subscription/${userData?._id}/${ipAddress}/${language}`,
      withCredentials: true,
    })
      .then((res) => {
        alert(
          language === "Fr"
            ? "L'e-mail d'annulation de l'abonnement est envoyé"
            : "Email for Subscription cancellation is sent"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // ---- To get page contents
  const getMypageData = () => {
    isLoading(true);
    home
      .getMyprofileData()
      .then((response) => {
        setDataEn(response?.language?.en);
        setDataFr(response?.language?.fr);
        isLoading(false);
      })
      .catch((error) => {
        console.log("Error while getting data", error);
      });
  };

  const getInscriptionData = () => {
    isLoading(true);
    home
      .getInscriptionData()
      .then((response) => {
        setRegDataEn(response?.language?.en);
        setRegDataFr(response?.language?.fr);
        isLoading(false);
      })
      .catch((error) => {
        console.log("Error while getting data", error);
      });
  };
  // --For HTML to text conversion
  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <>
      {isLangSwitch && (
        <div className="loader-background">
          <span className="loader-text">Switching...</span>
        </div>
      )}
      {!loading ? (
        <div className="profil-page">
          <div className="profil-nav">
            <NavLink
              to="/profil"
              className="nav-link"
              style={{ display: "flex" }}
            >
              <span
                dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.profile_name)
                    : createMarkup(dataEn?.profile_name)
                }
              />
              {":  "}
              <b>
                {userData.lastName} {userData.firstName}
              </b>
            </NavLink>
            <NavLink
              to="abonnement"
              className="nav-link"
              dangerouslySetInnerHTML={
                language == "Fr"
                  ? createMarkup(dataFr?.abonnements)
                  : createMarkup(dataEn?.abonnements)
              }
            />

            <NavLink
              to="faq"
              className="nav-link"
              dangerouslySetInnerHTML={
                language == "Fr"
                  ? createMarkup(dataFr?.besoin)
                  : createMarkup(dataEn?.besoin)
              }
            />
          </div>
          {/* si form est faux */}
          {!updateForm && (
            <>
              <div className="affichage-profil">
                <div className="infos-nonmodif">
                  <div className="info-nm">
                    <div className="info-nm-1">
                      <h4
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(dataFr?.email)
                            : createMarkup(dataEn?.email)
                        }
                      />
                      <p>{userData.email}</p>
                      <p
                        className="text"
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(dataFr?.email_description)
                            : createMarkup(dataEn?.email_description)
                        }
                      />
                    </div>
                    <div className="info-nm-2">
                      <NavLink
                        to="/contact"
                        className="profil-contact"
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(dataFr?.email_button_name)
                            : createMarkup(dataEn?.email_button_name)
                        }
                      />
                    </div>
                  </div>
                  <div className="info-nm">
                    <div className="info-nm-1">
                      <h4
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(dataFr?.password)
                            : createMarkup(dataEn?.password)
                        }
                      />
                      <p>*******</p>
                      <p
                        className="text"
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(dataFr?.password_description)
                            : createMarkup(dataEn?.password_description)
                        }
                      />
                    </div>
                    <div className="info-nm-2">
                      <button
                        type="button"
                        className="profil-contact"
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(dataFr?.password_button_name)
                            : createMarkup(dataEn?.password_button_name)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="title-heading">
                  <h1
                    dangerouslySetInnerHTML={
                      language == "Fr"
                        ? createMarkup(dataFr?.password_button_name)
                        : createMarkup(dataEn?.password_button_name)
                    }
                  />
                </div>
                <div className="infos-profil">
                  <div className="infos-profil-row">
                    <div className="info">
                      <h4 className="h4-text d-flex ">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.pesudo)
                              : createMarkup(dataEn?.pesudo)
                          }
                        />
                      </h4>
                      <p>{userData.pseudo}</p>
                    </div>
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.nom)
                              : createMarkup(dataEn?.nom)
                          }
                        />
                      </h4>
                      <p>{userData.lastName}</p>
                    </div>
                  </div>
                  <div className="infos-profil-row">
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.prenom)
                              : createMarkup(dataEn?.prenom)
                          }
                        />
                      </h4>
                      <p>{userData.firstName}</p>
                    </div>
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.deuxieme_prenom)
                              : createMarkup(dataEn?.deuxieme_prenom)
                          }
                        />
                      </h4>
                      <p>{userData.secondName}</p>
                    </div>
                  </div>
                  <div className="infos-profil-row">
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.adresse)
                              : createMarkup(dataEn?.adresse)
                          }
                        />
                      </h4>
                      <p>{userData.adress}</p>
                    </div>
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.ville)
                              : createMarkup(dataEn?.ville)
                          }
                        />
                      </h4>
                      <p>{userData.ville}</p>
                    </div>
                  </div>
                  <div className="infos-profil-row">
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.adresse)
                              : createMarkup(dataEn?.adresse)
                          }
                        />
                      </h4>
                      <p>{userData.adress}</p>
                    </div>
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.provience)
                              : createMarkup(dataEn?.provience)
                          }
                        />
                      </h4>
                      <p>{userData.etatProv}</p>
                    </div>
                  </div>
                  <div className="infos-profil-row">
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.code_postal)
                              : createMarkup(dataEn?.code_postal)
                          }
                        />
                      </h4>
                      <p>{userData.codePostal}</p>
                    </div>
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.telephone)
                              : createMarkup(dataEn?.telephone)
                          }
                        />
                      </h4>
                      <p>{userData.phone}</p>
                    </div>
                  </div>
                  <div className="infos-profil-row">
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.pays)
                              : createMarkup(dataEn?.pays)
                          }
                        />
                      </h4>
                      <p>{userData.pays}</p>
                    </div>
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.categorie_socio)
                              : createMarkup(dataEn?.categorie_socio)
                          }
                        />
                      </h4>
                      <p>{userData.categorieSP}</p>
                    </div>
                  </div>
                  <div className="infos-profil-row">
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.tranche)
                              : createMarkup(dataEn?.tranche)
                          }
                        />
                      </h4>
                      <p>{userData.age}</p>
                    </div>
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.categorie_socio)
                              : createMarkup(dataEn?.categorie_socio)
                          }
                        />
                      </h4>
                      <p>{userData.categorieSP}</p>
                    </div>
                  </div>
                  <div className="infos-profil-row">
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.comment)
                              : createMarkup(dataEn?.comment)
                          }
                        />
                      </h4>
                      <p>{userData.connu}</p>
                    </div>
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.raison_sociale)
                              : createMarkup(dataEn?.raison_sociale)
                          }
                        />
                      </h4>
                      <p>{userData.raisonS}</p>
                    </div>
                  </div>
                  <div className="infos-profil-row">
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.votre_broker)
                              : createMarkup(dataEn?.votre_broker)
                          }
                        />
                      </h4>
                      <p>{userData.broker}</p>
                    </div>
                    <div className="info">
                      <h4 className="h4-text">
                        <IoMdArrowDropright />
                        <span
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.votre_ip)
                              : createMarkup(dataEn?.votre_ip)
                          }
                        />
                      </h4>
                      <p>{userData.userIp}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* si form est vrai */}
          {updateForm && (
            <>
              <div className="modif-titre">
                <h1>Modification du profil</h1>
              </div>
              <div className="modif-input">
                <div className="profil-input">
                  <label htmlFor="Nom">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.nom)
                          : createMarkup(dataEn?.nom)
                      }
                    />
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={userData.lastName}
                    onChange={(e) => setlastName(e.target.value)}
                    required
                  />
                </div>
                <div className="profil-input">
                  <label htmlFor="Prénom">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.prenom)
                          : createMarkup(dataEn?.prenom)
                      }
                    />
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={userData.firstName}
                    onChange={(e) => setfirstName(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="modif-input">
                <div className="profil-input">
                  <label htmlFor="Deuxième Prénom">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.deuxieme_prenom)
                          : createMarkup(dataEn?.deuxieme_prenom)
                      }
                    />
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={userData.secondName}
                    onChange={(e) => setsecondName(e.target.value)}
                  />
                </div>
                <div className="profil-input">
                  <label htmlFor="Adresse">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.adresse)
                          : createMarkup(dataEn?.adresse)
                      }
                    />
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={userData.adress}
                    onChange={(e) => setAdress(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="modif-input">
                <div className="profil-input">
                  <label htmlFor="Complément d'adresse">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.complement_adresse)
                          : createMarkup(dataEn?.complement_adresse)
                      }
                    />
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={userData.complmtAdress}
                    onChange={(e) => setComplmtAdress(e.target.value)}
                  />
                </div>
                <div className="profil-input">
                  <label htmlFor="Ville">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.ville)
                          : createMarkup(dataEn?.ville)
                      }
                    />
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={userData.ville}
                    onChange={(e) => setVille(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="modif-input">
                <div className="profil-input">
                  <label htmlFor="Etat / Province">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.provience)
                          : createMarkup(dataEn?.provience)
                      }
                    />
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={userData.etatProv}
                    onChange={(e) => setEtatProv(e.target.value)}
                  />
                </div>
                <div className="profil-input">
                  <label htmlFor="Code postal">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.code_postal)
                          : createMarkup(dataEn?.code_postal)
                      }
                    />
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={userData.codePostal}
                    onChange={(e) => setCodePostal(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="modif-input">
                <div className="profil-input">
                  <label htmlFor="Pays">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.pays)
                          : createMarkup(dataEn?.pays)
                      }
                    />
                    <span>*</span>
                  </label>
                  <select
                    type="text"
                    defaultValue={userData.pays}
                    onChange={(e) => setPays(e.target.value)}
                    required
                  >
                    <option value=""></option>
                    {(language !== "Fr"
                      ? regDataEn?.inscription_page_form_contact_details_pays_values
                      : regDataFr?.inscription_page_form_contact_details_pays_values
                    )?.map((item) => {
                      return <option value="espagne">{item?.value}</option>;
                    })}
                    {/* <option value="royaume-uni">Royaume-Uni</option>
                <option value="canada">Canada</option>
                <option value="japon">Japon</option>
                <option value="allemagne">Allemagne</option>
                <option value="italie">Italie</option>
                <option value="chine">Chine</option>
                <option value="australie">Australie</option>
                <option value="suisse">Suisse</option>
                <option value="inde">Inde</option>
                <option value="bresil">Brésil</option>
                <option value="argentine">Argentine</option>
                <option value="mexique">Mexique</option>
                <option value="afrique-du-sud">Afrique du Sud</option> */}
                  </select>
                </div>
                <div className="profil-input">
                  <label htmlFor="Téléphone">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.telephone)
                          : createMarkup(dataEn?.telephone)
                      }
                    />
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={userData.phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>

              <div className="modif-input">
                <div className="profil-input">
                  <label htmlFor="Age">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.age)
                          : createMarkup(dataEn?.age)
                      }
                    />
                    <span>*</span>
                  </label>
                  <select
                    type="text"
                    defaultValue={userData.age}
                    onChange={(e) => setAge(e.target.value)}
                  >
                    {" "}
                    <option value=""></option>
                    {(language !== "Fr"
                      ? regDataEn?.inscription_page_form_contact_details_tranche_values
                      : regDataFr?.inscription_page_form_contact_details_tranche_values
                    )?.map((item) => {
                      return <option value="espagne">{item?.value}</option>;
                    })}
                  </select>
                </div>
                <div className="profil-input">
                  <label
                    htmlFor="Catégorie"
                    dangerouslySetInnerHTML={
                      language == "Fr"
                        ? createMarkup(dataFr?.categorie_socio)
                        : createMarkup(dataEn?.categorie_socio)
                    }
                  />
                  <select
                    type="text"
                    defaultValue={categorieSP}
                    onChange={(e) => setCategorieSP(e.target.value)}
                  >
                    <option value=""></option>
                    {(language !== "Fr"
                      ? regDataEn?.inscription_page_form_contact_details_category_values
                      : regDataFr?.inscription_page_form_contact_details_category_values
                    )?.map((item) => {
                      return <option value="espagne">{item?.value}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="modif-input">
                <div className="profil-input">
                  <label htmlFor="Statut">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.statut)
                          : createMarkup(dataEn?.statut)
                      }
                    />
                    <span>*</span>
                  </label>
                  <select
                    type="text"
                    defaultValue={userData.statut}
                    onChange={(e) => setStatut(e.target.value)}
                    required
                  >
                    <option value=""></option>
                    {(language !== "Fr"
                      ? regDataEn?.inscription_page_form_contact_details_category_values
                      : regDataFr?.inscription_page_form_contact_details_category_values
                    )?.map((item) => {
                      return <option value="espagne">{item?.value}</option>;
                    })}
                    
                  </select>
                </div>
                <div className="profil-input">
                  <label htmlFor="Raison sociale">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.raison_sociale)
                          : createMarkup(dataEn?.raison_sociale)
                      }
                    />
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={userData.raisonS}
                    onChange={(e) => setRaisonS(e.target.value)}
                  />
                </div>
              </div>
              <div className="modif-input">
                <div className="profil-input">
                  <label htmlFor="Comment nous avez-vous connu?">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.comment)
                          : createMarkup(dataEn?.comment)
                      }
                    />
                    <span>*</span>
                  </label>
                  <select
                    type="text"
                    defaultValue={userData.connu}
                    onChange={(e) => setConnu(e.target.value)}
                    required
                  >
                    <option value=""></option>
                    {(language !== "Fr"
                      ? regDataEn?.inscription_page_form_contact_details_how_did_you_know_us_values
                      : regDataFr?.inscription_page_form_contact_details_how_did_you_know_us_values
                    )?.map((item) => {
                      return <option value="espagne">{item?.value}</option>;
                    })}
                  </select>
                </div>
                <div className="profil-input">
                  <label htmlFor="Votre broker">
                    <span
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.votre_broker)
                          : createMarkup(dataEn?.votre_broker)
                      }
                    />{" "}
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={userData.broker}
                    onChange={(e) => setBroker(e.target.value)}
                    required
                  />
                </div>
              </div>
            </>
          )}

          {/* gestion des boutons*/}
          {updateForm ? (
            <div className="btn-modif-placement">
              <button
                onClick={handleUpdate}
                className="btn-modif"
                dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.valider_les_modification)
                    : createMarkup(dataEn?.valider_les_modification)
                }
              />
            </div>
          ) : (
            <div className="btn-modif-placement">
              <button
                onClick={setUpdateForm}
                className="btn-modif"
                dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.modifier_les_informations)
                    : createMarkup(dataEn?.modifier_les_informations)
                }
              />
            </div>
          )}
          {/* -------To show the current subscription and cancel subscription button */}
          <div className="cancel-block">
            <div>
              <h2
                dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.subscription)
                    : createMarkup(dataEn?.subscription)
                }
              />
              {/*
          <h4>
            Forex :{" "}
            {moment(
              moment(userData.aboForex).format("YYYY-MM-DD")
            ).isSameOrAfter(moment().format("YYYY-MM-DD")) ? (
              DateParser(userData.aboForex)
            ) : (
              <span style={{ color: "red" }}>not subscribed</span>
            )}
          </h4>
            */}
              <h4>
                Indices:{" "}
                {moment(
                  moment(userData.aboIndices).format("YYYY-MM-DD")
                ).isSameOrAfter(moment().format("YYYY-MM-DD")) ? (
                  DateParser(userData.aboIndices)
                ) : (
                  <span style={{ color: "red" }}>
                    {language === "Fr" ? "Non abonné" : "not subscribed"}
                  </span>
                )}
              </h4>
            </div>
            {(moment(
              moment(userData.aboForex).format("YYYY-MM-DD")
            ).isSameOrAfter(moment().format("YYYY-MM-DD")) ||
              moment(
                moment(userData.aboIndices).format("YYYY-MM-DD")
              ).isSameOrAfter(moment().format("YYYY-MM-DD"))) && (
              <div>
                <button
                  className="btn-cancel"
                  onClick={() => handleCancelSubscription()}
                  dangerouslySetInnerHTML={
                    language == "Fr"
                      ? createMarkup(dataFr?.unsubscribe)
                      : createMarkup(dataEn?.unsubscribe)
                  }
                />
              </div>
            )}
          </div>
          <div className="profil-creation">
            <h4>
              <span
                dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.membre_depuis_le)
                    : createMarkup(dataEn?.membre_depuis_le)
                }
              />{" "}
              :{DateParser(userData.createdAt)}
            </h4>
            <h4>
              <span
                dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.profil_mis_a_jour_le)
                    : createMarkup(dataEn?.profil_mis_a_jour_le)
                }
              />{" "}
              : {DateParser(userData.updatedAt)}
            </h4>
          </div>
        </div>
      ) : (
        <Loader loading={loading} />
      )}
    </>
  );
};

export default UpdateProfil;
