import React, { useContext, useEffect, useState } from "react";

// js et scss
import "../../styles/MentionCVG.scss";
import { LanguageContext } from "../../components/AppContext";
import { home } from "../../Utils/Models/index";
import Loader from "../../components/Loader";

export default function () {
  const { language } = useContext(LanguageContext);
  const [dataEn, setDataEn] = useState();
  const [dataFr, setDataFr] = useState();
  const [loading,isLoading]=useState(false)
  const [isLangSwitch, setIsLangSwitch] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    getLegalNotice();
   
  }, []);
  useEffect(()=>{
    if(!isInitialRender){
      setIsLangSwitch(true);
      setTimeout(() => {
        setIsLangSwitch(false);
      }, 1000);
    }else{
      setIsInitialRender(false);
    }
  },[language])
//----------------------------To all data of legal notice
  const getLegalNotice = () => {
    isLoading(true)
    home
      .getLegalNoticeData()
      .then((response) => {
        setDataEn(response?.language?.en);
        setDataFr(response?.language?.fr);
        isLoading(false)
      })
      .catch((error) => {
        console.log("Error while getting data", error);
      });
  };

  function createMarkup(data) {
    return { __html: data };
  }

  return (
    <>
    {isLangSwitch && <div className="loader-background"><span className="loader-text">Switching...</span></div>}
    {!loading?<div className="mention-container">
      <h1 className="mention-title" dangerouslySetInnerHTML={language == "Fr"?createMarkup(dataFr?.legal_notices_page_header_title):createMarkup(dataEn?.legal_notices_page_header_title)}>
      </h1>
      <div className="mention-placement">
        {language == "Fr"
          ? dataFr?.legal_notices_page_data?.map((item) => {
              return (
                <div className="card" key={item?.id}>
                  <h2 className="mention-sous-titre" dangerouslySetInnerHTML={createMarkup(item?.title)}></h2>
                  <p
                    className="mention-text"
                    dangerouslySetInnerHTML={createMarkup(item?.description)}
                  />
                </div>
              );
            })
          : dataEn?.legal_notices_page_data?.map((item) => {
              return (
                <div className="card" key={item?.id}>
                  <h2 className="mention-sous-titre" dangerouslySetInnerHTML={createMarkup(item?.title)}></h2>

                  <p
                    className="mention-text"
                    dangerouslySetInnerHTML={createMarkup(item?.description)}
                  />
                </div>
              );
            })}
      </div>
    </div>:<Loader loading={loading}/>}
    </>
  );
}
