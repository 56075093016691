import React, { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { getUser } from '../../actions/user.actions';
import { LanguageContext, UidContext } from '../../components/AppContext';
import { ProductID } from '../../components/AppContext';
import { Redirect } from 'react-router-dom';
import './../../styles/Salle-forex.scss';

// import js et scss et images
import openposition from './../../images/salles/openpositions.png';
import barretrade from './../../images/salles/trade-exemple.png';
import publicAnnonce from './../../images/salles/Public announce.png';
import cirque from './../../images/salles/cirque.png';
import png_cirque from './../../images/salles/cirque.png';
import png_Green from './../../images/salles/green.png';
import png_Orange from './../../images/salles/orange.png';
import png_Red from './../../images/salles/red.png';

//Import des sons
import Sound_default from './../../audio/im.mp3';
import Sound_TheEFTSignals from './../../audio/TheEFTSignals.mp3';
import Sound_ThinkBigProfit from './../../audio/ThinkBigProfit.mp3';
import Sound_ThinkLittleProfit from './../../audio/ThinkLittleProfit.mp3';
import Sound_australiandollar from './../../audio/australiandollar.mp3';
import Sound_buyAustraliandollar from './../../audio/buyAustraliandollar.mp3';
import Sound_buyDollarswissfranc from './../../audio/buyDollarswissfranc.mp3';
import Sound_buyDollaryen from './../../audio/buyDollaryen.mp3';
import Sound_buyEurodollar from './../../audio/buyEurodollar.mp3';
import Sound_buyEuropound from './../../audio/buyEuropound.mp3';
import Sound_buyEuroyen from './../../audio/buyEuroyen.mp3';
import Sound_circus from './../../audio/circus.mp3';
import Sound_circus_1 from './../../audio/circus_1.mp3';
import Sound_circus_2 from './../../audio/circus_2.mp3';
import Sound_dollarswissfranc from './../../audio/dollarswissfranc.mp3';
import Sound_dollaryen from './../../audio/dollaryen.mp3';
import Sound_eurodollar from './../../audio/eurodollar.mp3';
import Sound_europond from './../../audio/europond.mp3';
import Sound_europound from './../../audio/europound.mp3';
import Sound_euroyen from './../../audio/euroyen.mp3';
import Sound_exit from './../../audio/exit.mp3';
import Sound_firework from './../../audio/firework.mp3';
import Sound_goLong from './../../audio/goLong.mp3';
import Sound_goShort from './../../audio/goShort.mp3';
import Sound_gold from './../../audio/gold.mp3';
import Sound_goodbye from './../../audio/goodbye.mp3';
import Sound_green from './../../audio/green.mp3';
import Sound_im from './../../audio/im.mp3';
import Sound_message from './../../audio/message.mp3';
import Sound_new_message from './../../audio/new-message.mp3';
import Sound_objective from './../../audio/objective.mp3';
import Sound_orange from './../../audio/orange.mp3';
import Sound_ready from './../../audio/ready.mp3';
import Sound_red from './../../audio/red.mp3';
import Sound_reinforcePosition from './../../audio/reinforcePosition.mp3';
import Sound_reinforcedPosition from './../../audio/reinforcedPosition.mp3';
import Sound_sellAustraliandollar from './../../audio/sellAustraliandollar.mp3';
import Sound_sellDollarswissfranc from './../../audio/sellDollarswissfranc.mp3';
import Sound_sellDollaryen from './../../audio/sellDollaryen.mp3';
import Sound_sellEurodollar from './../../audio/sellEurodollar.mp3';
import Sound_sellEuropound from './../../audio/sellEuropound.mp3';
import Sound_sellEuroyen from './../../audio/sellEuroyen.mp3';
import Sound_stayAside from './../../audio/stayAside.mp3';
import Sound_stop_entry_price from './../../audio/stop_entry_price.mp3';
import Sound_Gamble from './../../audio/im.mp3';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
//var SalleContext;

const FOREX = () => {
  const pannelRef = useRef(null);
  const chatRef = useRef(null);
  const product = useContext(ProductID);
  const uid = useContext(UidContext);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userReducer);
  const isAdmin = uid && userData.role === 'admin' ? true : false;
  const moment = require('moment')
  const pseudo = userData.pseudo
  const abo_EndDate = userData.aboforex;
  const { language } = useContext(LanguageContext);
  const [isLangSwitch, setIsLangSwitch] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);


  useEffect(() => {
    if (!isInitialRender) {
      setIsLangSwitch(true);
      setTimeout(() => {
        setIsLangSwitch(false);
      }, 1000);
    } else {
      setIsInitialRender(false);
    }
  }, [language]);


  /*============== Equal Height of Component ==================*/
  useEffect(() => {
    const setEqualHeight = () => {
        if(pannelRef.current && chatRef.current){
        const pannelHeight = pannelRef.current.clientHeight;
        chatRef.current.style.maxHeight = `${pannelHeight}px`;
      }
    };

    setEqualHeight();
    window.addEventListener('resize', setEqualHeight);

    return () => {
      window.removeEventListener('resize', setEqualHeight);
    };
  }, []);
  /*============== Socket io ==================*/
  // utilisez des ports différents pour les connexions des clients
  //connexion à la socket Forex du server (https://forex.efficient-trading.com, port 4002)
  // en local (http://localhost:5001)
  const socket = io(process.env.REACT_APP_URL_SALLE_Forex, {
    transports: ['websocket'],
    protocol: ['ws']
  });


/*============== Gestion des message reçu du serveur par la socket ==================*/
  //Connection de la socket
  socket.on('connect', () => {
    socket.emit('pseudo', pseudo);
    // On demande le pseudo + edit de l'onglet de la page avec le pseudo
    document.title = pseudo + ' [Salle Forex] - Efficient-Trading';
  });


  //Un User entre dans la salle
  socket.on('newUserForex', (pseudo) => {
    if(isAdmin) {
      const newElement = document.createElement('div');
      newElement.classList.add('newUserForex', 'message');
      newElement.innerHTML = pseudo + ' a rejoint la Salle';
      document.getElementById('msgContainerAdmin').appendChild(newElement);
      scrollToBottom('msgContainerAdmin');
      var audio = new Audio(Sound_default);
      audio.play();
    }
  });

    // écoute du user quit
  socket.on('quitUserForex', (pseudo) => {
    if(isAdmin) {
      const newElement = document.createElement('div');
      newElement.classList.add('quitUserForex', 'message');
      newElement.innerHTML = pseudo + ' a quitté la Salle';
      document.getElementById('msgContainerAdmin').appendChild(newElement);
      scrollToBottom('msgContainerAdmin');
      var audio = new Audio(Sound_default);
      audio.play();
    }
  });

    //Deconnection de la socket
    socket.on('Disconnect', () => {
      console.log('Disconnected from Forex server');
      // Edit de l'onglet de la page avec le pseudo
      document.title = 'Efficient-Trading';
    });

  //Nouveau message a tous les users
  socket.on("newMessageAllForex", (message) => {
        // Display message
    createElementFunction('newMessageAllForex', message)
  });

  // Chargement des anciens messages
  socket.on('oldMessageForex', (messages) => {
    if(isAdmin) {
      // Purge des messages pour éviter les doublons
      RefreshElements('msgContainerAdmin');
      messages.forEach( message => {
          createElementFunction('oldMessagesMeForex', message);
        });
    } else {
      // Purge des messages pour éviter les doublons
      RefreshElements('msgContainer');
      // Affichage des mssages
      messages.forEach( message => {
        createElementFunction('oldMessagesForex', message);
      });
    }
  });

  // Chargement des trades
  socket.on('OpenPositionsForex', (trades) => {
    try {
      RefreshElements('Trades');
      trades.forEach( trade => {
          console.log('New trade retrieved:'+ trade.product);
          displayTrade(trade);
      });
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in OpenPositionsForex ' + err);
    } 
  });

    // New trade received
  socket.on('newTradeAllForex', (trade) => {
    try {
      //RefreshElements('Trades');
        console.log('New trade retrieved (All):'+ trade.product);
        displayTrade(trade);
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in newTradeAllForex ' + err);
    } 
  });

      // écoute du Pocket closed
  socket.on('SocketClosed', (pseudo) => {
    console.log('Socket has been closed by server'); 
  });


/*============== Fin Socket io ==================*/ 

/*=================== Fonctions de la salle ===================*/
  // Purge des donnée dans un container
  function RefreshElements(ContainerToRefresh){
    const containerDiv = document.getElementById(ContainerToRefresh);
    if (containerDiv) {
      while (containerDiv.firstElementChild) {
        containerDiv.removeChild(containerDiv.firstElementChild); 
      }
    };
  };

  // Création d'un message annonce Public 
  function createElementFunctionAnnonce(pseudo, message) {
    // Créer une nouvelle div avec l'ID spécifié
    const newDiv = document.createElement('div');
    newDiv.setAttribute('id', pseudo);
    // Ajouter le message à la div
    const messageNode = document.createTextNode(message);
    newDiv.appendChild(messageNode);
    // Ajouter la nouvelle div à la page
    const containerDiv = document.getElementById('annoncepublicadmin');
    containerDiv.appendChild(newDiv);
  };

  // Création d'un message
  function createElementFunction(element, content) {
    try {
      //Container à adresser
      var ContainerMessage ='';
      const newElement = document.createElement('div');
      // Heure du message
      const formattedTimestamp = moment(content.timestamp).format('HH:mm:ss');
      //Text a afficher 
      var TexttoDisplay=content.content;
      //Text a analyser 
      var casevalue = content.content; 
      const casevaluetab = casevalue.split("(");
      if (casevaluetab[0] !== ""){
        casevalue = casevaluetab[0];
      }
        // Select Sound to be played
        var preaudio = undefined;
        var audio = new Audio(Sound_default);

    //Traitement des messages
    switch (casevalue) {
      case "Ready for EUR/GBP":
        preaudio = new Audio(Sound_ready);
        audio = new Audio(Sound_europound);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT>';
        break;
      case "Ready for GBP/USD":
        audio = new Audio(Sound_europound);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT>';
        break;
      case "Ready for EUR/USD":
        preaudio = new Audio(Sound_ready);
        audio = new Audio(Sound_eurodollar);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT>';
        break;
      case "Ready for USD/JPY":
        preaudio = new Audio(Sound_ready);
        audio = new Audio(Sound_dollaryen);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT>';
        break;
      case "Ready for USD/CHF":
        preaudio = new Audio(Sound_ready);
        audio = new Audio(Sound_dollarswissfranc);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT>';
        break;
      case "Ready for AUD/USD":
        preaudio = new Audio(Sound_ready);
        audio = new Audio(Sound_australiandollar);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT>';
        break;
      case "Ready for EUR/JPY":
        preaudio = new Audio(Sound_ready);
        audio = new Audio(Sound_euroyen);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT>';
        break;
      case "Cancel EUR/GBP":
        preaudio = new Audio(Sound_default);
        audio = new Audio(Sound_europound);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT><p> No Need to stand ready anymore</p>';
        break;
      case "Cancel GBP/USD":
        preaudio = new Audio(Sound_default);
        audio = new Audio(Sound_europound);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT><p> No Need to stand ready anymore</p>';
        break;
      case "Cancel EUR/USD":
        preaudio = new Audio(Sound_default);
        audio = new Audio(Sound_eurodollar);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT><p> No Need to stand ready anymore</p>';
        break;
      case "Cancel USD/JPY":
        preaudio = new Audio(Sound_default);
        audio = new Audio(Sound_dollaryen);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT><p> No Need to stand ready anymore</p>';
        break;
      case "Cancel USD/CHF":
        preaudio = new Audio(Sound_default);
        audio = new Audio(Sound_dollarswissfranc);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT><p> No Need to stand ready anymore</p>';
        break;
      case "Cancel AUD/USD":
        preaudio = new Audio(Sound_default);
        audio = new Audio(Sound_australiandollar);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT><p> No Need to stand ready anymore</p>';
        break;
      case "Cancel EUR/JPY":
        preaudio = new Audio(Sound_default);
        audio = new Audio(Sound_euroyen);
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content + '</FONT><p> No Need to stand ready anymore</p>';
        break;

      // Traitement des Achats
      case "BuyMarket":
        audio = undefined;
        break;
      case "GO Long EUR/GBP":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goLong);
        audio = new Audio(Sound_buyEuropound);
        break;
      case "GO Long GBP/USD":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goLong);
        audio = new Audio(Sound_goLong);
        break;
      case "GO Long EUR/USD":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goLong);
        audio = new Audio(Sound_buyEurodollar);
        break;
      case "GO Long USD/JPY":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goLong);
        audio = new Audio(Sound_buyDollaryen);
        break;
      case "GO Long USD/CHF":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goLong);
        audio = new Audio(Sound_buyDollarswissfranc);
        break;
      case "GO Long AUD/USD":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goLong);
        audio = new Audio(Sound_buyAustraliandollar);
        break;
      case "GO Long EUR/JPY":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goLong);
        audio = new Audio(Sound_buyEuroyen);
        break;
      case "Exit Long EUR/GBP":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;
      case "Exit Long GBP/USD":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;
      case "Exit Long EUR/USD":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;
      case "Exit Long USD/JPY":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;
      case "Exit Long USD/CHF":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;
      case "Exit Long AUD/USD":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;
      case "Exit Long EUR/JPY":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#008000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;

      // Traitement des Ventes
      case "Sell Market":
        audio = undefined;
        break;
      case "GO Short EUR/GBP":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goShort);
        audio = new Audio(Sound_sellEuropound);
        break;
      case "GO Short GBP/USD":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goShort);
        audio = new Audio(Sound_goShort);
        break;
      case "GO Short EUR/USD":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goShort);
        audio = new Audio(Sound_sellEurodollar);
        break;
      case "GO Short USD/JPY":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goShort);
        audio = new Audio(Sound_sellDollaryen);
        break;
      case "GO Short USD/CHF":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goShort);
        audio = new Audio(Sound_sellDollarswissfranc);
        break;
      case "GO Short AUD/USD":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goShort);
        audio = new Audio(Sound_sellAustraliandollar);
        break;
      case "GO Short EUR/JPY":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        preaudio = new Audio(Sound_goShort);
        audio = new Audio(Sound_sellEuroyen);
        break;
      case "Exit Short EUR/GBP":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;
      case "Exit Short GBP/USD":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;
      case "Exit Short EUR/USD":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;
      case "Exit Short USD/JPY":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;
      case "Exit Short USD/CHF":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;
      case "Exit Short AUD/USD":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;
      case "Exit Short EUR/JPY":
        TexttoDisplay = '<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content + '</FONT>';
        audio = new Audio(Sound_exit);
        break;

      // Traitement du Think BIG Profit
      case "Think BIG Profit EUR/GBP":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkBigProfit);
        break;
      case "Think BIG Profit GBP/USD":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkBigProfit);
        break;
      case "Think BIG Profit EUR/USD":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkBigProfit);
        break;
      case "Think BIG Profit USD/JPY":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkBigProfit);
        break;
      case "Think BIG Profit USD/CHF":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkBigProfit);
        break;
      case "Think BIG Profit AUD/USD":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkBigProfit);
        break; 
      case "Think BIG Profit EUR/JPY":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkBigProfit);
        break; 

      // Traitement du New Price 
      case "New Price EUR/GBP":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkLittleProfit);
        break;
      case "New Price GBP/USD":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkLittleProfit);
        break;
      case "New Price EUR/USD":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkLittleProfit);
        break;
      case "New Price USD/JPY":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkLittleProfit);
        break;
      case "New Price USD/CHF":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkLittleProfit);
        break;
      case "New Price AUD/USD":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkLittleProfit);
        break; 
      case "New PriceEUR/JPY":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
        audio = new Audio(Sound_ThinkLittleProfit);
        break; 

      // Traitement des Pastilles
      case "Green":
        TexttoDisplay = '<img src=' + png_Green + ' width="20">';
        audio = new Audio(Sound_green);
        break;
      case "Orange":
        TexttoDisplay = '<img src=' + png_Orange + ' width="20">';
        audio = new Audio(Sound_orange);
        break;
      case "Red":
        TexttoDisplay = '<img src=' + png_Red + ' width="20">';
        audio = new Audio(Sound_red);
        break;

      //Others Signals
      case "Reinforce":
        audio = new Audio(Sound_reinforcePosition);
        TexttoDisplay='<FONT SIZE="4pt" COLOR="#C0C0C0";>Reinforce Position</FONT>';
        break;
      case "Reinforced":
        audio = new Audio(Sound_reinforcedPosition);
        TexttoDisplay='<FONT SIZE="4pt" COLOR="#C0C0C0";>Position has been Reinforced</FONT>';
        break;
      case "Stay Out":
        audio = new Audio(Sound_stayAside);
        break;
      case "Stop @ entry price":
        TexttoDisplay='<FONT SIZE="4pt" COLOR="#FF0000";>Stop @ entry price</FONT>';
        audio = new Audio(Sound_stop_entry_price);
        break; 
      case "Cancel":
        TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>Cancel Ready Signal</FONT>';
        audio = new Audio(Sound_stayAside)
        break;  
      case "Jingle":
        TexttoDisplay='<FONT SIZE="4pt" COLOR="#C0C0C0";>Bienvenue, notre session du jour commence.</FONT>';
        audio = new Audio(Sound_TheEFTSignals);
        break; 
      case "GAMBLE":
        audio = new Audio(Sound_Gamble);
        TexttoDisplay='<FONT SIZE="4pt" COLOR="#C0C0C0";>This is Gamble</FONT>';
        break;   
      case "Cirque":
        audio = new Audio(Sound_circus);
        TexttoDisplay='<img src='+png_cirque+' width="120">';
        break;   
      default:
        // audio = new Audio(Sound_default);     
    };


    // Affichage des informations dans la trading room
        //Define container to be addressed
        if(isAdmin) {
          ContainerMessage = 'msgContainerAdmin';
        } else {
          ContainerMessage = 'msgContainer';
        }
        // Use the right format to be sent to the backend for the message
        switch (element) {
          case 'newMessageMeForex':
            newElement.classList.add(element, 'message')
            newElement.innerHTML = "[" + formattedTimestamp + "] " + '' + pseudo + ' : ' + TexttoDisplay;
            document.getElementById('msgContainerAdmin').appendChild(newElement);
            scrollToBottom('msgContainerAdmin');
            if (preaudio !== undefined) preaudio.play(); 
            if (audio !== undefined) audio.play();
            break;

          case 'oldMessagesMeForex':
            newElement.classList.add(element, 'messages');
            newElement.innerHTML =  "[" + formattedTimestamp + "] " + '' + content.sender + ' : ' + TexttoDisplay + ' - ' ;
            document.getElementById('msgContainerAdmin').appendChild(newElement);
            scrollToBottom('msgContainerAdmin');
            break;

          case 'newMessageAllForex':
            newElement.classList.add(element, 'message')
            newElement.innerHTML = "[" + formattedTimestamp + "] " + '' + content.sender + ' : ' + TexttoDisplay
            document.getElementById(ContainerMessage).appendChild(newElement);
            scrollToBottom(ContainerMessage);
            if (preaudio !== undefined) preaudio.play(); 
            if (audio !== undefined) audio.play(); 
            break;

          case 'oldMessageForex':
            newElement.classList.add( element, 'messages')
            newElement.innerHTML = "[" + formattedTimestamp + "] " + '' + content.sender + ' : ' + TexttoDisplay   ;
            document.getElementById(ContainerMessage).appendChild(newElement);
            scrollToBottom(ContainerMessage);
            break;

          default:
            break;
        }
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in createElementFunction ' + err);
    }
  }

   function displayTrade(trade) {
    try {
      console.log('Affichage du trade: ' + trade.product);
      const CouleurDefault = '#FFFFFF'; 
      var Couleur = CouleurDefault;
      const newElement = document.createElement('div');
      newElement.classList.add('trades');
      //Formatage du Trade à afficher
      if (trade.sens ==='S') {Couleur = "#FF0000"} else {Couleur = "#00FF00"}; 
      newElement.innerHTML =  formattexthtml('4',Couleur,'>>') + formattexthtml('4',CouleurDefault,trade.sens) + formattexthtml('4',Couleur,'>>') + formattexthtml('4',CouleurDefault,trade.product) +formattexthtml('4',Couleur,'>>')+ formattexthtml('4',CouleurDefault,trade.price)+formattexthtml('4',Couleur,'>>') + formattexthtml('4',CouleurDefault,trade.Newprice) + formattexthtml('4',Couleur,'>> STOP ') + formattexthtml('4',CouleurDefault,trade.StopPrice);
      document.getElementById('Trades').appendChild(newElement);
      scrollToBottom('Trades');
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in displayTrade ' + err);
    }
  };

  function formattexthtml(size, couleur, message) {
    try {
      var valeurreturn = '<FONT SIZE="' + size + 'pt" COLOR="' + couleur + '";>' + message + '</FONT>';
      return valeurreturn;
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in formattexthtml' + err);
    }
  };

  // ========= Gestion de l'interface UI =========== // 
  // Scroll Down de la Div
  function scrollToBottom(ContainerToRefresh) {
    try {
      const chatDiv = document.getElementById(ContainerToRefresh);
      chatDiv.scrollTop = chatDiv.scrollHeight - chatDiv.clientHeight;
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in scrollToBottom' + err);
    }
  }


    // ========= Boutons =========== // 
    const onClickSend = (Value) => {
      var textInput = Value;
      var trade = Date.now();
    //Si Ticker sélectionné on traite le ready for.
    if (textInput.substring(0,9) === "Ready for") {
      document.getElementById('Ticker').value = textInput;
        // Envoi du message à la socket pour les clients
        socket.emit('newMessageForex', textInput );
        // Affichage du message sur la console
        createElementFunction('newMessageMeForex', {sender: pseudo, content: textInput}); 
    }
    else {
  
      //Récupération du Produit
      var currenttickers = document.getElementById('Ticker').value.substring(9);
      //Récupération du Prix
      var currentvalue  = Number(document.getElementById('Pricepoint').value.replace(',','.'));
   
      var StopValue = 0;
  
          // Si Produit sélectionné et Prix renseigné on traite un Trade sur Go Long/Short et Profits
          if (currenttickers !== "" && currentvalue !== "") { 
            //calcul du stop price
            
            switch (currenttickers) {
              case " Bund":
                StopValue=1.5;
                break;
              case " DAX":
                StopValue=20;
                break;
              case " CAC":
                StopValue=15;
                break;
              case " FTSE":
                StopValue=15;
                break;
              case " DJ":
                StopValue=30;
                break;
              case " Nsdq":
                StopValue=20;
                break; 
              default :
                StopValue=20;
                break; 
            }
    
            // Traitement de l'achat
            if (textInput === "GO Long") { 
                // Formatage du trade à envoyer
                trade = trade + ';' + currenttickers + ';L;' + currentvalue + ';'+ (currentvalue - StopValue);
                // Envoi du trade à la socket pour les clients
                socket.emit('newTradeForex', trade );
                // Formatage du texte à envoyer
                textInput = Value + currenttickers + '(' + currentvalue + ')';
            };
            // Traitement de la Vente
            if (textInput === "GO Short") {
                // Formatage du trade à envoyer
                trade = trade + ';' + currenttickers + ';S;' + currentvalue+ ';'+ (currentvalue + StopValue);
                // Envoi du trade à la socket pour les clients
                socket.emit('newTradeForex', trade );
                // Formatage du texte à envoyer
                textInput = Value + currenttickers + '(' + currentvalue + ')';
            };
            // Mise à jour du Prix
            if (textInput === "NewPrice_LP_L") {
                // Formatage du trade à mettre à jour
                trade = currenttickers + ';' + currentvalue + ';Open;L';
                // Envoi du trade à la socket pour les clients
                socket.emit('UpdateTradeForex', trade );
                // Formatage du texte à envoyer;
                textInput = "New Price" + currenttickers + '(' + currentvalue + ')';
            };
            // Mise à jour du Prix
            if (textInput === "NewPrice_BP_L") {
              // Formatage du trade à mettre à jour
              trade = currenttickers + ';' + currentvalue + ';Open;L';
              // Envoi du trade à la socket pour les clients
              socket.emit('UpdateTradeForex', trade );
              // Formatage du texte à envoyer; 
              textInput = "Think BIG Profit" + currenttickers + '(' + currentvalue + ')';
            };
            // Mise à jour du Prix
            if (textInput === "NewPrice_LP_S") {
              // Formatage du trade à mettre à jour
              trade = currenttickers + ';' + currentvalue + ';Open;S';
              // Envoi du trade à la socket pour les clients
              socket.emit('UpdateTradeForex', trade );
              // Formatage du texte à envoyer;
              textInput = "New Price" + currenttickers + '(' + currentvalue + ')';
          };
          // Mise à jour du Prix
          if (textInput === "NewPrice_BP_S") {
            // Formatage du trade à mettre à jour
            trade = currenttickers + ';' + currentvalue + ';Open;S';
            // Envoi du trade à la socket pour les clients
            socket.emit('UpdateTradeForex', trade );
            // Formatage du texte à envoyer; 
            textInput = "Think BIG Profit" + currenttickers + '(' + currentvalue + ')';
          };
            if (textInput === "Exit Long") {
              // Formatage du trade à mettre à jour
              trade = currenttickers + ';' + currentvalue + ';Closed;L';
              // Envoi du trade à la socket pour les clients
              socket.emit('UpdateTradeForex', trade );
              // Formatage du texte à envoyer; 
              textInput = Value + currenttickers+ '(' + currentvalue + ')';
              };
            if (textInput === "Exit Short") {
              // Formatage du trade à mettre à jour
              trade = currenttickers + ';' + currentvalue + ';Closed;S';
              // Envoi du trade à la socket pour les clients
              socket.emit('UpdateTradeForex', trade );
              // Formatage du texte à envoyer; 
              textInput = Value + currenttickers+ '(' + currentvalue + ')';
            };
    
            
            // Remise à Zéro de la current value
            document.getElementById('Pricepoint').value=""; 
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageForex', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageMeForex', {sender: pseudo, content: textInput});
    
          } else if (currenttickers !== "" && textInput !== "GO Long" && textInput !== "New Price" && textInput !== "GO Short" && textInput !== "Think BIG Profit"){
            if (textInput === "Ready to SELL") { textInput = Value + currenttickers};
            if (textInput === "Ready to BUY") { textInput = Value + currenttickers};
            if (textInput === "Reinforce Position") { textInput = Value + currenttickers};
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageForex', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageMeForex', {sender: pseudo, content: textInput});
    
          } else if (textInput === "Jingle")  {
              socket.emit('newMessageForex', textInput);
              createElementFunction('newMessageMeForex', {sender: pseudo, content: textInput}); 
          }
        }
      }
  
  return (
    <>
    {isLangSwitch && (
      <div className="loader-background" style={{top:0}}>
        <span className="loader-text">Switching...</span>
      </div>
    )}
    <div className='all-forex'>

      {!isAdmin? (
        <div className='main-chat-conatiner'>

          <div className='chat-container'>
            <div className="entete-salle">
            <div className="open-position" >
                  <div style={{margin:'0px'}}>
                  <img className='openposition-barre' src={openposition} alt="openposition"></img>
                  </div>
                  <div className="barre-trade" id='Trades'>

                  </div>
                </div>
              </div>
            <div className="titresalle">
              <h1 className='titre'>{language==='Fr'?"Salle des marchés Forex":"Forex trading room"}</h1>
            </div>
          </div>
          <div className='msgContainer' id='msgContainer'>
          </div>
          <div className="publicAnnonce">
            <div className="img-public">
              <img src={publicAnnonce} alt="img-hautparleur"></img>

            </div>
            <div className="annonceplublicadmin">texte</div>
          </div>
        </div>
      ):(
        <div className='tradePanel'>
          <div className='combine-panel'>
            <div ref={pannelRef} className='pannel'>
              <div className='Tickers'>
                <div className='Text'>
                  <h2>Tickers</h2>
                </div>
                <div className='ticket-block'>
                <div className='button-tickers1'>
                  <div className='Bouton'>
                    <button onClick={() => onClickSend('Ready for EUR/GBP')}>EUR/GBP</button>
                  </div>

                  <div className='Bouton'>
                    <button onClick={() => onClickSend('Ready for GBP/USD')}>GBP/USD</button>
                  </div>

                  <div className='Bouton'>
                    <button onClick={() => onClickSend('Ready for USD/JPY')}>USD/JPY</button>
                  </div>


                </div>
                <div className='button-tickers2'>
                  <div className='Bouton'>
                    <button onClick={() => onClickSend('Ready for EUR/USD')}>EUR/DOL</button>
                  </div>

                  <div className='Bouton'>
                    <button onClick={() => onClickSend('Ready for AUD/USD')}>AUD/USD</button>
                  </div>

                  <div className='Bouton'>
                    <button onClick={() => onClickSend('Ready for EUR/JPY')}>EUR/JPY</button>
                  </div>

                </div>
                <div className='button-tickers3'>

                  <input className='Form-Value' type='text' placeholder='Select a Ticker' disabled={true} id='Ticker' />

                  <div className='Bouton-Buy'>
                    <button onClick={() => onClickSend('Buy Market')}>BUY Market</button>
                  </div>

                  <div className='Bouton-sell'>
                    <button onClick={() => onClickSend('Sell Market')}>SELL MARKET</button>
                  </div>
                </div>
                </div>
              </div>
              <div className='Future-value'>
                <div className='Future-value-color'>
                  <div className='Bouton-green'>
                    <button onClick={() => onClickSend('Green')}>Green</button>
                  </div>

                  <div className='Bouton-orange'>
                    <button onClick={() => onClickSend('Orange')}>Orange</button>
                  </div>

                  <div className='Bouton-red'>
                    <button onClick={() => onClickSend('Red')}>Red</button>
                  </div>
                </div>
              </div>

              <div className='CurrentValue'>
                <div className='Text'>
                  <h2>Current value</h2>
                </div>

                <form onSubmit={(e) => {
                  e.preventDefault();
                  const textInput = document.getElementById('Pricepoint').value;
                  document.getElementById('Pricepoint').value = '';

                  if (textInput.length > 0) {
                    socket.emit('newMessageForex', textInput);
                    createElementFunction('newMessageMeForex', { sender: pseudo, content: textInput });
                  } else {
                    return false;
                  }
                }}>
                  <input className='Form-Value' type='text' placeholder='Current Value' id='Pricepoint' />
                </form>

              </div>

              <div className='Signals'>
                <div className='Long'>
                  <h2>Long Signals</h2>
                  <div className='Go-long'>
                    <div className='Bouton'>
                      <button onClick={() => onClickSend('GO Long')}>GO LONG</button>
                    </div>
                  </div>
                  <div className='profits'>
                    <h2>Profits</h2>
                    <div className='Bouton'>
                      <button onClick={() => onClickSend('NewPrice_LP_L')}>Little</button>
                    </div>
                    <div className='Bouton'>
                      <button onClick={() => onClickSend('NewPrice_BP_L')}>BIG</button>
                    </div>
                  </div>

                  <div className='Exit-Long'>
                    <div className='Bouton'>
                      <button onClick={() => onClickSend('Exit Long')}>EXIT LONG</button>
                    </div>
                  </div>
                </div>

                <div className='Short'>
                  <h2>Shorts Signals</h2>
                  <div className='Go-short'>
                    <div className='Bouton'>
                      <button onClick={() => onClickSend('GO Short')}>GO SHORT</button>
                    </div>
                  </div>
                  <div className='profits'>
                    <h2>Profits</h2>
                    <div className='Bouton'>
                      <button onClick={() => onClickSend('NewPrice_LP_S')}>Little</button>
                    </div>
                    <div className='Bouton'>
                      <button onClick={() => onClickSend('NewPrice_BP_S')}>BIG</button>
                    </div>
                  </div>
                  <div className='Exit-short'>
                    <div className='Bouton'>
                      <button onClick={() => onClickSend('Exit Short')}>EXIT SHORT</button>
                    </div>
                  </div>


                </div>
              </div>

              <div className='Future-value'>
                <h2>Future Value</h2>
                <form onSubmit={(e) => {
                  e.preventDefault();
                  const textInput = document.getElementById('FutrePricepoint').value;
                  document.getElementById('FutrePricepoint').value = '';

                  if (textInput.length > 0) {
                    socket.emit('newMessageForex', textInput);
                    createElementFunction('newMessageMeForex', { sender: pseudo, content: textInput });
                  } else {
                    return false;
                  }
                }}>
                  <input type='text' placeholder='Future Value' id='FutrePricepoint' />
                </form>
              </div>

              <div className='Others'>
                <h2>Others signals</h2>
                <div className='bouton1'>
                  <button onClick={() => onClickSend('Stop @ entry price')}>STOP @ ENTRY PRICE</button>
                </div>
                <div className='bouton2'>
                  <button onClick={() => onClickSend('Cancel')}>Cancel READY Signal</button>
                </div>
                <div className='bouton3'>
                  <button onClick={() => onClickSend('Reinforce')}>Reinforce Position</button>
                </div>
                <div className='bouton4'>
                  <button onClick={() => onClickSend('Reinforced')}>Position has been Reinforced</button>
                </div>
                <div className='bouton5'>
                  <button onClick={() => onClickSend('GAMBLE')}>THIS IS GAMBLE</button>
                </div>
                <div className='bouton6'>
                  <button onClick={() => onClickSend('Jingle')}>Jingle</button>
                </div>
                <div className='bouton7'>
                  <button onClick={() => onClickSend('Cirque')}>Cirque</button>
                </div>
              </div>



              <div className='Instant-message'>
                <div>
                  <h2>Messagerie Instantanée</h2>
                  <select onChange={(e) => {
                    const textInput = `${e.target.value}`;
                    if (textInput.length > 0) {
                      document.getElementById('directTextInput').value = textInput;
                      e.target.value = '';
                    }
                  }}
                  className="botSpaceSelect">
                    <option value=""></option>
                    <option value="Bonjour !">Bonjour !</option>
                    <option value="Bon appétit">Bon appétit</option>
                    <option value="A demain">A demain</option>
                    <option value="Je dois m'absenter quelques instant, je reviens au plus tôt.">Je reviens</option>
                  </select>

                </div>

                <div>
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    const textInput = document.getElementById('directTextInput').value;
                    document.getElementById('directTextInput').value = '';

                    if (textInput.length > 0) {
                      socket.emit('newMessageForex', textInput);
                      createElementFunction('newMessageMeForex', { sender: pseudo, content: textInput });
                    } else {
                      return false;
                    }
                  }}>
                    <input type='text' placeholder='Saisir le message à envoyer' id='directTextInput' />
                    <button type='submit'>Envoyer</button>
                  </form>
                </div>
              </div>
            </div>

            <div ref={chatRef} className='chat-container'>
              <div className="entete-salle">
                <div className="open-position">
                  <div>
                    <img className='openposition-barre' src={openposition} alt="openposition"></img>
                  </div>
                  <div className='barre-trade' id='Trades'>

                  </div>
                </div>
              </div>
              <div className="titresalle">
                <h1 className='titre'>{language==='Fr'?"Salle des marchés Forex":"Forex trading room"}</h1>
              </div>
              <div className='msgContainerAdmin' id='msgContainerAdmin'>
              </div>
              <div className="publicAnnonce">
                <div className="img-public">
                  <img src={publicAnnonce} alt="img-hautparleur"></img>
                  
                </div>
                <div className="annonceplublicadmin" id="annonceplublicadmin">
                </div>
              </div>
            </div>
          </div>

        </div>
      )}
      
     

    </div>
    </>
  );
}

export default FOREX;
