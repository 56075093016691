import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, blockUser, unblockUser } from "../../actions/users.actions";
import "./../../styles/ListUsers.scss";
import moment from "moment";
import axios from "axios";

let passwordData={
 password:'',
 confirmPassword:'',
}
export default function ListUsers() {
  const [loadUsers, setLoadUsers] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]); // Utilisateurs sélectionnés
  const [open,setOpen]=useState(false);
 
  const [password,setPassword]=useState(passwordData);
  const [errorMessage,setErrorMessage]=useState();


  const dispatch = useDispatch();
  const [users,setUsers] = useState(useSelector((state) => state.usersReducer));
  const [edit, setEdit] = useState(Array(users.length).fill(false));
  const [editIndices, setEditIndices] = useState(Array(users.length).fill(false));
 

  useEffect(() => {
    if (loadUsers) {
      dispatch(getUsers());
      setLoadUsers(false);
    }
  }, [loadUsers, dispatch]);

  const handleBlockUser = (user) => {
    if (user.blocked) {
      dispatch(unblockUser(user.id));
    } else {
      dispatch(blockUser(user.id));
    }
  };

  const toggleSelectUser = (userId) => {
    if (selectedUsers.includes(userId)) {
      // L'utilisateur est déjà sélectionné, le retirer de la liste
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      // L'utilisateur n'est pas encore sélectionné, l'ajouter à la liste
      setSelectedUsers([...selectedUsers, userId]);
    }
  };
  // handle the edit of dates for forex
  const handleEdit = (index) => {
    const newEdit = [...edit];
    newEdit[index] = !newEdit[index];
    setEdit(newEdit);
    // setEdit(newEditStates);
    
  };
  // handle the edit of dates for Indices
  const handleEditIndices = (index) => {
    const newEdit = [...editIndices];
    newEdit[index] = !newEdit[index];
    setEditIndices(newEdit);
    // setEdit(newEditStates);
    
  };
  const handleResetPassword=(e)=>{
    const {name,value}=e?.target;
      setPassword((prev)=>({
        ...prev,
        [name]:value
      }))
  }

 // Update date in the existing user array 
  const handleDate=(e,index)=>{
    const{name,value}=e?.target;
    setUsers((prev)=>{
        let temp = [...prev]
        temp[index][name]=value;
        return temp;
    })
  }
  //Update and hit api for reset password
  const submitResetData=()=>{

    if(password?.password===password?.confirmPassword)
    {
      let new_password=password?.password;
     //api
     axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/user/change-password/${open}`,
      withCredentials: true,
      data: {
        new_password
      },}).then((res)=>{
        setOpen(false);
        setPassword(passwordData);
        alert(res?.data?.message)
        console.log('Updating password successful')
      }).catch(error=>{
        console.log('Error while updating password',error);
        setOpen(false);
      })
     setErrorMessage(false)
    }
    else{
      setErrorMessage('Password Not Matching')
    }
  }
 //Update and hit api for aboforex
  const updateAboForex=(id,index)=>{
    let aboForex=users[index]?.aboForex;
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/user/change-forex/${id}`,
      withCredentials: true,
      data: {
        aboForex
      },}).then((res)=>{
        alert(res?.data?.message);
      }).catch(error=>{
        console.log('Error while updating aboforex',error);
        
      })
  }
   //Update and hit api for aboindices
  const updateAboIndices=(id,index)=>{
    let aboIndices=users[index]?.aboIndices;
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/user/change-indices/${id}`,
      withCredentials: true,
      data: {
        aboIndices
      },}).then((res)=>{
        alert(res?.data?.message);
      }).catch(error=>{
        console.log('Error while updating aboforex',error);
        
      })
  }
  // Hit api to block/unblock user
  const updateBlockUser=(e,id,index)=>{
    const {name,checked}=e.target;
    setUsers((prev)=>{
      let temp = [...prev]
      temp[index][name]=checked;
      return temp;
  })

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/user/change-status/${id}`,
      withCredentials: true,
      }).then((res)=>{
        alert(`${res?.data?.message} ${users[index]?.status ? '(Un-Blocked)':'(Blocked)'}`)
      }).catch(error=>{
        console.log('Error while updating aboforex',error);
        
      })

  }
  const isChecked = (userId) => selectedUsers.includes(userId);

  return (
    <>
      {open &&<div className="model-main" style={{}}>
        <div className="alert-box">
          <div className="alert-header">
            <div style={{ marginLeft:'0px' }}>
              <h4>Reset Password</h4>
            </div>
            <div
              style={{
                fontWeight: 900,
                color: "red",
               marginRight:'2px',
              }}
            >
              <span onClick={()=>setOpen(false)}>
✖</span>
            </div>
          </div>
          <div className="alert-body">
          <div>
            <label htmlFor="password">Password: </label>
            <input type="password" id="password" name="password" value={password?.password} onChange={(e)=>handleResetPassword(e)}/>
          </div>
          <div>
            <label htmlFor="password">Confirm Password: </label>
            <input type="password" name="confirmPassword" value={password?.confirmPassword} onChange={(e)=>handleResetPassword(e)} />
          </div>
          <div style={{color:'red'}}>{errorMessage}</div>
          <div>
            <button onClick={submitResetData}>Reset</button>
          </div>
          </div>
        </div>
      </div>}
      <div className="list-users">
        <div>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>Liste des Membres</th>
                <th rowSpan={2}>Nom</th>
                <th rowSpan={2}>Prénom</th>
                <th rowSpan={2}>Email</th>
                <th rowSpan={2}>Adresse</th>
                <th rowSpan={2}>Code postal</th>
                <th rowSpan={2}>Pays</th>
                <th rowSpan={2}>Age</th>
                <th rowSpan={2}>Raison sociale</th>
                <th rowSpan={2}>Connu ?</th>
                <th rowSpan={2}>Ip</th>
                <th rowSpan={2}>Date d'inscription</th>
                <th rowSpan={2}>Abonnement</th>
                <th rowSpan={2}>Pseudo</th>
                <th rowSpan={2}>Action</th>
                <th rowSpan={2}>Block/Unblock</th>
                <th rowSpan={2}>User Password</th>
                <th colSpan={2}>Subscription End</th>
              </tr>
              <tr>
                <th>Forex</th>
                <th>Indices</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(users) &&
                users.map((user,index) => (
                  <tr key={user.id}>
                    <td>{user.lastName}</td>
                    <td>{user.firstName}</td>
                    <td>{user.email}</td>
                    <td>{user.adress}</td>
                    <td>{user.ville}</td>
                    <td>{user.codePostal}</td>
                    <td>{user.pays}</td>
                    <td>{user.age}</td>
                    <td>{user.statut}</td>
                    <td>{user.connu}</td>
                    <td>{user.userIp}</td>
                    <td>{user.createdAt}</td>
                    <td>Abonnement</td>
                    <td>{user.pseudo}</td>
                    <td>Action</td>
                    <td>
                      <input
                        type="checkbox"
                        className="toggle"
                        id={`toggle-${user._id}`}
                        checked={user?.status}
                        name="status"
                        onChange={(e)=>updateBlockUser(e,user._id,index)}
                      />
                      <label
                        className="toggle-button"
                        htmlFor={`toggle-${user._id}`}
                      ></label>
                    </td>
                    <td>
                      <button onClick={()=>setOpen(user?._id)} >Reset Password</button>
                    </td>
                    <td>
                      {/* {moment(user.aboForex).format("YYYY-MM-DD")} */}
                      <input
                              type="date"
                              style={{
                                padding: "5px",
                                borderRadius: "5px",
                                border: "none",
                                marginBottom: "5px",
                              }}
                              name="aboForex"
                              disabled={!edit[index]?true:false}
                              value={moment(user.aboForex).format("YYYY-MM-DD")}
                              onChange={(e) =>handleDate(e,index)
                              }
                            />
                    <div
                          style={{
                            display: "flex",
                            flexDirection: "column-reverse",
                          }}
                        >
                          <div className="compteur-img" onClick={()=>handleEdit(index)}>
                            {!edit[index] ? (
                              <svg
                                fill="goldenrod"
                                xmlns="http://www.w3.org/2000/svg"
                                height="1.5em"
                                viewBox="0 0 512 512"
                              >
                                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            ) : (
                              <svg
                                fill="darkgreen"
                                xmlns="http://www.w3.org/2000/svg"
                                height="1.5em"
                                viewBox="0 0 448 512"
                                onClick={()=>updateAboForex(user?._id,index)}
                              >
                                <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                              </svg>
                            )}
                          </div>
                         
                        </div>
                    </td>
                    <td>
                      {/* {moment(user.aboIndices).format("YYYY-MM-DD")} */}
                      <input
                              type="date"
                              style={{
                                padding: "5px",
                                borderRadius: "5px",
                                border: "none",
                                marginBottom: "5px",
                              }}
                              disabled={!editIndices[index]?true:false}
                              name="aboIndices"
                              value={moment(user.aboIndices).format("YYYY-MM-DD")}
                              onChange={(e) =>handleDate(e,index)
                              }
                            />
                    <div
                          style={{
                            display: "flex",
                            flexDirection: "column-reverse",
                          }}
                        >
                          <div className="compteur-img" onClick={()=>handleEditIndices(index)}>
                            {!editIndices[index] ? (
                              <svg
                                fill="goldenrod"
                                xmlns="http://www.w3.org/2000/svg"
                                height="1.5em"
                                viewBox="0 0 512 512"
                              >
                                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            ) : (
                              <svg
                                fill="darkgreen"
                                xmlns="http://www.w3.org/2000/svg"
                                height="1.5em"
                                viewBox="0 0 448 512"
                                onClick={()=>updateAboIndices(user?._id,index)}
                              >
                                <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                              </svg>
                            )}
                          </div>
                         
                        </div>

                      </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
