import React from 'react'


// js et scss
import './../../styles/Admin.scss'

export default function Admin() {
  return (
    <div className="admin-container">
      <div className='heading-text'>
        <h1 className="admin-title">Administration</h1>
      </div>

      <div className='text-line'>
        <a href="/List-Users" className="List-users">Liste des membres</a>
      </div>
    </div>
  )
}
