import React, { useState } from "react";
import "./../styles/setNewPassword.scss";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

function SetNewPassword() {
    const [passwordData,setPasswordData]=useState({
        password:'',
        confirmPassword:''
    })
    const [error,setError]=useState(false);
    const history=useHistory();
    const {id}=useParams();

    console.log('params',id);
    const handleChange=(e)=>{
        const {name,value}=e?.target
        setPasswordData((prev)=>({
            ...prev,
            [name]:value
        }))
    }

    const onReset= async()=>{
        if(passwordData?.password!==''&&passwordData?.confirmPassword!=='')
        { setError(false)
            if(passwordData?.password!==passwordData?.confirmPassword){
                setError('Both password does not match')
               
            }
            else{
                setError(false)
                if(passwordData?.confirmPassword.length>=8){
                    setError(false)
                    await axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}api/user/reset-password/${id}`,
                        withCredentials: true,
                        data:{
                            new_password:passwordData?.confirmPassword
                        }
                      })
                        .then(() =>history.push('/login'))
                        .catch((err) => console.log(err));
                    
                
                }else{
                    setError('Password should be minimum 8 character')
                }
               
        }}
        else{
            setError('Fields Cannot be empty')
        }
       
    }
  return (
    <div className="main-div">
      <h2>Set your new password</h2>
      <div className="container">
        <div>
        <label for="password">New-Password :</label>
        <input type="password" id="password" value={passwordData?.password} name="password" onChange={(e)=>handleChange(e)} />
        </div>
        <div>
        <label for="Cpassword">Confirm New-Password :</label>
        <input type="password" id="Cpassword" value={passwordData?.confirmPassword} name="confirmPassword" onChange={(e)=>handleChange(e)}/>
        </div>
        {error&&<div className="error-message"><p>{error}</p></div>}
        <button onClick={()=>onReset()}>Reset Password</button>
      </div>
    </div>
  );
}

export default SetNewPassword;
