import React, { useContext, useEffect, useState } from "react";
import "./../styles/NavbarDemo.scss";
import CompanyLogo from "./../images/Logo.png";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { LanguageContext, UidContext } from "./AppContext";
import { useSelector } from "react-redux";
import { BiSolidUser } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import Logout from "../Log/Logout";
import { MdOutlineExitToApp } from "react-icons/md";
import EnFlag from "../images/flags/en.png";
import FrFlag from "../images/flags/fr.png";
import Switch from "react-switch";

const NavbarDemo = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  const uid  = useContext(UidContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const userData = useSelector((state) => state.userReducer);
  const [date,setDate]=useState(new Date());

  
  const onLngaugeUpdate = (event) => {
    setLanguage(event.target.value);
  };

  const handleDocumentClick = (event) => {
    menuOpen && setMenuOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const isAdmin = uid && userData.role === "admin" ? true : false;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSwitch = (data) => {
    setLanguage(data ? "Fr" : "En");
  };

  return (
    <div className="navbar-bg">
      <div className="main-container">
        <nav className="navbar">
          <NavLink className="company-logo" exact to="/">
            <img src={CompanyLogo} alt="icon" className="logo" />
          </NavLink>
          <div className={`navbar-menu ${menuOpen ? "open" : ""}`}>
            {uid? (
              <div className="Nav-log">
                <ul>
                  <div className="Nav-Log1">
                    {uid ? (
                      <li className="comp-margin">
                        <NavLink exact to={date<=new Date(userData?.aboForex)||date<=new Date(userData?.aboIndices)||userData?.role==='admin'?"/choix-de-la-salle":"/Abonnement"}>
                          <p className="btn-salle">{language==='Fr'?'Accès à la Salle':'Access to the room'}</p>
                        </NavLink>
                      </li>
                    ) : (
                      <li className="comp-margin">
                        <NavLink exact to="/choix-de-la-salle">
                          <p className="btn-salle">{language==='Fr'?'Accès à la salle':'Access to the room'}</p>
                        </NavLink>
                      </li>
                    )}
                    {/* <li className="comp-margin">
                      <NavLink className="font-m" exact to="/faq">
                        FAQ
                      </NavLink>
                    </li>
                    <li className="comp-margin">
                      <NavLink className="font-m" exact to="/contact">
                        Contact
                      </NavLink>
                    </li> */}
                    <li className="welcome comp-margin">
                      <NavLink exact to="/profil" className="profile">
                        <div className="hover-expand">
                          <h5 className="Pseudo">
                            <BiSolidUser
                              size={18}
                              color="yellow"
                              className="user-icon"
                            />
                            {userData.firstName}
                          </h5>
                        </div>
                      </NavLink>
                    </li>
                    <Logout className="Logout" />
                    <li className="lang-box">
                    <Switch
                        checked={language == "Fr" ? true : false}
                        onChange={(e) => handleSwitch(e)}
                        // handleDiameter={28}
                        offColor="#fff"
                        onColor="#fff"
                        offHandleColor="#0d1b2a"
                        onHandleColor="#0d1b2a"
                        height={18}
                        width={50}
                        borderRadius={5}
                     
                        uncheckedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 15,
                              color: "black",
                              paddingRight: 2
                            }}
                          >
                            EN
                          </div>
                        }
                        checkedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 15,
                              color: "black",
                              paddingRight: 18,
                            }}
                          >
                            FR
                          </div>
                        }
                        
                        uncheckedHandleIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: '20px',
                              fontSize: 18,
                              marginTop:-2
                            }}
                          >
                           <img src={EnFlag} style={{borderRadius:'50px'}}/>
                          </div>
                        }
                        checkedHandleIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "20px",
                             
                              fontSize: 18,
                              marginTop:-2
                            }}
                          >
                            <img src={FrFlag} style={{borderRadius:'50px'}}/>
                          </div>
                        }
                      />
                    </li>
                    {/* <div className="admin-text-align"> */}
                    {isAdmin && ( // Affiche uniquement si l'utilisateur est un administrateur
                      <li className="admin-align">
                        <NavLink className="admin-tag" exact to="/admin">
                          Admin
                        </NavLink>
                      </li>
                    )}
                    {/* </div> */}
                  </div>
                  {/*partie droite de la nav quand connecté*/}
                </ul>
              </div>
            ) : (
              <div className="Nav-log">
                <ul>
                  <div className="Nav-Log1">
                    <li>
                      <NavLink exact to="/login">
                        <p className="btn-salle">{language==='Fr'?'Accès aux salles':'Access to rooms'}</p>
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink exact to="/faq">
                        FAQ
                      </NavLink>
                    </li>
                    <li>
                      <NavLink exact to="/contact">
                        Contact
                      </NavLink>
                    </li> */}
                    <li className="welcome">
                      <NavLink exact to="/login" className="login">
                        <MdOutlineExitToApp size={18} />
                        {language==='Fr'?'Connexion':'Connection'}
                      </NavLink>
                    </li>
                    <li className="last-box">
                      <NavLink exact to="/register" className="regi">
                        <BiSolidUser size={18} color="white" />
                        {language==='Fr'?'Inscription':'Registration'}
                      </NavLink>
                    </li>
                    <li className="lang-box">
                      {/* <div className="main-div"
                        // style={{
                        //   padding: "5px",
                        //   display: "flex",
                        //   flexDirection: "row",
                        //   justifyContent: "space-between",
                        //   color: "#fff",
                        // }}
                      >
                        <label for="language" style={{ padding: "5px" }}>
                          {language !== "Fr" ? "Language: " : "Langue: "}
                        </label>
                        <select
                          name="language"
                          id="language"
                          value={language}
                          // style={{
                          //   padding: "5px",
                          //   marginLeft: "5px",
                          //   backgroundColor: "#1b263b",
                          //   color: "#fff",
                          //   border: "none",
                          // }}
                          onChange={onLngaugeUpdate}
                        >
                          <option value={"En"}>
                            <h1>English</h1>
                          </option>
                          <option value={"Fr"}>
                            <h1>French</h1>
                          </option>
                        </select>
                        <div
                        className="flag-div"
                        >
                          {language !== "Fr" ? (
                            <img src={EnFlag} />
                          ) : (
                            <img src={FrFlag}/>
                          )}
                        </div>
                      </div> */}
                      <Switch
                        checked={language == "Fr" ? true : false}
                        onChange={(e) => handleSwitch(e)}
                        // handleDiameter={28}
                        offColor="#fff"
                        onColor="#fff"
                        offHandleColor="#0d1b2a"
                        onHandleColor="#0d1b2a"
                        height={18}
                        width={50}
                        borderRadius={5}
                     
                        uncheckedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 15,
                              color: "black",
                              paddingRight: 2
                            }}
                          >
                            EN
                          </div>
                        }
                        checkedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 15,
                              color: "black",
                              paddingRight: 18,
                            }}
                          >
                            FR
                          </div>
                        }
                        
                        uncheckedHandleIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: '20px',
                              fontSize: 18,
                              marginTop:-2
                            }}
                          >
                           <img src={EnFlag} style={{borderRadius:'50px'}}/>
                          </div>
                        }
                        checkedHandleIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "20px",
                             
                              fontSize: 18,
                              marginTop:-2
                            }}
                          >
                            <img src={FrFlag} style={{borderRadius:'50px'}}/>
                          </div>
                        }
                      />
                    </li>
                  </div>
                  {/*partie droite de la nav quand  pas connecté*/}
                </ul>
              </div>
            )}
          </div>
          <div className="navbar-mobile-toggle" onClick={toggleMenu}>
            <span className="icon">
              {menuOpen === false ? (
                <AiOutlineMenu size={18} color="white" />
              ) : (
                <BsThreeDotsVertical size={18} color="white" />
              )}
            </span>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavbarDemo;
