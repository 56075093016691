import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import socketIO from "socket.io-client";

// import js scss
import SignInForm from "../../Log/SignInForm";
import SignUpForm from "../../Log/SignUpForm";
import Contact from "../../pages/Contact";
import Home from "../../pages/Home";
import Profil from "../../pages/Profil";
import Temoignages from "../../pages/Temoignages";
import Faq from "../../pages/Faq";
import Footer from "../Footer";
import Navbar from "../Navbar";
//import ChoixSalle from "../../pages/Salles/index.js";
import ChoixSalle from "../../pages/Abonnement";
import Indice from "../../pages/Salles/Indices.js";
import Forex from "../../pages/Salles/Salles";
import Abonnement from "../../pages/Abonnement";
import CVG from "../../pages/Mentions/CVG";
import Admin from "../../pages/Admin/Admin";
import ListUsers from "../../pages/Admin/ListUsers";
import ResetPasswordForm from "../../Log/ResetPasswordForm";
import NavbarDemo from "./../../components/NavbarDemo";
import { UidContext } from "../../components/AppContext";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import moment from "moment";
import SetNewPassword from "../../Log/SetNewPassword.js";

const Index = () => {
  const uid = useContext(UidContext);
  const [loading, setLoading] = useState(true);
  const abo_forex = useSelector((state) => state.userReducer);
  const forexDate = moment(abo_forex.abo_forex).format("YYYY-MM-DD");
  const indiceDate = moment(abo_forex.abo_indice).format("YYYY-MM-DD");

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulating a 2-second delay for loading the uid
  }, []);

  if (loading) {
    return <Loader loading={loading} />;
  }
  return (
    <Router>
      {/* <Navbar/> */}
      <NavbarDemo />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" exact component={SignInForm} />
        <Route exact path="/reset-password" component={ResetPasswordForm} />
        <Route exact path="/resetSetNewPassword/:id" component={SetNewPassword} />
        <Route path="/register" exact component={SignUpForm} />

        <Route
          path="/profil"
          exact
          render={() => (uid ? <Profil /> : <Redirect to="/login" />)}
        />
        <Route
          path="/choix-de-la-salle"
          exact
          render={() => (uid ? <ChoixSalle /> : <Redirect to="/login" />)}
        />
        <Route
          path="/Salle-Indice"
          exact
          render={() =>
            uid ? (
              moment().isBefore(indiceDate) || moment().isSame(indiceDate) ? (
                <Indice />
              ) : (
                <Redirect to="/abonnement" />
              )
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          path="/Salle-Forex"
          exact
          render={() =>
            uid ? (
              moment().isBefore(forexDate) || moment().isSame(forexDate) ? (
                <Forex />
              ) : (
                <Redirect to="/abonnement" />
              )
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route path="/temoignages" exact component={Temoignages} />
        <Route path="/faq" exact component={Faq} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/abonnement" exact component={Abonnement} />
        <Route path="/mentions-légales" exact component={CVG} />
        <Route
          path="/admin"
          exact
          render={() => (uid ? <Admin /> : <Redirect to="/login" />)}
        />
        <Route
          path="/List-Users"
          render={() => (uid ? <ListUsers /> : <Redirect to="/login" />)}
        />
      </Switch>
      <Footer />
    </Router>
  );
};

export default Index;
