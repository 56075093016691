import React, { useContext, useState } from "react";
import axios from "axios";


// import js et scss

import "./../styles/ResetPasswordForm.scss";
import { LanguageContext } from "../components/AppContext";

const ResetPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { language } = useContext(LanguageContext);


  const handleResetPassword = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}api/user/reset-password`, { email })
      .then((res) => {
        setSuccessMessage(res.data.message);
        setErrorMessage("");
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        setSuccessMessage("");
      });
  };

  return (
    <div className="main-container">
      <div className="ResetPasswordForm">
        <form className="form-style" onSubmit={handleResetPassword}>
          <h2 className="h2-text">{language=='Fr'?'Réinitialiser votre mot de passe':'Reset your password'}</h2>
          <div className="form-group">
            <label className="comp1" htmlFor="email"><h4>{language=='Fr'?'Adresse e-mail :':'E-mail address :'} </h4></label>
            <input
              className="comp2"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {successMessage && <div className="success-message">{successMessage}</div>}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div className="btn-div">
            <button className="btn-1" type="submit">{language=='Fr'?'Réinitialiser le mot de passe':'Reset password'}</button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
