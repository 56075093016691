import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
//js et scss
import "./../styles/Home.scss";

// image
import Compteur from "./../images/Compteur.png";
import HerveImg from "./../images/Logo-Herve.png";

import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../components/Utils";
import Card from "../components/Post/Card";
import { getPosts } from "../actions/post.action";
import { LanguageContext, UidContext } from "../components/AppContext";
import { home } from "../Utils/Models";
import Loader from "../components/Loader";

const obj = {
  date: "",
  percentage: 0,
};
const Home = () => {
  const [loadPost, setLoadPost] = useState(true);

  const dispatch = useDispatch();
  const posts = useSelector((state) => state.postReducer);
  // const [data,setData]=useState([]);
  const [dataEn, setDataEn] = useState([]);
  const [dataFr, setDataFr] = useState([]);
  const { language } = useContext(LanguageContext);
  const [loading, isLoading] = useState(false);
  const uid = useContext(UidContext);
  const userData = useSelector((state) => state.userReducer);
  const isAdmin = uid && userData.role === "admin" ? true : false;
  const [edit, setEdit] = useState(false);
  const [drop, setDrop] = useState(0);
  const [tdata, setTdata] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [sendData, setSendData] = useState(obj);
  const [isLangSwitch, setIsLangSwitch] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [currentMonth,setCurrentMonth]=useState(null);
  const moment = require("moment");
  moment.locale(language);

  useEffect(() => {
    if (loadPost) {
      dispatch(getPosts());
      setLoadPost(false);
    }
  }, [loadPost, dispatch]);

  useEffect(() => {
    getTradeData();
    getTradeDataMontly();
    getHomeData();
  }, []);

  const getHomeData = () => {
    isLoading(true);
    home
      .getHomeData()
      .then((response) => {
        setDataEn(response?.language?.en);
        setDataFr(response?.language?.fr);
        isLoading(false);
      })
      .catch((error) => {
        console.log("Error while getting Home data", error);
      });
  };
  useEffect(() => {
    if (!isInitialRender) {
      setIsLangSwitch(true);
      setTimeout(() => {
        setIsLangSwitch(false);
      }, 1000);
    } else {
      setIsInitialRender(false);
    }
  }, [language]);
  // ------------------------------To get trade 5 tarde data to display in rolling
  const getTradeData = () => {
    home
      .getTradeData()
      .then((response) => {
        setTdata(response);
        setSendData({
          date: response[0]?.value?.date,
          percentage: response[0]?.value?.percentage,
        });
      })
      .catch((error) => {
        console.log("Error while getting Trade Data", error);
      });
  };
  // ------------------------------To get trade 5 tarde data Montly to display in rolling
  const getTradeDataMontly = () => {
    home
      .getTradeDataMonthly()
      .then((response) => {
        setMonthlyData(response);
        response.find(i=>i?.type==='Current Month')&& setCurrentMonth({
          date: new Date(),
          percentage: response[0]?.value,
        });
      })
      .catch((error) => {
        console.log("Error while getting Trade Data", error);
      });
  };
  console.log('CurrentMonth',currentMonth);
  // ------------------------------To add new data entry
  const setTradeData = () => {
    let data = {
      // static_id:
      //   tdata?.length == 0 ? 1 : tdata[tdata?.length - 1].static_id + 1, // To generate static id if need to oderby Static id
      date: sendData?.date,
      percentage: sendData?.percentage,
    };
    home
      .setTradeData(data)
      .then((response) => {
        getTradeData();
        getTradeDataMontly();

      })
      .catch((error) => {
        console.log("Error while Setting Trade Data", error);
      });
  };

  function createMarkup(data) {
    return { __html: data };
  }
  // ---------------------------To handle edit option to add new data of trade result
  const handleEdit = () => {
    setEdit(!edit);
  };

  const showMonthIndex=(date)=>{
  let index=  moment(date).month()

  return ++index;
  }
  return (
    <>
      {isLangSwitch && (
        <div className="loader-background">
          <span className="loader-text">Switching...</span>
        </div>
      )}

      {!loading ? (
        <div>
          <div className="section-1-bg">
            <div className="main-container">
              <section className="All-section1">
                <div className="section1-1">
                  <div className="Mot-fonda">
                    <div
                      className="headingDiv"
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.home_page_header_title)
                          : createMarkup(dataEn?.home_page_header_title)
                      }
                    />
                    <div
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.home_page_header_description)
                          : createMarkup(dataEn?.home_page_header_description)
                      }
                    />
                  </div>
                </div>
                <div className="section1-2">
                  <div className="card-titre">
                    <div>
                      <h1
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(dataFr?.home_page_header_card_title)
                            : createMarkup(dataEn?.home_page_header_card_title)
                        }
                      ></h1>
                    </div>
                  </div>
                  <div className="bloc-bac-compteur">
                    <div className="data-container">
                      <div className="compteur-digit">
                        <div>
                          <img src={Compteur}></img>
                        </div>
                        <div className="date-blk" >
                        <div className="date">
                          {edit ? (
                            <input
                              type="text"
                              name="percentage"
                              value={sendData?.percentage || 0}
                              disabled={!edit}
                              style={{
                                backgroundColor: edit ? "#fff" : "#0063a7",
                              }}
                              onChange={(e) =>
                                setSendData((prevState) => ({
                                  ...prevState,
                                  percentage: e.target.value,
                                }))
                              }
                            />
                          ) : (
                            <input
                              type="text"
                              name="percentage"
                              value={
                                sendData?.percentage
                                  ? sendData?.percentage + "%"
                                  : 0 + "%"
                              }
                              disabled
                              style={{
                                backgroundColor: edit ? "#fff" : "#0063a7",
                                color: edit ? "#000" : "#fff",
                              }}
                            />
                          )}
                          <p style={{ color: "#fff" }}>
                            {tdata?.length > 0
                              ? language === "Fr"
                                ? moment(sendData?.date).format("DD-MM-YYYY") +
                                  " : Semaine " +
                                  moment(sendData?.date).week()
                                : sendData?.date +
                                  " : Week " +
                                  moment(sendData?.date).week()
                              : "DD/MM/YYYY"}
                          </p>
                          
                        </div>
                        {!edit&&currentMonth&& <div className="date">
                          {!edit&& (
                            <input
                              type="text"
                              name="percentage"
                              value={
                                currentMonth?.percentage
                                  ? currentMonth?.percentage.toFixed(2) + "%"
                                  : 0 + "%"
                              }
                              disabled
                              style={{
                                backgroundColor: "rgba(0, 99, 167, 0)",
                                color:  "#fff",
                              }}
                            />
                          )}
                          <p style={{ color: "#fff",marginLeft:'5px' }}>
                            {tdata?.length > 0
                              ? language === "Fr"
                                ? 
                                  " Mois " +
                                 showMonthIndex(currentMonth?.date)
                                : 
                                  " Month " +
                                  showMonthIndex(currentMonth?.date)
                              : "DD/MM/YYYY"}
                          </p>
                          
                        </div>}
                        </div>
                      </div>
                      {isAdmin && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column-reverse",
                          }}
                        >
                          <div className="compteur-img" onClick={handleEdit}>
                            {!edit ? (
                              <svg
                                fill="goldenrod"
                                xmlns="http://www.w3.org/2000/svg"
                                height="1.5em"
                                viewBox="0 0 512 512"
                              >
                                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            ) : (
                              <svg
                                fill="white"
                                xmlns="http://www.w3.org/2000/svg"
                                height="1.5em"
                                viewBox="0 0 448 512"
                                onClick={setTradeData}
                              >
                                <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                              </svg>
                            )}
                          </div>
                          {edit && (
                            <input
                              type="date"
                              style={{
                                padding: "5px",
                                borderRadius: "5px",
                                border: "none",
                                marginBottom: "5px",
                              }}
                              value={sendData?.date}
                              onChange={(e) =>
                                setSendData((prevState) => ({
                                  ...prevState,
                                  date: e.target.value,
                                }))
                              }
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      className="retour"
                      onClick={() => {
                        getTradeData();
                        getTradeDataMontly();
                        setDrop(!drop);
                      }}
                    >
                      <p
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(dataFr?.home_page_header_card_footer)
                            : createMarkup(dataEn?.home_page_header_card_footer)
                        }
                      ></p>
                      <div className="chevron">
                        <i className="fa-solid fa-chevron-right"></i>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        className="droplisteffect"
                        style={{
                          height: !drop ? 0 : "auto",
                        }}
                      >
                      
                        {tdata?.slice(1)?.filter((item) => item?.value).length >
                        0 ? (
                          tdata
                            ?.slice(1)
                            ?.filter((item) => item?.value)
                            ?.map((item, index) => {
                              return (
                                <>
                                  <p
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: "5px",
                                      fontWeight: 700,
                                      paddingBottom: "5px",
                                      color: "#FFF",
                                    }}
                                  >
                                    <span>
                                      {item?.type !== "Current Week"
                                        ? (language === "Fr"
                                            ? "Semaine "
                                            : "Week ") + item?.type
                                        : language === "Fr"
                                        ? "Semaine en cours"
                                        : item?.type}
                                    </span>
                                    :
                                    <span
                                      style={{
                                        color:
                                          Math.sign(item?.value) === -1
                                            ? "red"
                                            : "#14B052",
                                      }}
                                    >
                                      {" " + item?.value.toFixed(2)}%
                                    </span>
                                  </p>
                                </>
                              );
                            })
                        ) : (
                          <p
                            style={{
                              textAlign: "center",
                              paddingBottom: "5px",
                            }}
                          >
                            {language == "Fr"
                              ? "Aucune date passée"
                              : "No Past Data"}
                          </p>
                        )}
                      </div>
                      <div
                        className="droplisteffect"
                        style={{
                          height: !drop ? 0 : "auto",
                        }}
                      >
                        {monthlyData?.filter((item) => item?.value).length >
                        0 ? (
                          monthlyData
                            ?.filter((item) => item?.value)
                            ?.map((item, index) => {
                              return (
                                <>
                                  <p
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: "5px",
                                      fontWeight: 700,
                                      paddingBottom: "5px",
                                      color: "#FFF",
                                    }}
                                  >
                                    <span>
                                      {item?.type !== "Current Month"
                                        ? (language === "Fr"
                                            ? "Mois "
                                            : "Month ") + item?.type
                                        : language === "Fr"
                                        ? "Mois en cours"
                                        : item?.type}
                                    </span>
                                    :
                                    <span
                                      style={{
                                        color:
                                          Math.sign(item?.value) === -1
                                            ? "red"
                                            : "#14B052",
                                      }}
                                    >
                                      {" " + item?.value.toFixed(2)}%
                                    </span>
                                  </p>
                                </>
                              );
                            })
                        ) : (
                          <p
                            style={{
                              textAlign: "center",
                              paddingBottom: "5px",
                            }}
                          >
                            {language == "Fr"
                              ? "Aucune date passée"
                              : "No Past Data"}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="button-s1">
                    <NavLink
                      to={uid ? "/abonnement" : "/login"}
                      className="btnNavlist"
                    >
                      {language == "Fr"
                        ? dataFr?.home_page_header_button_name
                        : dataEn?.home_page_header_button_name}
                    </NavLink>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="main-container">
            <section className="All-section2">
              <div className="bloc-img-text">
                <div className="bloc-etoile-text">
                  <div className="bloc-img">
                    {/* <img src={HerveImg} className="bloc-img-img"></img> */}
                    <img
                      src={
                        language == "Fr"
                          ? dataFr?.home_page_second_part_image
                          : dataEn?.home_page_second_part_image
                      }
                      className="bloc-img-img"
                    ></img>
                  </div>
                  <div className="bloc-text">
                    <h1
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.home_page_second_part_title)
                          : createMarkup(dataEn?.home_page_second_part_title)
                      }
                    ></h1>
                    <div>
                      <p
                        className="text-1"
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.home_page_second_part_description
                              )
                            : createMarkup(
                                dataEn?.home_page_second_part_description
                              )
                        }
                      />

                      <br />
                      <div className="bloc-text2-btn">
                        <div className="text-2">
                          <p
                            dangerouslySetInnerHTML={
                              language == "Fr"
                                ? createMarkup(
                                    dataFr?.home_page_second_part_second_description
                                  )
                                : createMarkup(
                                    dataEn?.home_page_second_part_second_description
                                  )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginLeft: "initial" }}>
                  <h1
                    className="herveHeader"
                    dangerouslySetInnerHTML={
                      language == "Fr"
                        ? createMarkup(dataFr?.home_page_third_part_title)
                        : createMarkup(dataEn?.home_page_third_part_title)
                    }
                  ></h1>
                </div>
                <div className="text-3">
                  <div className="CV1">
                    <p
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(
                              dataFr?.home_page_third_part_description
                            )
                          : createMarkup(
                              dataEn?.home_page_third_part_description
                            )
                      }
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="section-3-bg">
            <div className="main-container">
              <div className="All-section3">
                <div className="bloc-temoin">
                  <div
                    dangerouslySetInnerHTML={
                      language == "Fr"
                        ? createMarkup(dataFr?.home_page_footer_title)
                        : createMarkup(dataEn?.home_page_footer_title)
                    }
                  />

                  <p
                    className="text-1"
                    dangerouslySetInnerHTML={
                      language == "Fr"
                        ? createMarkup(dataFr?.home_page_footer_description)
                        : createMarkup(dataEn?.home_page_footer_description)
                    }
                  />
                </div>
                <div className="reviewsPara">
                  {posts &&
                    !isEmpty(posts[0]) &&
                    Array.isArray(posts) &&
                    posts
                      ?.filter((i) => i?.show_on_profile && i?.posterStatus)
                      .map((post) => {
                        return (
                          <p
                            style={{
                              fontFamily: "Arial, Helvetica, sans-serif",
                            }}
                          >
                            {post?.firstName +
                              ", " +
                              post?.lastName +
                              ", " +
                              moment(post?.created_at).format("MMMM YYYY") +
                              ": " +
                              (language === "Fr"
                                ? post?.message_fr
                                : post?.message_en)}
                          </p>
                        );
                      })}
                </div>
                <div className="bouton-s3">
                  <NavLink to="/temoignages">
                    <div
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.home_page_footer_button_name)
                          : createMarkup(dataEn?.home_page_footer_button_name)
                      }
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader loading={loading} />
      )}
    </>
  );
};

export default Home;
