import React, { useContext, useEffect, useState } from "react";

// scss
import "./../styles/Contact.scss";
import { LanguageContext, UidContext } from "../components/AppContext";
import { home } from "../Utils/Models";
import Loader from "../components/Loader";
import axios from "axios";

const Contact = () => {
  const [toEmail, setToEmail] = useState("");
  const { language} = useContext(LanguageContext);
  const [dataEn, setDataEn] = useState([]);
  const [dataFr, setDataFr] = useState([]);
  const [loading,isLoading]=useState(false)
  const [isLangSwitch, setIsLangSwitch] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [showMessage,setShowMessage]=useState('');
  useEffect(() => {
    getContactData();
  }, []);
  useEffect(()=>{
    if(!isInitialRender){
      setIsLangSwitch(true);
      setTimeout(() => {
        setIsLangSwitch(false);
      }, 1000);
    }else{
      setIsInitialRender(false);
    }
  },[language])
  // ---------------------To get all data of Conatct page -----------
  const getContactData = () => {
    isLoading(true)
    home
      .getContactData()
      .then((res) => {
      
        setDataEn(res?.language?.en);
        setDataFr(res?.language?.fr);
        isLoading(false)
      })
      .catch((error) => {
        console.log("Error while getting Home data", error);
      });
  };
  const handleEmailChange = (event) => {
    setToEmail(event.target.value);
  };

  const sendEmail=(sendData)=>{
  
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/user/send-email`,
      withCredentials: true,
      data:sendData
    }).then((response)=>{
      setShowMessage('Email has been sent')
 
    }).catch(err=>{
      setShowMessage('Error while sending email. Please try again')
      console.log("Error while sending email:"+err);
    })
  }
 
  const handleSubmit = (event) => {
    event.preventDefault();

    // const toEmail = "Contact@efficient-trading.com";
    const subject = document.getElementById("sujet").value;
    const message = document.getElementById("message").value;

    let sendData={
      email: toEmail,
        subject: subject,
        message: message,
    }
sendEmail(sendData);
    // const mailtoLink = `mailto:${toEmail}?subject=${encodeURIComponent(
    //   subject
    // )}&body=${encodeURIComponent(message)}`;

    // Ouvrir le lien mailto dans une nouvelle fenêtre ou un nouvel onglet
    // window.open(mailtoLink, "_blank");

    // Réinitialiser les champs du formulaire
    setToEmail("");
    document.getElementById("sujet").selectedIndex = 0;
    document.getElementById("message").value = "";
  };
  function createMarkup(data) {
    return { __html: data };
  }


  return (
    <>
    {isLangSwitch && <div className="loader-background"><span className="loader-text">Switching...</span></div>}
    {!loading?<div className="main-container">
      <div className="All-contact">
        <div>
          <h1>
            {language == "Fr"
              ? dataFr?.contact_page_header_title
              : dataEn?.contact_page_header_title}
          </h1>
          <p
            dangerouslySetInnerHTML={
              language == "Fr"
                ? createMarkup(dataFr?.contact_page_header_description)
                : createMarkup(dataEn?.contact_page_header_description)
            }
          />

          <p className="desc2" dangerouslySetInnerHTML={
              language == "Fr"
                ? createMarkup(dataFr?.contact_page_header_second_description)
                : createMarkup(dataEn?.contact_page_header_second_description)
            }/>
           
          
        </div>
        <form onSubmit={handleSubmit}>
          <h1>
            {language == "Fr"
              ? dataFr?.contact_page_form_header
              : dataEn?.contact_page_form_header}
          </h1>
          <div className="input">
            <label htmlFor="email">
              {language == "Fr"
                ? dataFr?.contact_page_form_email
                : dataEn?.contact_page_form_email}
            </label>
            <input
              type="email"
              id="email"
              name="to"
              value={toEmail}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div className="input">
            <label htmlFor="sujet">
              {language == "Fr"
                ? dataFr?.contact_page_form_subject
                : dataEn?.contact_page_form_subject}
            </label>
            <select id="sujet" required>
            {language == "Fr"
                ? dataFr?.contact_page_form_subject_fields?.map((item)=>{
                  return <option value={item?.name} key={item?.id}>{item?.name}</option>
                })
                : dataEn?.contact_page_form_subject_fields?.map((item)=>{
                  return <option value={item?.name} key={item?.id}>{item?.name}</option>
                })}

              
            </select>
          </div>
          <div className="input">
            <label htmlFor="message">
              {language == "Fr"
                ? dataFr?.contact_page_form_message
                : dataEn?.contact_page_form_message}
            </label>
            <textarea id="message" name="message" rows="5" required></textarea>
            <br />
          </div>
          <div className='error-message' style={showMessage=='Error while sending email. Please try again'?{color:'red'}:{color:'green'}}>
          {showMessage}
          </div>
          <div className="btn-contact">
            <input
              id="submit"
              type="submit"
              value={
                language == "Fr"
                  ? dataFr?.contact_page_form_button_name
                  : dataEn?.contact_page_form_button_name
              }
            />
            {/*<input type="reset" id='reset' value="Effacer"></input>*/}
          </div>
          
        </form>
      </div>
    </div>:<Loader loading={loading}/>}
    </>
  );
};

export default Contact;
