import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { LanguageContext, ProductID, UidContext } from '../../components/AppContext';
import './../../styles/Salle-indice.scss';
import './../../styles/Salle-indice2.scss';


// import js et scss et images
import publicAnnonce from './../../images/salles/Public announce.png';
import png_cirque from './../../images/salles/cirque.png';
import png_Green from './../../images/salles/green.png';
import openposition from './../../images/salles/openpositions.png';
import png_Orange from './../../images/salles/orange.png';
import png_Red from './../../images/salles/red.png';
//import barretrade from './../../images/salles/trade-exemple.png';

//Import des sons
import Sound_TheEFTSignals from './../../audio/TheEFTSignals.mp3';

import Sound_ThinkBigProfit from './../../audio/ThinkBigProfit.mp3';
import Sound_ThinkLittleProfit from './../../audio/ThinkLittleProfit.mp3';
import Sound_bund from './../../audio/bund.mp3';
import Sound_buyBund from './../../audio/buyBund.mp3';
import Sound_buyCac from './../../audio/buyCac.mp3';
import Sound_buyDax from './../../audio/buyDax.mp3';
import Sound_buyDowjones from './../../audio/buyDowjones.mp3';
import Sound_buyFootsee from './../../audio/buyFootsee.mp3';
import Sound_buyNasdaq from './../../audio/buyNasdaq.mp3';
import Sound_cac from './../../audio/cac.mp3';
import Sound_circus from './../../audio/circus_2.mp3';
import Sound_dax from './../../audio/dax.mp3';
import Sound_dowjones from './../../audio/dowjones.mp3';
import Sound_exit from './../../audio/exit.mp3';
import Sound_footsee from './../../audio/footsee.mp3';
import Sound_green from './../../audio/green.mp3';
import Sound_stayAside from './../../audio/im.mp3';
import Sound_newmessage from './../../audio/new-message.mp3';
import Sound_hello from './../../audio/message.mp3';
import Sound_goodbye from './../../audio/goodbye.mp3';
import Sound_GoLong from './../../audio/goLong.mp3';
import Sound_GoShort from './../../audio/goShort.mp3';
import Sound_default from './../../audio/im.mp3';
import Sound_nasdaq from './../../audio/nasdaq.mp3';
import Sound_orange from './../../audio/orange.mp3';
import Sound_ready from './../../audio/ready.mp3';
import Sound_red from './../../audio/red.mp3';
import Sound_reinforcePosition from './../../audio/reinforcePosition.mp3';
import Sound_reinforcedPosition from './../../audio/reinforcedPosition.mp3';
import Sound_sellBund from './../../audio/sellBund.mp3';
import Sound_sellCac from './../../audio/sellCac.mp3';
import Sound_sellDax from './../../audio/sellDax.mp3';
import Sound_sellDowjones from './../../audio/sellDowjones.mp3';
import Sound_sellFootsee from './../../audio/sellFootsee.mp3';
import Sound_sellNasdaq from './../../audio/sellNasdaq.mp3';
import Sound_Gamble from './../../audio/stayAside.mp3';
import Sound_stop_entry_price from './../../audio/stop_entry_price.mp3';
import Sound_beep from './../../audio/im.mp3';
import TradingPanel from '../../components/TradingPanel';


/*============== Début de la Page Indice ==================*/
const Indice = () => {
  const pannelRef = useRef(null);
  const chatRef = useRef(null);
  const product = useContext(ProductID);
  const uid = useContext(UidContext);
 // const dispatch = useDispatch();
  const userData = useSelector((state) => state.userReducer);
  const isAdmin = uid && userData.role === 'admin' ? true : false;
  const moment = require('moment');
  const pseudo = userData.pseudo;
  //const abo_EndDate = userData.aboindices;
  const { language } = useContext(LanguageContext);
  const [isLangSwitch, setIsLangSwitch] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);


  useEffect(() => {
    if (!isInitialRender) {
      setIsLangSwitch(true);
      setTimeout(() => {
        setIsLangSwitch(false);
      }, 1000);
    } else {
      setIsInitialRender(false);
    }
  }, [language]);


  /*============== Equal Height of Component ==================*/
  useEffect(() => {
    const setEqualHeight = () => {
        if(pannelRef.current && chatRef.current){
        const pannelHeight = pannelRef.current.clientHeight;
        chatRef.current.style.maxHeight = `${pannelHeight}px`;
      }
    };

    setEqualHeight();
    window.addEventListener('resize', setEqualHeight);

    return () => {
      window.removeEventListener('resize', setEqualHeight);
    };
  }, []);



/*============== Gestion des message reçu du serveur par la socket ==================*/
  //connexion à la socket Indices du server
    const socket = io(process.env.REACT_APP_URL_SALLE_Indices, {
      transports: ['websocket'],
      protocol: ['ws']
    });  
  //Connection de la socket
    socket.on('connect', () => {
      socket.emit('pseudo', pseudo);
      //On demande le pseudo + edit de l'onglet de la page avec le pseudo
      document.title = pseudo + ' [Salle Indices] - Efficient-Trading';
    });

  //Un User entre dans la salle
    socket.on('newUserIndice', (pseudo) => {
      //console.log ('NewUersIndice msg received');
      if(isAdmin) {
        createSimpleElement('newUserIndice', pseudo + ' a rejoint la Salle')
      }
    });

  //Un user quitte la salle
  socket.on('quitUserIndice', (pseudo) => {
    //console.log ('quitUserIndice msg received');
    if(isAdmin) {
      createSimpleElement('newUserIndice', pseudo + ' a quitté la Salle')
    }
  });

  //Deconnection de la socket
  socket.on('Disconnect', () => {
    //console.log('Disconnected from Indices server');
    // Edit de l'onglet de la page avec le pseudo
    document.title = '[Salle Indices] - Efficient-Trading';
  });

  //Nouveau message a tous les users
  socket.on("newAnnonceAllIndice", (messages) => {
    //console.log ('newAnnonceAllIndice msg received');
    // Purge des messages pour éviter les doublons
    RefreshElements('annoncepublic');
    // Display message
    createElementFunctionAnnonce('newAnnonceAllIndice', messages);
  });

  //Nouveau message a tous les users
  socket.on('newMessageAllIndice', (messages) => {
    //console.log ('newMessageAllIndice msg received');
      // Display message
      createElementFunction('newMessageAllIndice', messages);

      //Sparadra pour corriger doublons
      socket.emit('MessageRefresh');
     
  });

  //Chargement des anciens messages
  socket.on('oldMessageIndice', (messages) => {
    //console.log ('oldMessageIndice msg received');
    try {
      if(isAdmin) {
        // Purge des messages pour éviter les doublons
        RefreshElements('msgContainerAdmin');
      } else {
        // Purge des messages pour éviter les doublons
        RefreshElements('msgContainer');
      }
       // Affichage des messages
       messages.forEach( message => {
        createElementFunction('oldMessagesIndice', message);
      });
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in oldMessageIndice ' + err);
    }
});

  // CHargement des Trades
  socket.on('OpenPositionsIndice', (trades) => {
    //console.log ('OpenPositionsIndice msg received');
    try {
      RefreshElements('Trades');
      trades.forEach( trade => {
          //console.log('New trade retrieved:'+ trade.product);
          displayTrade(trade);
      });
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in OpenPositionsIndice ' + err);
    } 
  });

  // New trade received
  socket.on('newTradeAllIndice', (trade) => {
    try {
      //RefreshElements('Trades');
        displayTrade(trade);
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in newTradeAllIndice ' + err);
    } 
  });

   // écoute du Socket UsersList closed
 socket.on('ShowUsersList', (userslist) => {
  try {
    // Display liste des users
    RefreshElements('UserListContainerAdmin');
    ShowConnectedUsers('UserListContainerAdmin',userslist);
  } catch (err) {
    //Gestion de l'erreur
    console.log('Error in ShowUsersList ' + err);
  } 
  });
 
  // écoute du Pocket closed
 socket.on('SocketClosed', (pseudo) => {
  console.log('Socket has been closed by server'); 
  });

/*============== Fin Socket io ==================*/ 
    
/*=================== Fonctions de la salle ===================*/
  // Purge des donnée dans un container
  function RefreshElements(ContainerToRefresh){
    const containerDiv = document.getElementById(ContainerToRefresh);
    if (containerDiv) {
      while (containerDiv.firstElementChild) {
        containerDiv.removeChild(containerDiv.firstElementChild); 
      }
    };
    socket.emit('getUsersList');
  };

  // List des utilisateurs connectés
  function ShowConnectedUsers(ContainerToAdd,content){
    if(isAdmin) {
        content.content.forEach((user) => {
          const newElement = document.createElement('div');
          newElement.classList.add('newUserIndice', 'users');
          newElement.innerHTML = user;
          document.getElementById(ContainerToAdd).appendChild(newElement);
          scrollToBottom(ContainerToAdd);
      });
    }
  };

  // Nouveau message admin
  function createSimpleElement(element,message){
    if(isAdmin) {
      /*
      const newElement = document.createElement('div');
      newElement.classList.add(element, 'message');
      newElement.innerHTML = message;
      document.getElementById('msgContainerAdmin').appendChild(newElement);
      scrollToBottom('msgContainerAdmin');
      */
      var audio = new Audio(Sound_default);
      audio.play();
    }
  };


  // Création d'un message annonce Public 
  function createElementFunctionAnnonce(element, message) {
    //Format du Text
    var TexttoDisplay='<FONT SIZE="4pt" STYLE="font-weight:bold" COLOR="#8A2BE2";>' + message+'</FONT>';
    // Créer une nouvelle div avec l'ID spécifié
    const newElement = document.createElement('div');
    newElement.classList.add(element, 'Annonce');
    newElement.innerHTML = TexttoDisplay;
    document.getElementById('annoncepublic').appendChild(newElement);
    if (message.length>0){
      var audio = new Audio(Sound_newmessage);
      audio.play(); 
    }
  };


  // Création d'un message
  function createElementFunction(element, content) {
    try {
      //console.log ('Nouveau message : '+ content.content);
      //Container à adresser
      var ContainerMessage ='';
      const newElement = document.createElement('div');
      // Heure du message
      const formattedTimestamp = moment(content.timestamp).format('HH:mm:ss');
      //Text a afficher 
      var TexttoDisplay=content.content;
      //Text a analyser 
      var casevalue = content.content; 
      const casevaluetab = casevalue.split("(");
      if (casevaluetab[0] !== ""){
        casevalue = casevaluetab[0];
      }
        // Select Sound to be played
        var preaudio = undefined;
        var audio = undefined;

        //Traitement des messages
        switch (casevalue){
          //Traitement du messsage Ready for
          case "Ready for Bund":
            preaudio = new Audio(Sound_ready);
            audio = new Audio(Sound_bund);
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            break;
          case "Ready for CAC":
            preaudio = new Audio(Sound_ready);
            audio = new Audio(Sound_cac);
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            break;
          case "Ready for DAX":
            preaudio = new Audio(Sound_ready);
            audio = new Audio(Sound_dax);
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            break;
          case "Ready for FTSE":
            preaudio = new Audio(Sound_ready);
            audio = new Audio(Sound_footsee);
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            break;
          case "Ready for DJ":
            preaudio = new Audio(Sound_ready);
            audio = new Audio(Sound_dowjones);
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            break;  
          case "Ready for Nsdq":
            preaudio = new Audio(Sound_ready);
            audio = new Audio(Sound_nasdaq);
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            break;
          case "Cancel Bund":
            preaudio = new Audio(Sound_default);
            audio = new Audio(Sound_bund);
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT><p> No Need to stand ready anymore</p>';
            break;
          case "Cancel CAC":
            preaudio = new Audio(Sound_default);
            audio = new Audio(Sound_cac);
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT><p> No Need to stand ready anymore</p>';
            break;
          case "Cancel DAX":
            preaudio = new Audio(Sound_default);
            audio = new Audio(Sound_dax);
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT><p> No Need to stand ready anymore</p>';
            break;
          case "Cancel FTSE":
            preaudio = new Audio(Sound_default);
            audio = new Audio(Sound_footsee);
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT><p> No Need to stand ready anymore</p>';
            break;
          case "Cancel DJ":
            preaudio = new Audio(Sound_default);
            audio = new Audio(Sound_dowjones);
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT><p> No Need to stand ready anymore</p>';
            break;  
          case "Cancel Nsdq":
            preaudio = new Audio(Sound_default);
            audio = new Audio(Sound_nasdaq);
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT><p> No Need to stand ready anymore</p>';
            break; 

          //Traitement des Achats
          case "BuyMarket":
            preaudio = undefined;
            audio = undefined;
            break;
          case "GO Long DAX":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_GoLong);
            audio = new Audio(Sound_buyDax);
            break;
          case "GO Long Bund":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_GoLong);
            audio = new Audio(Sound_buyBund);
            break;
          case "GO Long CAC":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_GoLong);
            audio = new Audio(Sound_buyCac);
            break;
          case "GO Long FTSE":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_GoLong);
            audio = new Audio(Sound_buyFootsee);
            break;
          case "GO Long DJ":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_GoLong);
            audio = new Audio(Sound_buyDowjones);
            break;
          case "GO Long Nsdq":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_GoLong);
            audio = new Audio(Sound_buyNasdaq);
            break;
          case "Ready to BUY":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = undefined;
            audio = new Audio(Sound_beep);
            break;
          case "Ready to BUY Bund":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_beep);
            audio = new Audio(Sound_bund);
            break;
          case "Ready to BUY DAX":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_beep);
            audio = new Audio(Sound_dax);
            break;
          case "Ready to BUY CAC":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_beep);
            audio = new Audio(Sound_cac);
            break;
          case "Ready to BUY FTSE":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_beep);
            audio = new Audio(Sound_footsee);
            break;
          case "Ready to BUY DJ":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_beep);
            audio = new Audio(Sound_dowjones);
            break;
          case "Ready to BUY Nsdq":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_beep);
            audio = new Audio(Sound_nasdaq);
            break;
          case "Exit Long DAX":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_exit);
            audio = new Audio(Sound_dax);
            break;
          case "Exit Long Bund":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_exit);
            audio = new Audio(Sound_bund);
            break;
          case "Exit Long CAC":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_exit);
            audio = new Audio(Sound_cac);
            break;
          case "Exit Long FTSE":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_exit);
            audio = new Audio(Sound_footsee);
            break;
          case "Exit Long DJ":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_exit);
            audio = new Audio(Sound_dowjones);
            break;
          case "Exit Long Nsdq":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#008000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_exit);
            audio = new Audio(Sound_nasdaq);
            break;

          //Traitement des Ventes
          case "Sell Market":
            preaudio = undefined;
            audio = undefined;
            break;
          case "GO Short Bund":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_GoShort);
            audio = new Audio(Sound_sellBund);
            break;
          case "GO Short DAX":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_GoShort);
            audio = new Audio(Sound_sellDax);
            break;
          case "GO Short CAC":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_GoShort);
            audio = new Audio(Sound_sellCac);
            break;
          case "GO Short FTSE":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_GoShort);
            audio = new Audio(Sound_sellFootsee);
            break;
          case "GO Short DJ":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_GoShort);
            audio = new Audio(Sound_sellDowjones);
            break;
          case "GO Short Nsdq":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_GoShort);
            audio = new Audio(Sound_sellNasdaq);
            break;
          case "Ready to SELL":
              TexttoDisplay='<FONT SIZE="4pt" COLOR="#FF0000";>' + content.content+'</FONT>';
              preaudio = undefined;
              audio = new Audio(Sound_beep);
              break;
          case "Ready to SELL Bund":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_beep);
            audio = new Audio(Sound_bund);
            break;
          case "Ready to SELL DAX":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_beep);
            audio = new Audio(Sound_dax);
            break;
          case "Ready to SELL CAC":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_beep);
            audio = new Audio(Sound_cac);
            break;
          case "Ready to SELL FTSE":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_beep);
            audio = new Audio(Sound_footsee);
            break;
          case "Ready to SELL DJ":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_beep);
            audio = new Audio(Sound_dowjones);
            break;
          case "Ready to SELL Nsdq":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_beep);
            audio = new Audio(Sound_nasdaq);
            break;
          case "Exit Short Bund":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_exit);
            audio = new Audio(Sound_bund);
            break;
          case "Exit Short DAX":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_exit);
            audio = new Audio(Sound_dax);
            break;
          case "Exit Short CAC":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_exit);
            audio = new Audio(Sound_cac);
            break;
          case "Exit Short FTSE":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_exit);
            audio = new Audio(Sound_footsee);
            break;
          case "Exit Short DJ":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_exit);
            audio = new Audio(Sound_dowjones);
            break;
          case "Exit Short Nsdq":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#FF0000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_exit);
            audio = new Audio(Sound_nasdaq);
            break; 

          // Traitement du Think BIG Profit
          case "Think Big Profit Bund":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_ThinkBigProfit);
            audio = new Audio(Sound_bund);
            break;
          case "Think Big Profit DAX":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_ThinkBigProfit);
            audio = new Audio(Sound_dax);
            break;
          case "Think Big Profit CAC":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_ThinkBigProfit);
            audio = new Audio(Sound_cac);
            break;
          case "Think Big Profit FTSE":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_ThinkBigProfit);
            audio = new Audio(Sound_footsee);
            break;
          case "Think Big Profit DJ":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_ThinkBigProfit);
            audio = new Audio(Sound_dowjones);
            break;
          case "Think Big Profit Nsdq":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_ThinkBigProfit);
            audio = new Audio(Sound_nasdaq);
            break; 

          // Traitement du New Price 
          case "New Price Bund":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_ThinkLittleProfit);
            audio = new Audio(Sound_bund);
            break;
          case "New Price DAX":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_ThinkLittleProfit);
            audio = new Audio(Sound_dax);
            break;
          case "New Price CAC":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_ThinkLittleProfit);
            audio = new Audio(Sound_cac);
            break;
          case "New Price FTSE":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_ThinkLittleProfit);
            audio = new Audio(Sound_footsee);
            break;
          case "New Price DJ":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_ThinkLittleProfit);
            audio = new Audio(Sound_dowjones);
            break;
          case "New Price Nsdq":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = new Audio(Sound_ThinkLittleProfit);
            audio = new Audio(Sound_nasdaq);
            break; 

          //Traitement des Pastilles  
          case "Green":
            TexttoDisplay = '<img src='+png_Green+' width="20">';
            preaudio = undefined;
            audio = new Audio(Sound_green);
            break;
          case "Orange":
            TexttoDisplay = '<img src='+png_Orange+' width="20">';
            preaudio = undefined;
            audio = new Audio(Sound_orange);
            break;
          case "Red":
            TexttoDisplay = '<img src='+png_Red+' width="20">';   
            preaudio = undefined;
            audio = new Audio(Sound_red);
            break;

          //Others Signals
          case "Reinforce position ":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>' + content.content+'</FONT>';
            preaudio = undefined;
            audio = new Audio(Sound_reinforcePosition);
            break;
          case "Reinforced":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>Position has been Reinforced</FONT>';
            preaudio = undefined;
            audio = new Audio(Sound_reinforcedPosition);
            break;
          case "Stop @ entry price":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>Stop @ entry price</FONT>';
            preaudio = undefined;
            audio = new Audio(Sound_stop_entry_price);
            break; 
          case "Cancel":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#000000";>Cancel Ready Signal</FONT>';
            preaudio = undefined;
            audio = new Audio(Sound_stayAside)
            break;  
          case "Jingle":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#8A2BE2";>Departure</FONT>';
            preaudio = undefined;
            audio = new Audio(Sound_TheEFTSignals);

            break; 
          case "GAMBLE":
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#C0C0C0";>This is Gamble</FONT>';
            preaudio = undefined;
            audio = new Audio(Sound_Gamble);
            break;   
          case "Cirque":
            TexttoDisplay='<img src='+png_cirque+' width="120">';
            preaudio = undefined;
            audio = new Audio(Sound_circus);
            break;   
          case "Hello everyone!":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
            preaudio = undefined;
            audio = new Audio(Sound_hello);
            break;   
          case "Good evening!":
            TexttoDisplay='<FONT SIZE="5pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
            preaudio = undefined;
            audio = new Audio(Sound_goodbye);
            break;   
          default:
            TexttoDisplay='<FONT SIZE="4pt" COLOR="#C0C0C0";>' + content.content+'</FONT>';
            preaudio = undefined;
            audio = new Audio(Sound_default);      
            break;   
        }
        //Define container to be addressed
        if(isAdmin) {
          ContainerMessage = 'msgContainerAdmin';
        } else {
          ContainerMessage = 'msgContainer';
        }
        // Use the right format to be sent to the backend for the message
        switch (element) {
          case 'newMessageAllIndice':
            newElement.classList.add(element, 'message')
            newElement.innerHTML = "[" + formattedTimestamp + "] " + '' + content.sender + ' : ' + TexttoDisplay
            document.getElementById(ContainerMessage).appendChild(newElement);
            scrollToBottom(ContainerMessage);
            if (preaudio !== undefined) preaudio.play(); 
            if (audio !== undefined) audio.play(); 
            break;

          case 'oldMessagesIndice':
            newElement.classList.add( element, 'messages')
            newElement.innerHTML = "[" + formattedTimestamp + "] " + '' + content.sender + ' : ' + TexttoDisplay ;
            document.getElementById(ContainerMessage).appendChild(newElement);
            scrollToBottom(ContainerMessage);
            break;

          default:
            break;
      }
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in createElementFunction ' + err);
    }
  }
  function displayTrade(trade) {
    try {
      //console.log('Affichage du trade: ' + trade.product);
      const CouleurDefault = '#FFFFFF'; 
      var Couleur = CouleurDefault;
      const newElement = document.createElement('div');
      newElement.classList.add('trades');
      //Formatage du Trade à afficher
      if (trade.sens ==='S') {Couleur = "#FF0000"} else {Couleur = "#00FF00"}; 
      newElement.innerHTML =  formattexthtml('4',Couleur,'>>') + formattexthtml('4',CouleurDefault,trade.sens) + formattexthtml('4',Couleur,'>>') + formattexthtml('4',CouleurDefault,trade.product) +formattexthtml('4',Couleur,'>>')+ formattexthtml('4',CouleurDefault,trade.price)+formattexthtml('4',Couleur,'>>') + formattexthtml('4',CouleurDefault,trade.Newprice) + formattexthtml('4',Couleur,'>> STOP ') + formattexthtml('4',CouleurDefault,trade.StopPrice);
      document.getElementById('Trades').appendChild(newElement);
      scrollToBottom('Trades');
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in displayTrade ' + err);
    }
  };

  function formattexthtml(size, couleur, message) {
    try {
      var valeurreturn = '<FONT SIZE="' + size + 'pt" COLOR="' + couleur + '";>' + message + '</FONT>';
      return valeurreturn;
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in formattexthtml' + err);
    }
  };


  // ========= Gestion de l'interface UI =========== // 
  // Scroll Down de la Div
  function scrollToBottom(ContainerToRefresh) {
    try {
      const chatDiv = document.getElementById(ContainerToRefresh);
      chatDiv.scrollTop = chatDiv.scrollHeight - chatDiv.clientHeight;
    } catch (err) {
      //Gestion de l'erreur
      console.log('Error in scrollToBottom' + err);
    }
  };

  // Gestion du clic des Boutons
  const onClickSend = (Value) => {
    //Récupération du message à afficher
    var textInput = Value;
    //Récupération Date
    var trade = Date.now();
    //Récupération du Produit
    var currenttickers = document.getElementById('Ticker').value.substring(9);
    //Récupération du Prix
    var currentvalue  = Number(document.getElementById('Pricepoint').value.replace(',','.'));
    //Initialisation variable Stop
    var StopValue = 0;
    // Si Produit sélectionné et Prix renseigné on traite un Trade sur Go Long/Short et Profits
    if (currenttickers !== "" && currentvalue >0) { 
      //calcul du stop price
      switch (currenttickers) {
        case " Bund":
          StopValue=20;
          break;
        case " DAX":
          StopValue=20;
          break;
        case " CAC":
          StopValue=15;
          break;
        case " FTSE":
          StopValue=15;
          break;
        case " DJ":
          StopValue=30;
          break;
        case " Nsdq":
          StopValue=20;
          break; 
        default :
          StopValue=20;
          break; 
      }
    }

    switch (textInput) {
      case "PublicAnnonce":
        //Récupération texte à envoyer
        var textaenvoyer = document.getElementById('directTextInput').value;
        // Envoi du message à la socket pour les clients
        socket.emit('NewPublicAnnonce', textaenvoyer );
        // Display message
        createElementFunctionAnnonce('newAnnonceAllIndice', textaenvoyer);
        //On efface le message
        document.getElementById('directTextInput').value = "";
        break; 

      case "CustomAnnonce":
        var textaenvoyer = document.getElementById('directTextInput').value;
        if (textaenvoyer.length > 0) {
          // Envoi du message à la socket pour les clients
          socket.emit('newMessageIndice', textaenvoyer);
          // Display message
          createElementFunction('newMessageIndice', {sender: pseudo, content: textaenvoyer});
          //On efface le message
          document.getElementById('directTextInput').value = '';
        }
        break;

      case "Ready to SELL":
        if (currenttickers !== "") { 
          textInput = Value + currenttickers;
          // Envoi du message à la socket pour les clients
          socket.emit('newMessageIndice', textInput );
          // Affichage du message sur la console
          createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
        }
        break;

      case "Ready to BUY":
        if (currenttickers !== "") { 
          textInput = Value + currenttickers;
          // Envoi du message à la socket pour les clients
          socket.emit('newMessageIndice', textInput );
          // Affichage du message sur la console
          createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
        }
        break;

      case "Cancel":
        if (currenttickers !== "") { 
          document.getElementById('Ticker').value = "";
          // Envoi du message à la socket pour les clients
          socket.emit('newMessageIndice', textInput );
          // Affichage du message sur la console
          createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
        }
        break;

        case "GO Long":
          if (currenttickers !== "" && currentvalue >0) { 
            // Formatage du trade à envoyer
            trade = trade + ';' + currenttickers + ';L;' + currentvalue + ';'+ (currentvalue - StopValue);
            // Envoi du trade à la socket pour les clients
            socket.emit('newTradeIndice', trade );
            // Formatage du texte à envoyer
            textInput = Value + currenttickers + '(' + currentvalue + ')';
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
            // Remise à Zéro de la current value
            document.getElementById('Pricepoint').value=""; 
          }
        break;

        case "GO Short":
          if (currenttickers !== "" && currentvalue >0) { 
            // Formatage du trade à envoyer
            trade = trade + ';' + currenttickers + ';S;' + currentvalue+ ';'+ (currentvalue + StopValue);
            // Envoi du trade à la socket pour les clients
            socket.emit('newTradeIndice', trade );
            // Formatage du texte à envoyer
            textInput = Value + currenttickers + '(' + currentvalue + ')';
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
            // Remise à Zéro de la current value
            document.getElementById('Pricepoint').value=""; 
          }
        break;

        case "NewPrice_LP_L":
          if (currenttickers !== "" && currentvalue >0) { 
            // Formatage du trade à mettre à jour
            trade = currenttickers + ';' + currentvalue + ';Open;L';
            // Envoi du trade à la socket pour les clients
            socket.emit('UpdateTradeIndice', trade );
            // Formatage du texte à envoyer;
            textInput = "New Price" + currenttickers + '(' + currentvalue + ')';
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
            // Remise à Zéro de la current value
            document.getElementById('Pricepoint').value=""; 
          }
        break;

        case "NewPrice_BP_L":
          if (currenttickers !== "" && currentvalue >0) { 
            // Formatage du trade à mettre à jour
            trade = currenttickers + ';' + currentvalue + ';Open;L';
            // Envoi du trade à la socket pour les clients
            socket.emit('UpdateTradeIndice', trade );
            // Formatage du texte à envoyer; 
            textInput = "Think Big Profit" + currenttickers + '(' + currentvalue + ')';
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
            // Remise à Zéro de la current value
            document.getElementById('Pricepoint').value=""; 
          }
        break;

        case "NewPrice_LP_S":
          if (currenttickers !== "" && currentvalue >0) { 
            // Formatage du trade à mettre à jour
            trade = currenttickers + ';' + currentvalue + ';Open;S';
            // Envoi du trade à la socket pour les clients
            socket.emit('UpdateTradeIndice', trade );
            // Formatage du texte à envoyer;
            textInput = "New Price" + currenttickers + '(' + currentvalue + ')';
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
            // Remise à Zéro de la current value
            document.getElementById('Pricepoint').value=""; 
          }
        break;

        case "NewPrice_BP_S":
          if (currenttickers !== "" && currentvalue >0) { 
            // Formatage du trade à mettre à jour
            trade = currenttickers + ';' + currentvalue + ';Open;S';
            // Envoi du trade à la socket pour les clients
            socket.emit('UpdateTradeIndice', trade );
            // Formatage du texte à envoyer; 
            textInput = "Think Big Profit" + currenttickers + '(' + currentvalue + ')';
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
            // Remise à Zéro de la current value
            document.getElementById('Pricepoint').value=""; 
          }
        break;

        case "Exit Long":
          if (currenttickers !== "" && currentvalue >0) { 
            // Formatage du trade à mettre à jour
            trade = currenttickers + ';' + currentvalue + ';Closed;L';
            // Envoi du trade à la socket pour les clients
            socket.emit('UpdateTradeIndice', trade );
            // Formatage du texte à envoyer; 
            textInput = Value + currenttickers+ '(' + currentvalue + ')';
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
            // Remise à Zéro de la current value
            document.getElementById('Pricepoint').value=""; 
          }
        break;

        case "Exit Short":
          if (currenttickers !== "" && currentvalue >0) { 
            // Formatage du trade à mettre à jour
            trade = currenttickers + ';' + currentvalue + ';Closed;S';
            // Envoi du trade à la socket pour les clients
            socket.emit('UpdateTradeIndice', trade );
            // Formatage du texte à envoyer; 
            textInput = Value + currenttickers+ '(' + currentvalue + ')';
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
            // Remise à Zéro de la current value
            document.getElementById('Pricepoint').value=""; 
          }
        break;

        case "Delete Long":
          if (currenttickers !== "") { 
            // Formatage du trade à mettre à jour
            trade = currenttickers + ';' + currentvalue + ';Delete;L';
            // Envoi du trade à la socket pour les clients
            socket.emit('DeleteTradeIndice', trade );
            // Formatage du texte à envoyer; 
            textInput = 'Typing error, ongoing correction on ' + currenttickers;
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
          }
        break;

        case "Delete Short":
          if (currenttickers !== "") { 
            // Formatage du trade à mettre à jour
            trade = currenttickers + ';' + currentvalue + ';Delete;S';
            // Envoi du trade à la socket pour les clients
            socket.emit('DeleteTradeIndice', trade );
            // Formatage du texte à envoyer; 
            textInput = 'Typing error, ongoing correction on ' + currenttickers;
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
          }
        break;

        case "Remove Last message":
            // Envoi de la demande de suppression message
            socket.emit('RemoveLstMsg');
        break;


        case "Reinforce":
          if (currenttickers !== "" && currentvalue >0) { 
            // Formatage du texte à envoyer; 
            textInput = 'Reinforce position (@' + currentvalue + ')' + currenttickers;
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
            // Remise à Zéro de la current value
            document.getElementById('Pricepoint').value=""; 
          }
        break;

        case "Reinforced":
          if (currenttickers !== "") { 
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
          }
        break;

        case "Stop @ entry price":
          if (currenttickers !== "") { 
            // Envoi du message à la socket pour les clients
            socket.emit('newMessageIndice', textInput );
            // Affichage du message sur la console
            createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
          }
        break;

      default :
        if (textInput.substring(0,9) === "Ready for") {
            //Si Ticker sélectionné on traite le ready for.
            document.getElementById('Ticker').value = textInput;
        }
        // Envoi du message à la socket pour les clients
        socket.emit('newMessageIndice', textInput );
        // Affichage du message sur la console
        createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
        break; 
    }
  }
    return (
      <>
      {isLangSwitch && (
      <div className="loader-background" style={{top:0}}>
        <span className="loader-text">Switching...</span>
      </div>
    )}
        <div className='all-Indice'>
          
          {!isAdmin ?(
            <div className='main-chat-conatiner'>
            
            <div className='chat-container' >
              <div className="entete-salle" >
                <div className="open-position" >
                  <div style={{margin:'0px'}}>
                  <img className='openposition-barre' src={openposition} alt="openposition"></img>
                  </div>
                  <div className="barre-trade" id='Trades'>

                  </div>
                </div>
              </div>
              <div className="titresalle">
                  <h1 className='titre'>{language==='Fr'?"Salle de marchés Indices":"Indice trading room"}</h1>
                </div>
            </div>
              <div className='msgContainer' id='msgContainer'>           
              </div> 
              <div className="publicAnnonce">
                <div className="img-public">
                  <img src={publicAnnonce} alt="img-hautparleur"></img>
                </div>
                <div className="annonceplublicadmin" id='annoncepublic'>
                </div>           
              </div>
              
            </div>
          ):<div className='tradePanel'>
          
          <div ref={pannelRef} className='pannel'>
              <div className='Tickers'>
                <div className='Text'>
                    <h2>Tickers</h2>
                </div>
                <div className='button-tickers1'>
                <div className='Bouton'>
                      <button onClick={() => onClickSend('Ready for DAX')}>DAX</button>
                    </div>
                    <div className='Bouton'>
                      <button onClick={() => onClickSend('Ready for CAC')}>CAC</button>
                    </div>
                    <div className='Bouton'>
                      <button onClick={() => onClickSend('Ready for Bund')}>Bund</button>
                    </div>            
                </div>
                  <div className='button-tickers2'>
                  <div className='Bouton'>
                      <button onClick={() => onClickSend('Ready for Nsdq')}>Nsdq</button>
                  </div>
      
                  <div className='Bouton'>
                      <button onClick={() => onClickSend('Ready for DJ')}>DJ</button>
                  </div>
                    
                  <div className='Bouton'>
                    <button onClick={() => onClickSend('Ready for FTSE')}>FTSE</button>
                  </div>
      
                </div>
                <div className='button-tickers3'>
                  <input  className='Form-Value' type='text' placeholder='Select a Ticker' disabled={true} id='Ticker' /> 
      
                  <div className='Bouton-Buy'>
                      <button disabled={true} onClick={() => onClickSend('Buy Market')}>BUY Market</button>
                  </div>  
                
                  <div className='Bouton-sell'>
                    <button disabled= {true} onClick={() => onClickSend('Sell Market')}>SELL MARKET</button>
                  </div>
                </div>
                
              </div>
              <div className='Future-value'>
                  <div className='Future-value-color'>  
                    <div className='Bouton-green'>
                      <button onClick={() => onClickSend('Green')}>Green</button>
                    </div>
      
                    <div className='Bouton-orange'>
                      <button onClick={() => onClickSend('Orange')}>Orange</button>
                    </div>
      
                    <div className='Bouton-red'>
                      <button onClick={() => onClickSend('Red')}>Red</button>
                    </div>
                  </div>
                  <div className='Placement-Boutton-Color'>
                    <div className='Bouton-2'>
                      <button onClick={() => onClickSend('Ready to BUY')}></button>
                    </div>
                    <div className='Bouton-4'>
                      <button onClick={() => onClickSend('Ready to SELL')}></button>
                    </div>
                  </div>
              </div>
      
              <div className='CurrentValue'>
                <div className='Text'>
                  <h2>Current value</h2>
                </div>
                <form onSubmit={(e) => {
                  e.preventDefault();
                  const textInput = document.getElementById('Pricepoint').value;
                  document.getElementById('Pricepoint').value = '';
      
                  if (textInput.length > 0) {
                    socket.emit('newMessageIndice', textInput);
                    //createElementFunction('newMessageMeIndice', {sender: pseudo, content: textInput});
                    createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
                  } else {
                    return false;
                  }
                  }}>
                  <input  className='Form-Value' type='text' placeholder='Current Value' id='Pricepoint' />
                </form>
              </div>
            <div className='Signals'>
              <div className='Long'>
                <h2>Long Signals</h2>
                <div className='Go-long'>
                    <div className='Bouton'>
                      <button onClick={()=>onClickSend('GO Long')}>GO LONG</button>
                    </div>
                </div>
                  <div className='profits'>
                    <h2>Profits</h2>
                      <div className='Bouton'>
                        <button onClick={() => onClickSend('NewPrice_LP_L')}>Little</button>
                      </div>
                      <div className='Bouton'>
                        <button onClick={() => onClickSend('NewPrice_BP_L')}>Big</button>
                      </div>
                  </div>
                  <div className='Exit-Long'>
                    <div className='Bouton'>
                      <button onClick={() => onClickSend('Exit Long')}>EXIT LONG</button>
                    </div>
                    <div className='Bouton'>
                      <button onClick={() => onClickSend('Delete Long')}>Cancel LONG</button>
                    </div>
                  </div>
              </div>
      
              <div className='Short'>
              <h2>Shorts Signals</h2>
              <div className='Go-short'>
                  <div className='Bouton'>
                    <button onClick={() => onClickSend('GO Short')}>GO SHORT</button>
                  </div> 
              </div>    
              <div className='profits'>
                    <h2>Profits</h2>
                      <div className='Bouton'>
                        <button onClick={() => onClickSend('NewPrice_LP_S')}>Little</button>
                      </div>
                      <div className='Bouton'>
                        <button onClick={() => onClickSend('NewPrice_BP_S')}>Big</button>
                      </div>
                  </div>
                  <div className='Exit-short'>
                      <div className='Bouton'>
                        <button onClick={() => onClickSend('Exit Short')}>EXIT SHORT</button>
                      </div>
                      <div className='Bouton'>
                        <button onClick={() => onClickSend('Delete Short')}>Cancel SHORT</button>
                      </div>
                  </div> 
      
      
              </div>
            </div> 
      
      
            <div className='Future-value'>
              <h2>Future Value</h2>
                <form onSubmit={(e) => {
                      e.preventDefault();
                      const textInput = document.getElementById('FutrePricepoint').value;
                      document.getElementById('FutrePricepoint').value = '';
      
                      if (textInput.length > 0) {
                        socket.emit('newMessageIndice', textInput);
                        //createElementFunction('newMessageMeIndice', {sender: pseudo, content: textInput});
                        createElementFunction('newMessageIndice', {sender: pseudo, content: textInput});
                      } else {
                        return false;
                      }
                    }}>
                      <input type='text' placeholder='Future Value' id='FutrePricepoint' />
                    </form>
      
                
              </div>
      
            <div className='Others'>
              <h2>Others signals</h2>
              <div className='bouton5'>
                <button onClick={() => onClickSend('Remove Last message')}>Remove Last message</button>
              </div> 
              <div className='bouton1'>
                <button onClick={() => onClickSend('Stop @ entry price')}>STOP @ ENTRY PRICE</button>
              </div> 
              <div className='bouton2'>
                <button onClick={()=>onClickSend('Cancel')}>Cancel READY Signal</button>
              </div>
              <div className='bouton3'>
                <button onClick={() => onClickSend('Reinforce')}>Reinforce Position</button>
              </div>
              <div className='bouton4'>
                <button onClick={() => onClickSend('Reinforced')}>Position has been Reinforced</button>
              </div>
              <div className='bouton5'>
                <button onClick={() => onClickSend('GAMBLE')}>THIS IS GAMBLE</button>
              </div>
              <div className='bouton6'>
                <button onClick={() => onClickSend('Jingle')}>Jingle</button>
              </div>
              <div className='bouton7'>
                <button onClick={() => onClickSend('Cirque')}>Cirque</button>
              </div>
            </div>  
      
          <div className='Instant-message'>
            <h2>Messagerie Instantanée</h2>
            <div>
              <select onChange={(e) => {
                const textInput = `${e.target.value}`;
                if (textInput.length > 0) {
                  document.getElementById('directTextInput').value = textInput;
                  e.target.value = ''; 
                }
              }}
              className="botSpaceSelect">
                <option value=""></option>
                <option value="Hello everyone!">Hello everyone !</option>
                <option value="Good evening!">Good evening!</option>
                <option value="See you tomorrow">See you tomorrow</option>
                <option value="Out for a while, please manage your positions.">Out for a while</option>
                <option value="Out for Lunch, please manage your positions.">Out for Lunch !</option>
                <option value="The room is closed!">Closed</option>
                <option value="The room is Opened!">Open</option>
              </select>
            </div>
            <div>
              <form>
                <input type='text' multiline rows="3" placeholder='Saisir le message à envoyer' id='directTextInput' onChange={() => {}}/>
              </form>
                <button type='submit' onClick={() => onClickSend('CustomAnnonce')}>Envoyer</button>
                <button type='submit' onClick={() => onClickSend('PublicAnnonce')}>Public Annonce</button>
            </div>
          </div>
        </div>
      
            <div ref={chatRef} className='chat-container'>
             
              <div className="entete-salle">
                <div className="open-position2">
                  <div>
                    <img className='openposition-barre' src={openposition} alt="Open positions"></img>
                  </div>
                  <div className='barre-trade' id='Trades'>
      
                  </div>
                </div>
              </div>
              <div className="titresalle">
                <h1 className='titre'>{language==='Fr'?"Salle de marchés Indices":"Indice trading room"}</h1>
              </div>
              <div className='msgContainerAdmin' id='msgContainerAdmin'>           
              </div> 
              <div className="publicAnnonce">
                <div className="img-public">
                  <img className='img-size' src={publicAnnonce} alt="img-hautparleur"></img>
                </div>
                <div className="annonceplublicadmin" id='annoncepublic'>
                </div>       
              </div>
            </div>
            <div className='chat-container'>
              <h2 className='titre'>Users</h2>
              <div className='msgContainerAdmin' id='UserListContainerAdmin'> </div>
            </div>
          </div>
       }
    

        </div>
        </>
    );
}

export default Indice;
