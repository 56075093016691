import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import js et scss
import "./../styles/Abonnement.scss";
import "./../styles/ChoixSalles.scss";

// images
import PrixP from "./../images/PrixP.png";
import PrixG from "./../images/PrixG.png";
import okLogo from "./../images/imgbin_check-mark-computer-icons-ok-png.png"; 

// Import Ogone
import { CreatePayment } from "./../components/Ogone/CBSubscribe.js";

import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { LanguageContext, UidContext } from "../components/AppContext";
import { useSelector } from "react-redux";
import { home } from "../Utils/Models";
import Loader from "../components/Loader";

export default function Abonnement() {
  const uid = useContext(UidContext);
  const { language } = useContext(LanguageContext);
  const [showSubscriptionOverlay, setShowSubscriptionOverlay] = useState(false);
  const [showSubscriptionOverlayIndice, setShowSubscriptionOverlayIndice] =
    useState(false);
  const [date, setDate] = useState(new Date());
  const userData = useSelector((state) => state.userReducer);
  const [dataEn, setDataEn] = useState([]);
  const [dataFr, setDataFr] = useState([]);
  const [loading, isLoading] = useState(false);
  const [isLangSwitch, setIsLangSwitch] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  var NewPayement = new CreatePayment();
  useEffect(() => {
    getSubcriptionData();
  }, []);

  useEffect(() => {
    if (!isInitialRender) {
      setIsLangSwitch(true);
      setTimeout(() => {
        setIsLangSwitch(false);
      }, 1000);
    } else {
      setIsInitialRender(false);
    }
  }, [language]);

  // ---------------------To get all data of subcription page  -----------
  const getSubcriptionData = () => {
    isLoading(true);
    home
      .getSubcriptionData()
      .then((response) => {
        setDataEn(response?.language?.en);
        setDataFr(response?.language?.fr);
        isLoading(false);
      })
      .catch((error) => {
        console.log("Error while getting ", error);
      });
  };
  function createMarkup(data) {
    return { __html: data };
  }

  function fareManage(data) {
    let numValue = parseFloat(data?.match(/[\d.]+/));

    let decNumber = numValue?.toString()?.split(".");

    return decNumber;
  }
  return (
    <>
      {" "}
      {isLangSwitch && (
        <div className="loader-background">
          <span className="loader-text">Switching...</span>
        </div>
      )}
      {Object.keys(dataEn).length && Object.keys(dataFr).length && !loading ? (
        <div className="main-container">
          <div className="box">
            <div className="head-row">
             {(date >= new Date(userData?.aboIndices))||(date >= new Date(userData?.aboForex))||(userData?.aboIndices===undefined)&&
               <h1
                dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.subscription_page_header_title)
                    : createMarkup(dataEn?.subscription_page_header_title)
                }
              />}
            </div>
                <p dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.subscription_page_first_description)
                    : createMarkup(dataEn?.subscription_page_first_description)
                } style={{textAlign:'justify',marginTop:'5px'}}/>
            <div className="All-box">
              {!uid ? ( //Cards for not logged in users
                <>


{/* Hide Forex Romm


                  <div className="One-box">
                    <div className="Title-box">
                      <h2
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[0]?.title
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[0]?.title
                              )
                        }
                      />
                    </div>
                    <div className="Text-box">
                      <p
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[0]
                                  ?.second_title
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[0]
                                  ?.second_title
                              )
                        }
                      />
                      <p className="amount-para">
                        €{" "}
                        {language == "Fr"
                          ? fareManage(
                              dataFr?.subscription_page_card_data[0]?.price
                            )[0]
                          : fareManage(
                              dataEn?.subscription_page_card_data[0]?.price
                            )[0]}
                        <sup>
                          .
                          {language == "Fr"
                            ? fareManage(
                                dataFr?.subscription_page_card_data[0]?.price
                              )[1]
                            : fareManage(
                                dataEn?.subscription_page_card_data[0]?.price
                              )[1]}
                        </sup>
                      </p>
                      <p
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[0]?.time
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[0]?.time
                              )
                        }
                      />
                      <div className="Foot-box">
                        <p
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[0]?.offer
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[0]?.offer
                                )
                          }
                        />
                      </div>
                    </div>
                    <p
                      className="desc-foot"
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.subscription_page_descripton)
                          : createMarkup(dataEn?.subscription_page_descripton)
                      }
                    ></p>
                    <div>
                      {uid && (
                        <button
                          className="Button-box"
                          onClick={() => setShowSubscriptionOverlay(true)}
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.subscription_page_button)
                              : createMarkup(dataEn?.subscription_page_button)
                          }
                        ></button>
                      )}

                      {showSubscriptionOverlay && (
                        <div className="overlay">
                          <div className="subscription-form">
                            <form>
                              <label htmlFor="subscription-select">
                                {language == "Fr"
                                  ? "Abonnement:"
                                  : "Subscription:"}
                              </label>
                              <select
                                name="subscription"
                                id="subscription-select"
                              >
                                  <option value="M">{language == "Fr"
                                    ? "Mensuel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 2.780)*10)
                                    : "Monthly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 2.780)*10)
                                    }
                                  </option>
                                  <option value="BM">{language == "Fr"
                                    ? "BiMestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 5.125)*10)
                                    : "Bimonthly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 5.125)*10)
                                    }
                                  </option>
                                  <option value="T"> {language == "Fr"
                                    ? "Trimestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 7.275)*10)
                                    : "Quarterly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 7.275)*10)
                                    }
                                  </option>
                                  <option value="S"> {language == "Fr"
                                    ? "Semestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 13.75)*10)
                                    : "Semi-annual €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 13.75)*10)
                                    }
                                  </option>
                                  <option value="A">{language == "Fr"
                                    ? "Annuel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 25)*10)
                                    : "Annual €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 25)*10)
                                    }
                                  </option>
                              </select>
                            </form>
                            <div className="button-overlay">
                              <button
                                type="submit"
                                onClick={() =>
                                  NewPayement.CBSubscrib(
                                    userData._id,
                                    userData.email,
                                    "Forex",
                                    document.getElementById(
                                      "subscription-select"
                                    ).value,
                                    language,
                                    fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]
                                  )
                                }
                              >
                                Souscrire
                              </button>
                              <button
                                className="close-button"
                                onClick={() =>
                                  setShowSubscriptionOverlay(false)
                                }
                              >
                                Fermer
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

{/* Hide Forex Room */}

                  <div className="One-box">
                    <div className="Title-box">
                      <h2
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[1]?.title
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[1]?.title
                              )
                        }
                      />
                    </div>
                    <div className="Text-box">
                      <p
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[1]
                                  ?.second_title
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[1]
                                  ?.second_title
                              )
                        }
                      />
                      <p className="amount-para">
                        €{" "}
                        {language == "Fr"
                          ? fareManage(
                              dataFr?.subscription_page_card_data[1]?.price
                            )[0]
                          : fareManage(
                              dataEn?.subscription_page_card_data[1]?.price
                            )[0]}
                        <sup>
                          .
                          {language == "Fr"
                            ? fareManage(
                                dataFr?.subscription_page_card_data[1]?.price
                              )[1]
                            : fareManage(
                                dataEn?.subscription_page_card_data[1]?.price
                              )[1]}
                        </sup>
                      </p>
                      <p
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[1]?.time
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[1]?.time
                              )
                        }
                      />

                      <div className="Foot-box">
                        <p
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[1]?.offer
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[1]?.offer
                                )
                          }
                        />
                      </div>
                    </div> 
                    <p
                      className="desc-foot"
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.subscription_page_descripton)
                          : createMarkup(dataEn?.subscription_page_descripton)
                      }
                    />

                    <div>
                      {uid && (
                        <button
                          className="Button-box"
                          onClick={() => setShowSubscriptionOverlayIndice(true)}
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.subscription_page_button)
                              : createMarkup(dataEn?.subscription_page_button)
                          }
                        ></button>
                      )}

                      {showSubscriptionOverlayIndice && (
                        <div className="overlay">
                          <div className="subscription-form">
                            <form>
                              <label htmlFor="subscription-select">
                                Abonnement:
                              </label>
                              <select
                                name="subscription"
                                id="subscription-select"
                              >
                                  <option value="M">{language == "Fr"
                                    ? "Mensuel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 2.780)*10)
                                    : "Monthly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 2.780)*10)
                                    }
                                  </option>
                                  <option value="BM">{language == "Fr"
                                    ? "BiMestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 5.125)*10)
                                    : "Bimonthly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 5.125)*10)
                                    }
                                  </option>
                                  <option value="T"> {language == "Fr"
                                    ? "Trimestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 7.275)*10)
                                    : "Quarterly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 7.275)*10)
                                    }
                                  </option>
                                  <option value="S"> {language == "Fr"
                                    ? "Semestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 13.75)*10)
                                    : "Semi-annual €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 13.75)*10)
                                    }
                                  </option>
                                  <option value="A">{language == "Fr"
                                    ? "Annuel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 25)*10)
                                    : "Annual €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 25)*10)
                                    }
                                  </option>
                              </select>
                            </form>
                            <div className="button-overlay">
                              <button
                                type="submit"
                                onClick={() =>
                                  NewPayement.CBSubscrib(
                                    userData._id,
                                    userData.email,
                                    "Indice",
                                    document.getElementById(
                                      "subscription-select"
                                    ).value,
                                    language,
                                    fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]
                                  )
                                }
                              >
                                Souscrire
                              </button>
                              <button
                                className="close-button"
                                onClick={() =>
                                  setShowSubscriptionOverlayIndice(false)
                                }
                              >
                                Fermer
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : userData?.role === "admin" ? ( // Cards for admin
                <>


{/* Hide Forex Room


                  <div className="One-box">
                    <div className="Title-box">
                      <h2
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[0]?.title
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[0]?.title
                              )
                        }
                      />
                    </div>
                    <div className="Text-box" style={{ paddingBottom: "0em" }}>
                      <p> </p>
                      <img src={okLogo} className="logo-size" alt="PrixG" />

                      <div
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_inner_description
                              )
                            : createMarkup(
                                dataEn?.subscription_inner_description
                              )
                        }
                      />
                      <div
                        className="Foot-box"
                        style={{ paddingBottom: "0.1em" }}
                      >
                        <p>
                          <button className="Button-box">
                            <NavLink
                              to="/Salle-Forex"
                              dangerouslySetInnerHTML={
                                language == "Fr"
                                  ? createMarkup(
                                      dataFr?.subscription_page_button2
                                    )
                                  : createMarkup(
                                      dataEn?.subscription_page_button2
                                    )
                              }
                            ></NavLink>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>{" "}
                  
                  

{/* Hide Forex Room */}




                  <div className="One-box">
                    <div className="Title-box">
                      <h2
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[1]?.title
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[1]?.title
                              )
                        }
                      />
                    </div>
                    <div className="Text-box" style={{ paddingBottom: "0em" }}>
                      <p> </p>
                      <img src={okLogo} className="logo-size" alt="PrixG" />
                      {/* <h2 className='p-font' style={{color:'rgb(38 193 89)'}}>Souscrit</h2> */}
                      <div
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_inner_description
                              )
                            : createMarkup(
                                dataEn?.subscription_inner_description
                              )
                        }
                      />
                      <div
                        className="Foot-box"
                        style={{ paddingBottom: "0.1em" }}
                      >
                        <p>
                          <button className="Button-box">
                            <NavLink
                              to="/Salle-Indice"
                              dangerouslySetInnerHTML={
                                language == "Fr"
                                  ? createMarkup(
                                      dataFr?.subscription_page_button2
                                    )
                                  : createMarkup(
                                      dataEn?.subscription_page_button2
                                    )
                              }
                            ></NavLink>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : date <= new Date(userData?.aboForex) ||
                date <= new Date(userData?.aboIndices) ? ( // check if the user is logged in has any subscrption of forex or indices
                
                date <= new Date(userData?.aboForex) &&
                date <= new Date(userData?.aboIndices) ? ( // Card to if user has both subscription
                  <>


{/* Hide Forex Room


                    <div className="One-box">
                      <div className="Title-box">
                        <h2
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[0]?.title
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[0]?.title
                                )
                          }
                        />
                      </div>
                      <div
                        className="Text-box"
                        style={{ paddingBottom: "0em" }}
                      >
                        <p> </p>
                        <img src={okLogo} className="logo-size" alt="PrixG" />
                        <div
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_inner_description
                                )
                              : createMarkup(
                                  dataEn?.subscription_inner_description
                                )
                          }
                        />
                        <div
                          className="Foot-box"
                          style={{ paddingBottom: "0.1em" }}
                        >
                          <p>
                            <button className="Button-box">
                              <NavLink
                                to="/Salle-Forex"
                                dangerouslySetInnerHTML={
                                  language == "Fr"
                                    ? createMarkup(
                                        dataFr?.subscription_page_button2
                                      )
                                    : createMarkup(
                                        dataEn?.subscription_page_button2
                                      )
                                }
                              ></NavLink>
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>


{/* Hide Forex Room */}



                    <div className="One-box">
                      <div className="Title-box">
                        <h2
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[1]?.title
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[1]?.title
                                )
                          }
                        />
                      </div>
                      <div
                        className="Text-box"
                        style={{ paddingBottom: "0em" }}
                      >
                        <p> </p>
                        <img src={okLogo} className="logo-size" alt="PrixG" />
                        {/* <h2 className='p-font' style={{color:'rgb(38 193 89)'}}>Souscrit</h2> */}
                        <div
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_inner_description
                                )
                              : createMarkup(
                                  dataEn?.subscription_inner_description
                                )
                          }
                        />
                        <div
                          className="Foot-box"
                          style={{ paddingBottom: "0.1em" }}
                        >
                          <p>
                            <button className="Button-box">
                              <NavLink
                                to="/Salle-Indice"
                                dangerouslySetInnerHTML={
                                  language == "Fr"
                                    ? createMarkup(
                                        dataFr?.subscription_page_button2
                                      )
                                    : createMarkup(
                                        dataEn?.subscription_page_button2
                                      )
                                }
                              ></NavLink>
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : date <= new Date(userData?.aboForex) ? ( // Card to if user has forex subscription
                  <>



{/* Hide Forex Room 



                    <div className="One-box">
                      <div className="Title-box">
                        <h2
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[0]?.title
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[0]?.title
                                )
                          }
                        />
                      </div>
                      <div
                        className="Text-box"
                        style={{ paddingBottom: "0em" }}
                      >
                        <p> </p>
                        <img src={okLogo} className="logo-size" alt="PrixG" />
                        <div
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_inner_description
                                )
                              : createMarkup(
                                  dataEn?.subscription_inner_description
                                )
                          }
                        />
                        <div
                          className="Foot-box"
                          style={{ paddingBottom: "0.1em" }}
                        >
                          <p>
                            <button className="Button-box">
                              <NavLink
                                to="/Salle-Forex"
                                dangerouslySetInnerHTML={
                                  language == "Fr"
                                    ? createMarkup(
                                        dataFr?.subscription_page_button2
                                      )
                                    : createMarkup(
                                        dataEn?.subscription_page_button2
                                      )
                                }
                              ></NavLink>
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>{" "}



{/* Hide Forex Room */}




                    <div className="One-box">
                      <div className="Title-box">
                        <h2
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[1]?.title
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[1]?.title
                                )
                          }
                        />
                      </div>
                      <div className="Text-box">
                        <p
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[1]
                                    ?.second_title
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[1]
                                    ?.second_title
                                )
                          }
                        />
                        {/* <img src={PrixP} alt="PrixG" /> */}
                        <p className="amount-para">
                          €{" "}
                          {language == "Fr"
                            ? fareManage(
                                dataFr?.subscription_page_card_data[1]?.price
                              )[0]
                            : fareManage(
                                dataEn?.subscription_page_card_data[1]?.price
                              )[0]}
                          <sup>
                            .
                            {language == "Fr"
                              ? fareManage(
                                  dataFr?.subscription_page_card_data[1]?.price
                                )[1]
                              : fareManage(
                                  dataEn?.subscription_page_card_data[1]?.price
                                )[1]}
                          </sup>
                        </p>
                        <p
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[1]?.time
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[1]?.time
                                )
                          }
                        />
                        <div className="Foot-box">
                          <p
                            dangerouslySetInnerHTML={
                              language == "Fr"
                                ? createMarkup(
                                    dataFr?.subscription_page_card_data[1]
                                      ?.offer
                                  )
                                : createMarkup(
                                    dataEn?.subscription_page_card_data[1]
                                      ?.offer
                                  )
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="desc-foot"
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_descripton2
                              )
                            : createMarkup(
                                dataEn?.subscription_page_descripton2
                              )
                        }
                      />

                      <div>
                        <button
                          className="Button-box"
                          onClick={() => setShowSubscriptionOverlayIndice(true)}
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.subscription_page_button)
                              : createMarkup(dataEn?.subscription_page_button)
                          }
                        ></button>

                        {showSubscriptionOverlayIndice && (
                          <div className="overlay">
                            <div className="subscription-form">
                              <form>
                                <label htmlFor="subscription-select">
                                {language == "Fr"
                                  ? "Abonnement:"
                                  : "Subscription:"}
                                </label>
                                <select
                                  name="subscription"
                                  id="subscription-select"
                                >
                                  <option value="M">{language == "Fr"
                                    ? "Mensuel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 2.780)*10)
                                    : "Monthly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 2.780)*10)
                                    }
                                  </option>
                                  <option value="BM">{language == "Fr"
                                    ? "BiMestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 5.125)*10)
                                    : "Bimonthly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 5.125)*10)
                                    }
                                  </option>
                                  <option value="T"> {language == "Fr"
                                    ? "Trimestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 7.275)*10)
                                    : "Quarterly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 7.275)*10)
                                    }
                                  </option>
                                  <option value="S"> {language == "Fr"
                                    ? "Semestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 13.75)*10)
                                    : "Semi-annual €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 13.75)*10)
                                    }
                                  </option>
                                  <option value="A">{language == "Fr"
                                    ? "Annuel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 25)*10)
                                    : "Annual €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 25)*10)
                                    }
                                  </option>
                                </select>
                              </form>
                              <div className="button-overlay">
                                <button
                                  type="submit"
                                  onClick={() =>
                                    NewPayement.CBSubscrib(
                                      userData._id,
                                      userData.email,
                                      "Indice",
                                      document.getElementById(
                                        "subscription-select"
                                      ).value,
                                      language,
                                      fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]
                                    )
                                  }
                                >
                                  {language == "Fr" ? "Souscrire:" : "Subscribe:"}
                                </button>
                                <button
                                  className="close-button"
                                  onClick={() =>
                                    setShowSubscriptionOverlayIndice(false)
                                  }
                                >
                                  { language == "Fr"
                              ? 'Fermer'
                              : 'Close'
                          }
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  // Cards to ifuser has indices subscription
                  <>
                    <div className="One-box">
                      <div className="Title-box">
                        <h2
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[1]?.title
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[1]?.title
                                )
                          }
                        />
                      </div>
                      <div
                        className="Text-box"
                        style={{ paddingBottom: "0em" }}
                      >
                        <p> </p>
                        <img src={okLogo} className="logo-size" alt="PrixG" />
                        {/* <h2 className='p-font' style={{color:'rgb(38 193 89)'}}>Souscrit</h2> */}
                        <div
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_inner_description
                                )
                              : createMarkup(
                                  dataEn?.subscription_inner_description
                                )
                          }
                        />
                        <div
                          className="Foot-box"
                          style={{ paddingBottom: "0.1em" }}
                        >
                          <p>
                            <button className="Button-box">
                              <NavLink
                                to="/Salle-Indice"
                                dangerouslySetInnerHTML={
                                  language == "Fr"
                                    ? createMarkup(
                                        dataFr?.subscription_page_button2
                                      )
                                    : createMarkup(
                                        dataEn?.subscription_page_button2
                                      )
                                }
                              ></NavLink>
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>




                  {/* Hide Forex Room 


                    <div className="One-box">
                      <div className="Title-box">
                        <h2
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[0]?.title
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[0]?.title
                                )
                          }
                        />
                      </div>
                      <div className="Text-box">
                        <p
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[0]
                                    ?.second_title
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[0]
                                    ?.second_title
                                )
                          }
                        />
                        <p className="amount-para">
                          €{" "}
                          {language == "Fr"
                            ? fareManage(
                                dataFr?.subscription_page_card_data[0]?.price
                              )[0]
                            : fareManage(
                                dataEn?.subscription_page_card_data[0]?.price
                              )[0]}
                          <sup>
                            .
                            {language == "Fr"
                              ? fareManage(
                                  dataFr?.subscription_page_card_data[0]?.price
                                )[1]
                              : fareManage(
                                  dataEn?.subscription_page_card_data[0]?.price
                                )[1]}
                          </sup>
                        </p>
                        <p
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[0]?.time
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[0]?.time
                                )
                          }
                        />
                        <div className="Foot-box">
                          <p
                            dangerouslySetInnerHTML={
                              language == "Fr"
                                ? createMarkup(
                                    dataFr?.subscription_page_card_data[0]
                                      ?.offer
                                  )
                                : createMarkup(
                                    dataEn?.subscription_page_card_data[0]
                                      ?.offer
                                  )
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="desc-foot"
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_descripton2
                              )
                            : createMarkup(
                                dataEn?.subscription_page_descripton2
                              )
                        }
                      />
                      <div>
                        <button
                          className="Button-box"
                          onClick={() => setShowSubscriptionOverlay(true)}
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(dataFr?.subscription_page_button)
                              : createMarkup(dataEn?.subscription_page_button)
                          }
                        ></button>

                        {showSubscriptionOverlay && (
                          <div className="overlay">
                            <div className="subscription-form">
                              <form>
                                <label htmlFor="subscription-select">
                                {language == "Fr"
                                  ? "Abonnement:"
                                  : "Subscription:"}
                                </label>
                                <select
                                  name="subscription"
                                  id="subscription-select"
                                >
                                  <option value="M">{language == "Fr"
                                    ? "Mensuel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 2.780)*10)
                                    : "Monthly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 2.780)*10)
                                    }
                                  </option>
                                  <option value="BM">{language == "Fr"
                                    ? "BiMestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 5.125)*10)
                                    : "Bimonthly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 5.125)*10)
                                    }
                                  </option>
                                  <option value="T"> {language == "Fr"
                                    ? "Trimestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 7.275)*10)
                                    : "Quarterly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 7.275)*10)
                                    }
                                  </option>
                                  <option value="S"> {language == "Fr"
                                    ? "Semestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 13.75)*10)
                                    : "Semi-annual €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 13.75)*10)
                                    }
                                  </option>
                                  <option value="A">{language == "Fr"
                                    ? "Annuel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 25)*10)
                                    : "Annual €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 25)*10)
                                    }
                                  </option>
                                </select>
                              </form>
                              <div className="button-overlay">
                                <button
                                  type="submit"
                                  onClick={() =>
                                    NewPayement.CBSubscrib(
                                      userData._id,
                                      userData.email,
                                      "Forex",
                                      document.getElementById(
                                        "subscription-select"
                                      ).value,
                                      language,
                                      fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]
                                    )
                                  }
                                >
                                  {language == "Fr" ? "Souscrire:" : "Subscribe:"}
                                </button>
                                <button
                                  className="close-button"
                                  onClick={() =>
                                    setShowSubscriptionOverlay(false)
                                  }
                                >
                                  { language == "Fr"
                              ? 'Fermer'
                              : 'Close'
                          }
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                  {/* Hide Forex Room */}


                  </>

                )
              ) : (
                // Cards if the user is logged in and no subscription taken
                <>
{/* Hide Forex Room
                  {" "}
                  <div className="One-box">
                    <div className="Title-box">
                      <h2
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[0]?.title
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[0]?.title
                              )
                        }
                      />
                    </div>
                    <div className="Text-box">
                      <p
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[0]
                                  ?.second_title
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[0]
                                  ?.second_title
                              )
                        }
                      />
                      <p className="amount-para">
                        €{" "}
                        {language == "Fr"
                          ? fareManage(
                              dataFr?.subscription_page_card_data[0]?.price
                            )[0]
                          : fareManage(
                              dataEn?.subscription_page_card_data[0]?.price
                            )[0]}
                        <sup>
                          .
                          {language == "Fr"
                            ? fareManage(
                                dataFr?.subscription_page_card_data[0]?.price
                              )[1]
                            : fareManage(
                                dataEn?.subscription_page_card_data[0]?.price
                              )[1]}
                        </sup>
                      </p>
                      <p
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[0]?.time
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[0]?.time
                              )
                        }
                      />
                      <div className="Foot-box">
                        <p
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[0]?.offer
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[0]?.offer
                                )
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="desc-foot"
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.subscription_page_descripton2)
                          : createMarkup(dataEn?.subscription_page_descripton2)
                      }
                    />
                    <div>
                      <button
                        className="Button-box"
                        onClick={() => setShowSubscriptionOverlay(true)}
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(dataFr?.subscription_page_button)
                            : createMarkup(dataEn?.subscription_page_button)
                        }
                      ></button>

                      {showSubscriptionOverlay && (
                        <div className="overlay">
                          <div className="subscription-form">
                            <form>
                              <label htmlFor="subscription-select">
                                {language == "Fr"
                                  ? "Abonnement:"
                                  : "Subscription:"}
                              </label>
                              <select
                                name="subscription"
                                id="subscription-select"
                              >
                                  <option value="M">{language == "Fr"
                                    ? "Mensuel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 2.780)*10)
                                    : "Monthly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 2.780)*10)
                                    }
                                  </option>
                                  <option value="BM">{language == "Fr"
                                    ? "BiMestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 5.125)*10)
                                    : "Bimonthly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 5.125)*10)
                                    }
                                  </option>
                                  <option value="T"> {language == "Fr"
                                    ? "Trimestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 7.275)*10)
                                    : "Quarterly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 7.275)*10)
                                    }
                                  </option>
                                  <option value="S"> {language == "Fr"
                                    ? "Semestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 13.75)*10)
                                    : "Semi-annual €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 13.75)*10)
                                    }
                                  </option>
                                  <option value="A">{language == "Fr"
                                    ? "Annuel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]* 25)*10)
                                    : "Annual €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[0]?.price)[0]* 25)*10)
                                    }
                                  </option>
                              </select>
                            </form>
                            <div className="button-overlay">
                              <button
                                type="submit"
                                onClick={() =>
                                  NewPayement.CBSubscrib(
                                    userData._id,
                                    userData.email,
                                    "Forex",
                                    document.getElementById(
                                      "subscription-select"
                                    ).value,
                                    language,
                                    fareManage(dataFr?.subscription_page_card_data[0]?.price)[0]
                                  )
                                }
                              >
                                {language == "Fr" ? "Souscrire:" : "Subscribe:"}
                              </button>
                              <button
                                className="close-button"
                                onClick={() =>
                                  setShowSubscriptionOverlay(false)
                                }
                              >
                                 { language == "Fr"
                              ? 'Fermer'
                              : 'Close'
                          }
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>


{/* Hide Forex Room */}


                  <div className="One-box">
                    <div className="Title-box">
                      <h2
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[1]?.title
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[1]?.title
                              )
                        }
                      />
                    </div>
                    <div className="Text-box">
                      <p
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[1]
                                  ?.second_title
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[1]
                                  ?.second_title
                              )
                        }
                      />

                      <p className="amount-para">
                        €{" "}
                        {language == "Fr"
                          ? fareManage(
                              dataFr?.subscription_page_card_data[1]?.price
                            )[0]
                          : fareManage(
                              dataEn?.subscription_page_card_data[1]?.price
                            )[0]}
                        <sup>
                          .
                          {language == "Fr"
                            ? fareManage(
                                dataFr?.subscription_page_card_data[1]?.price
                              )[1]
                            : fareManage(
                                dataEn?.subscription_page_card_data[1]?.price
                              )[1]}
                        </sup>
                      </p>
                      <p
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(
                                dataFr?.subscription_page_card_data[1]?.time
                              )
                            : createMarkup(
                                dataEn?.subscription_page_card_data[1]?.time
                              )
                        }
                      />

                      <div className="Foot-box">
                        <p
                          dangerouslySetInnerHTML={
                            language == "Fr"
                              ? createMarkup(
                                  dataFr?.subscription_page_card_data[1]?.offer
                                )
                              : createMarkup(
                                  dataEn?.subscription_page_card_data[1]?.offer
                                )
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="desc-foot"
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.subscription_page_descripton2)
                          : createMarkup(dataEn?.subscription_page_descripton2)
                      }
                    />

                    <div>
                      <button
                        className="Button-box"
                        onClick={() => setShowSubscriptionOverlayIndice(true)}
                        dangerouslySetInnerHTML={
                          language == "Fr"
                            ? createMarkup(dataFr?.subscription_page_button)
                            : createMarkup(dataEn?.subscription_page_button)
                        }
                      ></button>

                      {showSubscriptionOverlayIndice && (
                        <div className="overlay">
                          <div className="subscription-form">
                            <form>
                              <label htmlFor="subscription-select">
                              {language == "Fr"
                                  ? "Abonnement:"
                                  : "Subscription:"}
                              </label>
                              <select
                                name="subscription"
                                id="subscription-select"
                              >
                                  <option value="M">{language == "Fr"
                                    ? "Mensuel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 2.780)*10)
                                    : "Monthly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 2.780)*10)
                                    }
                                  </option>
                                  <option value="BM">{language == "Fr"
                                    ? "BiMestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 5.125)*10)
                                    : "Bimonthly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 5.125)*10)
                                    }
                                  </option>
                                  <option value="T"> {language == "Fr"
                                    ? "Trimestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 7.275)*10)
                                    : "Quarterly €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 7.275)*10)
                                    }
                                  </option>
                                  <option value="S"> {language == "Fr"
                                    ? "Semestriel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 13.75)*10)
                                    : "Semi-annual €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 13.75)*10)
                                    }
                                  </option>
                                  <option value="A">{language == "Fr"
                                    ? "Annuel €" + (Math.round(fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]* 25)*10)
                                    : "Annual €" + (Math.round(fareManage(dataEn?.subscription_page_card_data[1]?.price)[0]* 25)*10)
                                    }
                                  </option>
                              </select>
                            </form>
                            <div className="button-overlay">
                              <button
                                type="submit"
                                onClick={() =>
                                  NewPayement.CBSubscrib(
                                    userData._id,
                                    userData.email,
                                    "Indice",
                                    document.getElementById(
                                      "subscription-select"
                                    ).value,
                                    language,
                                    fareManage(dataFr?.subscription_page_card_data[1]?.price)[0]
                                  )
                                }
                              >
                                {language == "Fr" ? "Souscrire:" : "Subscribe:"}
                              </button>
                              <button
                                className="close-button"
                                onClick={() =>
                                  setShowSubscriptionOverlayIndice(false)
                                }
                              >
                                 { language == "Fr"
                              ? 'Fermer'
                              : 'Close'
                          }
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <p
                      className="desc-foot-2"
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.subscription_page_second_description)
                          : createMarkup(dataEn?.subscription_page_second_description)
                      }
                    />
          </div>
        </div>
      ) : (
        <Loader loading={loading} />
      )}
    </>
  );
}
