import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFaq } from "../actions/faq.action";

// import js  et scss
import "../styles/Faq.scss";
import { home } from "../Utils/Models/index";
import { LanguageContext, UidContext } from "../components/AppContext";
import Loader from "../components/Loader";


const FaqList = () => {
  const { language } = useContext(LanguageContext);
  const [dataEn, setDataEn] = useState([]);
  const [dataFr, setDataFr] = useState([]);
  const [loading,isLoading]=useState(false)
  const [isLangSwitch, setIsLangSwitch] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    getFaqData();
  }, []);
  useEffect(()=>{
    if(!isInitialRender){
      setIsLangSwitch(true);
      setTimeout(() => {
        setIsLangSwitch(false);
      }, 1000);
    }else{
      setIsInitialRender(false);
    }
  },[language])
    // ---------------------To get all data of Conatct page -----------
  const getFaqData = () => {
    isLoading(true)
    home
      .getFaqsData()
      .then((response) => {
       
        setDataEn(response?.language?.en);
        setDataFr(response?.language?.fr);
        isLoading(false)
      })
      .catch((error) => {
        console.log("Error while getting ", error);
      });
  };

  function createMarkup(data) {
    return { __html: data };
  }

  return (
    <>
    {isLangSwitch && <div className="loader-background"><span className="loader-text">Switching...</span></div>}
   { !loading?<div className="main-container">
      <div className="faq-container">
        <h1 className="faq-title" dangerouslySetInnerHTML={language == "Fr"?createMarkup(dataFr?.faq_page_header_title):createMarkup(dataEn?.faq_page_header_title)}>
         
        </h1>

        <div className="faq-placement">
          {language == "Fr"
            ? dataFr?.faq_page_data?.map((item) => {
                return (
                  <div className="card" >
                    <h2 className="faq-sous-titre" dangerouslySetInnerHTML={createMarkup(item?.question)}></h2>
                    <p
                      className="faq-text"
                      dangerouslySetInnerHTML={createMarkup(item?.answer)}
                    />
                  </div>
                );
              })
            : dataEn?.faq_page_data?.map((item) => {
                return (
                  <div className="card">
                    <h2 className="faq-sous-titre" dangerouslySetInnerHTML={createMarkup(item?.question)}></h2>
                    <p
                      className="faq-text"
                      dangerouslySetInnerHTML={createMarkup(item?.answer)}
                    />
                  </div>
                );
              })}
        </div>
      </div>
    </div>:<Loader loading={loading}/>}
    </>
  );
};

export default FaqList;
