import React, { useContext, useEffect, useState } from "react";
//js et scss
import "./../styles/Footer.scss";
//image
import LogoHttp from "./../images/SpidrontechLogo.png";

import { NavLink } from "react-router-dom";
import { LanguageContext} from "../components/AppContext";
import { home } from "../Utils/Models/index";
import EnFlag from "../images/flags/en.png";
import FrFlag from "../images/flags/fr.png";

const Footer = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [dataEn, setDataEn] = useState([]);
  const [dataFr, setDataFr] = useState([]);

  useEffect(() => {
    getFooterData();
  }, []);

  const onLngaugeUpdate = (event) => {
    setLanguage(event.target.value);
  };

  const getFooterData = () => {
    home
      .getFooterData()
      .then((res) => {
        setDataEn(res?.language?.en);
        setDataFr(res?.language?.fr);
      })
      .catch((error) => {
        console.log("Error while getting Home data", error);
      });
  };
  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <div className="All-Footer ">
      <div className="Footer-P1-bg">
        <div className="main-container">
          <div className="Footer-P1 ">
            <NavLink className="P1-link p1-1" to="/mentions-légales">
              {language == "Fr"
                ? dataFr?.footer_page_one_name
                : dataEn?.footer_page_one_name}
            </NavLink>
            {/* <NavLink
              className="P1-link p1-2"
              to="/politique-de-confidentialité"
            >
              {language == "Fr"
                ? dataFr?.footer_page_two_name
                : dataEn?.footer_page_two_name}
            </NavLink> */}
            <NavLink className="P1-link p1-3" to="/contact">
              {language == "Fr"
                ? dataFr?.footer_page_three_name
                : dataEn?.footer_page_three_name}
            </NavLink>
            <NavLink className="P1-link p1-3" exact to="/faq">
              {language == "Fr"
                ? dataFr?.footer_page_fourth_name
                : dataEn?.footer_page_fourth_name}
            </NavLink>
          </div>
        </div>
      </div>
      <div className="Footer-P2-bg">
        <div className="main-container">
          <div className="Footer-P2 ">
          
            <p>
              
              <a
                href="https://www.efficient-trading.com/"
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
                dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.footer_end_line)
                    : createMarkup(dataEn?.footer_end_line)
                }
              >
                
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
