import React, { useEffect, useState } from "react";
import { LanguageContext, UidContext } from "./components/AppContext";
import axios from "axios";
import Routes from "./components/Routes";
import { useDispatch } from "react-redux";
import { getUser } from "./actions/user.actions";
import { getUsers } from "./actions/users.actions";
import AutoLogouts from "./components/AutoLogouts";
import "moment/locale/fr";

// check si l'utilisateur est connecté en analysant le token (JWT) "Stockage"
const App = () => {
  const [uid, setUid] = useState(null);
  const [language, setLanguage] = useState(
    navigator.language.toLowerCase().includes("fr") ? "Fr" : "En"
  );
  const dispatch = useDispatch();
  console.log(navigator.language);
  useEffect(() => {
    const fetchToken = async () => {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}jwtid`,
        withCredentials: true,
      })
        .then((res) => {
          setUid(res.data);
        })
        .catch((err) => {
          console.log("No token, unconnected to server");
        });
    };
    fetchToken();

    if (uid) {
      dispatch(getUser(uid));
      dispatch(getUsers());
    }
  }, [uid, dispatch]);

  return (
    <UidContext.Provider value={uid}>
      <LanguageContext.Provider
        value={{ language: language, setLanguage: setLanguage }}
      >
        <Routes />
      </LanguageContext.Provider>
      {uid && <AutoLogouts />}{" "}
      {/**-------------To active auto logout if user logged in for serverdown and idle state */}
    </UidContext.Provider>
  );
};

export default App;
