import React, { useContext } from 'react';
import Thread from '../components/Thread';


// js est scss
import './../styles/temoignages.scss'
import { LanguageContext } from '../components/AppContext';
const Temoignages = () => {
    const { language } = useContext(LanguageContext);

    return (
        <div className="titre-page">
            <h1 style={{marginTop:'10px'}}>{language==='Fr'?'Témoignages':'Testimonials'}</h1>
            <div> 
                <Thread />
            </div>
        </div>
        
    );
};

export default Temoignages;