import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

// js et scss
import "./../styles/SignUpForm.scss";
import { NavLink } from "react-router-dom";

// images
import Connexion from "./../images/Connexion-SignUP.png";
import { home } from "../Utils/Models";
import { LanguageContext } from "../components/AppContext";
import Loader from "../components/Loader";

//Récupération InfoIP

const SignUpForm = () => {
  // Récupération adresse IP
  const [userIp, setIpAddress] = useState("");
  const fetchIp = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      setIpAddress(data.ip);
    } catch (error) {
      console.error(error);
    }
  };
  fetchIp();
  // hook des différentes infos lors de l'inscriptions
  const [formSubmit, setFormSubmit] = useState(false);
  const [lastName, setlastName] = useState("");
  const [firstName, setfirstName] = useState("");
  const [secondName, setsecondName] = useState("first");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [adress, setAdress] = useState("");
  const [complmtAdress, setComplmtAdress] = useState("");
  const [ville, setVille] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [etatProv, setEtatProv] = useState("");
  const [pays, setPays] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [categorieSP, setCategorieSP] = useState("");
  const [statut, setStatut] = useState("");
  const [raisonS, setRaisonS] = useState("");
  const [connu, setConnu] = useState("");
  const [broker, setBroker] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { language } = useContext(LanguageContext);
  const [loading, isLoading] = useState(false);
  const [dataEn, setDataEn] = useState([]);
  const [dataFr, setDataFr] = useState([]);

  useEffect(() => {
    getInscriptionData();
  }, []);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const terms = document.getElementById("terms");
    const pseudoError = document.querySelector(".pseudo.error");
    const emailError = document.querySelector(".email.error");
    const passwordError = document.querySelector(".password.error");
    const ipError = document.querySelector(".iperror");
    const passwordConfirmationError = document.querySelector(
      ".passwordconfirmation.error"
    );
    const termsError = document.querySelector(".terms.error");
    console.log("Passage par les vérification");
    passwordConfirmationError.innerHTML = "";
    termsError.innerHTML = "";

    if (password !== passwordConfirmation || !terms.checked) {
      if (password !== passwordConfirmation)
        passwordConfirmationError.innerHTML =
          language == "Fr"
            ? "Les mots de passe ne correspondent pas"
            : "Passwords do not match";

      if (!terms.checked)
        termsError.innerHTML =language == "Fr"? "Veuillez valider les conditions générales":"Please validate the general conditions";
    } else {
      //Message Console pour debug
      console.log("Passage par axios");
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}api/user/register`,
        data: {
          email,
          password,
          lastName,
          firstName,
          secondName,
          adress,
          complmtAdress,
          ville,
          codePostal,
          etatProv,
          pays,
          phone,
          age,
          categorieSP,
          statut,
          raisonS,
          connu,
          broker,
          pseudo,
          userIp,
        },
      })
        .then((res) => {
          if (res.data.errors) {
            //Message Console pour debug
            console.log("Passage red.data.errors");
            console.log(res.data.errors);
            res.data.errors.userIp &&
              (ipError.innerHTML =
                language != "Fr"
                  ? "Error: Already registered with same Ip address.(Please contact us if you think that this is an incorrect statement)"
                  : "Erreur : Déjà enregistré avec la même adresse IP. (Veuillez nous contacter si vous pensez qu'il s'agit d'une erreur)");
            emailError.innerHTML = res.data.errors.email;
            passwordError.innerHTML = res.data.errors.password;
            pseudoError.innerHTML = res.data.errors.pseudo;
          } else {
            //Message Console pour debug
            console.log("Submission au server");
            setFormSubmit(true);
          }
        })
        //Affichage de l'erreur
        .catch((err) => console.log(err));
    }
  };

  const getInscriptionData = () => {
    isLoading(true);
    home
      .getInscriptionData()
      .then((response) => {
        console.log("data of inscription", response);
        setDataEn(response?.language?.en);
        setDataFr(response?.language?.fr);
        isLoading(false);
      })
      .catch((error) => {
        console.log("Error while getting data", error);
      });
  };
console.log('status',statut);
  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <>
      {!loading ? (
        <div className="All-page">
          <div className="Form-head row">
            <div className="form-title">
              <h1
                dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.inscription_page_header_title)
                    : createMarkup(dataEn?.inscription_page_header_title)
                }
              ></h1>
            </div>
            <div className="head-button row">
              <div className="mt-2">
                <p
                  dangerouslySetInnerHTML={
                    language == "Fr"
                      ? createMarkup(dataFr?.inscription_page_header_register)
                      : createMarkup(dataEn?.inscription_page_header_register)
                  }
                ></p>
              </div>
              <div className="mt-2">
                <NavLink to="/login">
                  <img src={Connexion} alt="connexion" />
                </NavLink>
              </div>
            </div>
          </div>
          <div className="Form-desc">
            <p
              className="desc-grey"
              dangerouslySetInnerHTML={
                language == "Fr"
                  ? createMarkup(dataFr?.inscription_page_header_description)
                  : createMarkup(dataEn?.inscription_page_header_description)
              }
            />

            <br />
            <br />
            <p>
              {/* Si vous n'avez pas encore de compte Efficient Trading, vous avez la
          possibilité d'en créer un{" "}
          <span className="desc-Weight">en quelques minutes.</span>
          Les nouveaux adhérents bénéficient{" "}
          <span className="desc-Weight">
            d'une offre de bienvenue : deux jours d'accès complet aux
            différentes salles de marchés.
          </span>
          <br />
          <br />
          La création d'un compte{" "}
          <span className="desc-red">
            vous engage à effectuer un essai dans l’une des salles de trading
            disponibles.
          </span>
          <br />
          <br /> */}
              <span
                className="desc-grey"
                dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.inscription_page_header_note)
                    : createMarkup(dataEn?.inscription_page_header_note)
                }
              />
            </p>
            <div>
              {formSubmit ? (
                (window.location = "/login")
              ) : (
                <form
                  className="All-form"
                  method="get"
                  action=""
                  onSubmit={handleRegister}
                >
                  <div className="Titre-section">
                    <h2
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(dataFr?.inscription_page_form_id)
                          : createMarkup(dataEn?.inscription_page_form_id)
                      }
                    />
                  </div>
                  <div className="identifiants">
                    <div className="input-error">
                      <div className="input">
                        <label htmlFor="pseudo">
                          {language == "Fr" ? "Votre pseudo" : "Your username"}
                          <span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder={
                            language == "Fr" ? "ActionTrader" : "ActionTrader"
                          }
                          id="pseudo"
                          required
                          onChange={(e) => setPseudo(e.target.value)}
                          value={pseudo}
                        />
                      </div>
                      <div className="pseudo error"></div>
                    </div>
                    {/*Email*/}
                    <div className="input-error">
                      <div className="input">
                        <label htmlFor="Email">
                          {language == "Fr" ? "Adresse mail" : "Mail address"}
                          <span>*</span>
                        </label>
                        <input
                          type="email"
                          id="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          required
                        />
                      </div>
                      <div className="email error"></div>
                      <p>
                        {language == "Fr"
                          ? "Nous vous remercions d' utiliser en priorité une adresse gmail ou hotmail pour éviter les spams."
                          : "Please use a gmail or hotmail address as a priority to avoid spam."}
                      </p>
                    </div>
                    {/*password*/}
                    <div className="input-error">
                      <div className="input">
                        <label htmlFor="Mot de passe">
                          {language == "Fr" ? "Mot de passe" : "Password"}
                          <span>*</span>
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="************"
                          id="Mot de passe"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          required
                        />
                        <button className="mt-2" onClick={toggleShowPassword}>
                          {showPassword ? "Hide" : "Show"} Password
                        </button>
                      </div>
                      <div className="password error"></div>
                    </div>
                    <div className="input-error">
                      {/*Confimation password*/}
                      <div className="input">
                        <label htmlFor="Confimation mot de passe">
                          {language == "Fr"
                            ? "Confirmation mot de passe"
                            : "Password confirmation"}
                          <span>*</span>
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="************"
                          id="Confimation mot de passe"
                          onChange={(e) =>
                            setPasswordConfirmation(e.target.value)
                          }
                          value={passwordConfirmation}
                          required
                        />
                        <button className="mt-2" onClick={toggleShowPassword}>
                          {showPassword ? "Hide" : "Show"} Password
                        </button>
                      </div>
                      <div className="passwordconfirmation error"></div>
                    </div>
                  </div>

                  <div className="coordonnées Titre-section">
                    <h2
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(
                              dataFr?.inscription_page_form_contact_details
                            )
                          : createMarkup(
                              dataEn?.inscription_page_form_contact_details
                            )
                      }
                    />

                    <div className="All-input">
                      {/*Nom*/}

                      <div className="input">
                        <label htmlFor="Nom">
                          {language == "Fr" ? "Nom" : "Name"} <span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Dupond"
                          id="Nom"
                          required
                          onChange={(e) => setlastName(e.target.value)}
                          value={lastName}
                        />
                      </div>

                      {/*prénom*/}
                      <div className="input">
                        <label htmlFor="Prénom">
                          {language == "Fr" ? "Prénom" : "First name"}
                          <span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Michel"
                          id="Prénom"
                          required
                          onChange={(e) => setfirstName(e.target.value)}
                          value={firstName}
                        ></input>
                      </div>

                      {/*Deuxieme prénom*/}
                      <div className="input">
                        <label htmlFor="DeuxièmePrénom">
                          {language == "Fr" ? "Deuxième Prénom" : "Middle name"}{" "}
                          <span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Paul"
                          id="DeuxièmePrénom"
                          required
                          onChange={(e) => setsecondName(e.target.value)}
                          value={secondName}
                        ></input>
                      </div>
                    </div>

                    {/*address*/}

                    <div className="All-input">
                      <div className="input">
                        <label htmlFor="Adresse">
                          {language == "Fr" ? "Adresse" : "Address"}
                          <span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="3 rue des lilas"
                          id="Adresse"
                          required
                          onChange={(e) => setAdress(e.target.value)}
                          value={adress}
                        />
                      </div>

                      {/*Complément d'adresse*/}
                      <div className="input">
                        <label htmlFor="ComplémentAdresse">
                          {language == "Fr"
                            ? "Complément d'adresse"
                            : "Additional address"}
                        </label>
                        <input
                          type="text"
                          placeholder="Batiment 2"
                          id="ComplémentAdresse"
                          onChange={(e) => setComplmtAdress(e.target.value)}
                          value={complmtAdress}
                        />
                      </div>
                    </div>

                    {/*Ville*/}
                    <div className="All-input">
                      <div className="input">
                        <label htmlFor="Ville">
                          {language == "Fr" ? "Ville" : "City"} <span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Paris"
                          id="Ville"
                          required
                          onChange={(e) => setVille(e.target.value)}
                          value={ville}
                        />
                      </div>

                      {/*Code postal*/}
                      <div className="input">
                        <label htmlFor="CodePostal">
                          {language == "Fr" ? "Code Postal" : "Postal code"}
                          <span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="75001"
                          id="CodePostal"
                          required
                          onChange={(e) => setCodePostal(e.target.value)}
                          value={codePostal}
                        />
                      </div>

                      {/*Etat / province*/}
                      <div className="input">
                        <label htmlFor="ÉtatProvince">
                          {language == "Fr"
                            ? "État / Province"
                            : "State/Province"}{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="IDF"
                          id="ÉtatProvince"
                          onChange={(e) => setEtatProv(e.target.value)}
                          value={etatProv}
                        />
                      </div>
                    </div>
                    <div className="All-input">
                      {/*Pays*/}
                      <div className="input">
                        <label htmlFor="Pays">
                          {language == "Fr" ? "Pays" : "Country"}
                          <span>*</span>
                        </label>
                        <select
                          name="Pays"
                          id="Pays"
                          required
                          onChange={(e) => setPays(e.target.value)}
                          value={pays}
                        >
                          <option value=""></option>
                          {language == "Fr"
                            ? dataFr?.inscription_page_form_contact_details_pays_values?.map(
                                (val) => {
                                  return (
                                    <option value={val?.value}>
                                      {val?.value}{" "}
                                    </option>
                                  );
                                }
                              )
                            : dataEn?.inscription_page_form_contact_details_pays_values?.map(
                                (val) => {
                                  return (
                                    <option value={val?.value}>
                                      {val?.value}{" "}
                                    </option>
                                  );
                                }
                              )}
                        </select>
                      </div>

                      {/*Téléphone*/}
                      <div className="input">
                        <label htmlFor="Téléphone">
                          {language == "Fr" ? "Téléphone" : "Phone"}
                          <span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="0000000000"
                          id="Téléphone"
                          required
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                        />
                      </div>
                    </div>

                    {/*Age*/}
                    <div className="All-input">
                      <div className="input">
                        <label htmlFor="Age">
                          {language == "Fr" ? "Tranche D'age'" : "Age range"}
                        </label>
                        <select
                          id="Age"
                          onChange={(e) => setAge(e.target.value)}
                          value={age}
                        >
                          <option value=""></option>
                          {language == "Fr"
                            ? dataFr?.inscription_page_form_contact_details_tranche_values?.map(
                                (val) => {
                                  return (
                                    <option value={val?.value}>
                                      {val?.value}
                                    </option>
                                  );
                                }
                              )
                            : dataEn?.inscription_page_form_contact_details_tranche_values?.map(
                                (val) => {
                                  return (
                                    <option value={val?.value}>
                                      {val?.value}
                                    </option>
                                  );
                                }
                              )}
                        </select>
                      </div>

                      {/*Catégorie SP*/}
                      <div className="input">
                        <label htmlFor="Catégorie">
                          {language == "Fr"
                            ? "Catégorie socio-professionnelle"
                            : "Socio-Professional Category"}
                        </label>
                        <select
                          id="Catégorie"
                          onChange={(e) => setCategorieSP(e.target.value)}
                          value={categorieSP}
                        >
                          <option value=""></option>
                          {language == "Fr"
                            ? dataFr?.inscription_page_form_contact_details_category_values?.map(
                              (val) => {
                                return (
                                  <option value={val?.value}>
                                      {val?.value}
                                    </option>
                                  );
                                }
                                )
                                : dataEn?.inscription_page_form_contact_details_category_values?.map(
                                  (val) => {
                                    return (
                                      <option value={val?.value}>
                                      {val?.value}
                                    </option>
                                  );
                                }
                                )}
                        </select>
                      </div>
                    </div>
                    <div className="All-input">
                      {/*Statut*/}
                                {console.log('wertyu',dataFr?.inscription_page_form_contact_details_status_values)}
                      <div className="input">
                        <label htmlFor="Statut">
                          {language == "Fr" ? "Statut" : "Status"}
                          <span>*</span>
                        </label>
                        <select
                          id="Statut"
                          required
                          onChange={(e) => setStatut(e.target.value)}
                          value={statut}
                        >
                          <option value=""></option>
                          {language == "Fr"
                            ? dataFr?.inscription_page_form_contact_details_status_values?.map(
                                (val) => {
                                  return (
                                    <option value={val?.value}>
                                      {val?.value}
                                    </option>
                                  );
                                }
                              )
                            : dataEn?.inscription_page_form_contact_details_status_values?.map(
                                (val) => {
                                  return (
                                    <option value={val?.value}>
                                      {val?.value}
                                    </option>
                                  );
                                }
                              )}
                        </select>
                      </div>

                      {/*Raison Sociale*/}
                      <div className="input">
                        <label htmlFor="RaisonSociale">
                          {language == "Fr"
                            ? "Raison Sociale"
                            : "Social reason"}
                          {statut === (language === "Fr"?"Societe":"Company") && <span>*</span>}
                        </label>
                        <input
                          type="text"
                          placeholder="SARL"
                          id="RaisonSociale"
                          required={statut === (language === "Fr"?"Societe":"Company") ? true : false}
                          onChange={(e) => setRaisonS(e.target.value)}
                          value={raisonS}
                        />
                      </div>
                    </div>

                    {/*Connu*/}
                    <div className="All-input">
                      <div className="input">
                        <label htmlFor="Connu">
                          {language == "Fr"
                            ? "Comment nous avez-vous connus ?"
                            : "How did you know us?"}
                          <span>*</span>
                        </label>
                        <select
                          id="Connu"
                          required
                          onChange={(e) => setConnu(e.target.value)}
                          value={connu}
                        >
                          <option value=""></option>
                          {language == "Fr"
                            ? dataFr?.inscription_page_form_contact_details_how_did_you_know_us_values?.map(
                                (val) => {
                                  return (
                                    <option value={val?.value}>
                                      {val?.value}
                                    </option>
                                  );
                                }
                              )
                            : dataEn?.inscription_page_form_contact_details_how_did_you_know_us_values?.map(
                                (val) => {
                                  return (
                                    <option value={val?.value}>
                                      {val?.value}
                                    </option>
                                  );
                                }
                              )}
                        </select>
                      </div>

                      {/*Broker*/}
                      <div className="input">
                        <label htmlFor="Broker">
                          {language == "Fr" ? "Votre Broker" : "Your Broker"}
                          <span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder={
                            language == "Fr" ? "Votre Broker" : "Your Broker"
                          }
                          id="Broker"
                          required
                          onChange={(e) => setBroker(e.target.value)}
                          value={broker}
                        />
                      </div>
                    </div>
                    <p className="asterisque">
                      <span>*</span>
                      {language == "Fr"
                        ? "Les champs marqués par une astérisque sont requis"
                        : "Fields marked with an asterisk are required"}
                    </p>
                  </div>

                  {/*Checkbox conditions*/}
                  <div className="Bouton-Condition">
                    <input type="checkbox" id="terms"></input>
                    {/* J'accepte les{" "} */}
                    <label
                      htmlFor="terms"
                      style={{ textDecoration: "none" }}
                      dangerouslySetInnerHTML={
                        language == "Fr"
                          ? createMarkup(
                              dataFr?.inscription_page_form_contact_details_term_and_condition
                            )
                          : createMarkup(
                              dataEn?.inscription_page_form_contact_details_term_and_condition
                            )
                      }
                    ></label>
                    <div className="terms error"></div>
                  </div>
                  <div className="iperror"></div>
                  <div className="Form-button-bottom row ">
                    {/*Bouton reset*/}
                    <div className="comp1">
                      <input
                        type="reset"
                        placeholder="Reset"
                        id="reset"
                        value={
                          language == "Fr"
                            ? dataFr?.inscription_page_form_contact_details_button_one
                            : dataEn?.inscription_page_form_contact_details_button_one
                        }
                      />
                    </div>
                    <div className="comp2">
                      <input
                        type="submit"
                        placeholder="Enregistrer"
                        id="submit"
                        value={
                          language == "Fr"
                            ? dataFr?.inscription_page_form_contact_details_button_two
                            : dataEn?.inscription_page_form_contact_details_button_two
                        }
                      />
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader loading={loading} />
      )}
    </>
  );
};

export default SignUpForm;
