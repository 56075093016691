import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateParser, isEmpty } from "../Utils";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

// js et scss
import "./../../styles/Card.scss";
import { getPosts, updatePost } from "../../actions/post.action";
import { LanguageContext } from "../AppContext";
import moment from "moment";

const Card = ({ post, count }) => {
  const [isLoading, setIsLoading] = useState(true);
  const usersData = useSelector((state) => state.postReducer);
  const loggedInUser = useSelector((state) => state.userReducer);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(true);
  const dispatch = useDispatch();
  const { language } = useContext(LanguageContext);
  const [errorMsg, setErrorMsg] = useState(false);
  moment.locale(language);
  const onCloseModal = () => {
    setOpen(false);
    setEdit(true);
  };

  useEffect(() => {
    !isEmpty(usersData[0]) && setIsLoading(false);
  }, [usersData]);

  console.log("open", open);
  const handleChange = (e) => {
    let { name, value } = e?.target;
    if (name === "posterStatus" || name === "show_on_profile")
      value = value == 1 ? true : false;
    else if (name === "created_at") value = new Date(value);
    console.log("valueee", value);
    setOpen((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    edit
      ? setEdit(!edit)
      : open?.message_en && open?.message_fr
      ? dispatch(updatePost(open?._id, open))
          .then(() => {
            onCloseModal();
            dispatch(getPosts(count));
            setErrorMsg(false);
          })
          .catch((error) => console.log("Error while updating Reviews"))
      : setErrorMsg(true);
  };
  return (
    <>
      <li className="card-container" key={post._id}>
        {isLoading ? (
          <i className="fas fa-spinner fa-spin"></i>
        ) : (
          <>
            <div
              className="card"
              style={{ cursor: loggedInUser?.role === "admin" && "pointer" }}
              onClick={() => loggedInUser?.role === "admin" && setOpen(post)}
            >
              <div className="pseudo">
                <h3>
                  {post.firstName} {post.lastName}
                  {/* , {post.statut}, {post.etatProv}, {post.pays} */}
                </h3>

                {loggedInUser?.role === "admin" && (
                  <div className="aprroveBlk">
                    {post?.show_on_profile && (
                      <span style={{ marginRight: "10px", color: "yellow" }}>
                        (
                        {language === "Fr"
                          ? "Voir sur la page d'accueil"
                          : "View on Homepage"}
                        )
                      </span>
                    )}
                    <span>
                      {language === "Fr" ? "Approbation" : "Approval"}
                    </span>{" "}
                    <span
                      className="approvCircle"
                      style={{
                        backgroundColor: post?.posterStatus ? "green" : "red",
                      }}
                    ></span>
                  </div>
                )}
              </div>
              <div className="card-desc">
                <p className="card-date">
                  {moment((post.created_at)).format("MMMM YY")}
                </p>
                <p className="card-message">
                  {language === "Fr" ? post.message_fr : post.message_en}
                </p>
              </div>
            </div>
          </>
        )}
      </li>
      <style>
        {`
        .customOverlay {
          background: rgba(36, 123, 160, 0.7);
        }
        .customModal {
          background: #b2dbbf;
          max-width: 500px;
          width: 100%;
        }
        `}
      </style>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        styles={{ width: "500px" }}
      >
        <h2 style={{ marginTop: "20px" }}>
          {language === "Fr" ? "Détails du témoignage" : "Testimonal Details"}
        </h2>
        <hr />
        <div className="dataForm">
          <p style={{ textAlign: "end" }}>
            {language === "Fr" ? "Créé à" : "Created At"}:{" "}
            {dateParser(open.created_at)}
          </p>
          <label htmlFor="name">
            {language === "Fr" ? "Prénom" : "First Name"} :
          </label>
          <input
            type="text"
            name="firstName"
            value={open?.firstName}
            disabled={edit}
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="name">
            {language === "Fr" ? "Nom de famille" : "Last Name"} :
          </label>
          <input
            type="text"
            name="lastName"
            value={open?.lastName}
            disabled={edit}
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="name">
            {language === "Fr" ? "Statut" : "Status"} :
          </label>
          <span>{open?.statut}</span>
          <label htmlFor="message_fr">
            {language === "Fr" ? "Message (fr)" : "Message (fr)"} :
          </label>
          <textarea
            rows={5}
            name="message_fr"
            value={open?.message_fr}
            disabled={edit}
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="message_en">
            {language === "Fr" ? "Message (En)" : "Message (En)"} :
          </label>
          <textarea
            rows={5}
            name="message_en"
            value={open?.message_en}
            disabled={edit}
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="createdDate">
            {language === "Fr" ? "Date de création" : "Created Date"} :
          </label>
          {console.log("Created Date", open?.created_at)}
          <input
            type="date"
            value={moment(open?.created_at).format("YYYY-MM-DD")}
            name="created_at"
            onChange={(e) => handleChange(e)}
            disabled={edit}
          />
          <label htmlFor="name">
            {language === "Fr"
              ? "Approuver le témoignage"
              : "Approve Testimonial"}{" "}
            :
          </label>
          <div className="radio-blk">
            <div>
              <span>{language === "Fr" ? "Approuver" : "Approve"}?</span>
              <input
                type="radio"
                name="posterStatus"
                value={1}
                checked={open?.posterStatus}
                disabled={edit}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div>
              {language === "Fr" ? "Non approuvé" : "Not-Approve"}
              <input
                type="radio"
                name="posterStatus"
                value={0}
                checked={!open?.posterStatus}
                disabled={edit}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <label htmlFor="name">
            {language === "Fr"
              ? "Afficher sur la page d'accueil"
              : "Display on Homepage"}{" "}
            :
          </label>
          <div className="radio-blk">
            <div>
              <span>{language === "Fr" ? "Montrer" : "Show"}?</span>
              <input
                type="radio"
                name="show_on_profile"
                value={1}
                checked={open?.show_on_profile}
                disabled={edit}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div>
              {language === "Fr" ? "Cacher" : "Hide"}
              <input
                type="radio"
                name="show_on_profile"
                value={0}
                checked={!open?.show_on_profile}
                disabled={edit}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        <hr />
        {errorMsg && (
          <p style={{ color: "red" }}>
            {language === "Fr"
              ? "Message(En) ou Message(Fr) ne peuvent pas être vides"
              : "Message(En) or Message(Fr) cannot be empty"}
          </p>
        )}
        <div className="modalFooter">
          <button
            style={{ backgroundColor: edit ? "#0063a7" : "green" }}
            onClick={handleSave}
          >
            {!edit
              ? language === "Fr"
                ? "Sauvegarder"
                : "Save"
              : language === "Fr"
              ? "Modifier"
              : "Edit"}
          </button>
          <button style={{ backgroundColor: "red" }} onClick={onCloseModal}>
            {language === "Fr" ? "Fermer" : "Close"}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Card;
