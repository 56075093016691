import axios from "../axios";

import React from "react";

const Home = {
  getHomeData() {
    return new Promise((resolve, reject) => {
      axios
        .get("page/1")
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getLegalNoticeData() {
    return new Promise((resolve, reject) => {
      axios
        .get("page/3")
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getFaqsData() {
    return new Promise((resolve, reject) => {
      axios
        .get("page/4")
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getFooterData() {
    return new Promise((resolve, reject) => {
      axios
        .get("page/7")
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSubscrptionData() {
    return new Promise((resolve, reject) => {
      axios
        .get("page/6")
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getContactData() {
    return new Promise((resolve, reject) => {
      axios
        .get("page/2")
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTradeData() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`get`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  getTradeDataMonthly() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`get-months-data`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  setTradeData(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`store`, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  getInscriptionData() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("page/5")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  getSubcriptionData() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("page/6")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  getLoginData() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("page/8")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  getMyprofileData() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("page/9")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  sendEmail(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`api/user/send-email`, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};
export default Home;
