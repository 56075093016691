import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

// js et scss
import "./../styles/SignInForm.scss";
import { NavLink } from "react-router-dom";
import { LanguageContext } from "../components/AppContext";
import { home } from "../Utils/Models";
import Loader from "../components/Loader";
// images

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { language } = useContext(LanguageContext);
  const [isLangSwitch, setIsLangSwitch] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, isLoading] = useState(false);
  const [dataEn, setDataEn] = useState([]);
  const [dataFr, setDataFr] = useState([]);

  useEffect(()=>{
    getLoginData();
  },[])
  useEffect(() => {
    if (!isInitialRender) {
      setIsLangSwitch(true);
      setTimeout(() => {
        setIsLangSwitch(false);
      }, 1000);
    } else {
      setIsInitialRender(false);
    }
  }, [language]);

  const handleLogin = (e) => {
    e.preventDefault();
    const emailError = document.querySelector(".email.error");
    const passwordError = document.querySelector(".password.error");

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/user/login`,
      withCredentials: true,
      data: {
        email,
        password,
      },
    })
      .then((res) => {
        if (res.data.errors) {
          emailError.innerHTML = res.data.errors.email;
          passwordError.innerHTML = res.data.errors.password;
        } else {
          axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}api/user`,
            withCredentials: true,
          })
            .then((res) => {
              if (res.data.role === "admin") {
                window.location = "/admin";
              } else {
                window.location = "/";
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // -----To get login page data
  const getLoginData = () => {
    isLoading(true);
    home
      .getLoginData()
      .then((response) => {
        setDataEn(response?.language?.en);
        setDataFr(response?.language?.fr);
        isLoading(false);
      })
      .catch((error) => {
        console.log("Error while getting data", error);
      });
  };
  // ---Handle password show
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
// --For HTML to text conversion
  function createMarkup(data) {
    return { __html: data };
  }

  return (
    <>
      {isLangSwitch && (
        <div className="loader-background">
          <span className="loader-text">Switching...</span>
        </div>
      )}
      {!loading ? (<div className="All-page-form">
        <form action="" onSubmit={handleLogin} id="sign-up-form">
          <div className="title-connexion" dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.login_page_title)
                    : createMarkup(dataEn?.login_page_title)
                }/>
          <div className="placement-connexion">
            <label htmlFor="email" dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.login_page_email)
                    : createMarkup(dataEn?.login_page_email)
                }/>
             
          
            <div className="input-box">
              <input
                type="text"
                name="email"
                id="email"
                placeholder="paul.dupond@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="email error"></div>
            <br />
            <label htmlFor="password" dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.login_page_password)
                    : createMarkup(dataEn?.login_page_password)
                }/>
              
            <div className="input-box">
              {showPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  onClick={handleShowPassword}
                >
                  <path
                    fill="currentColor"
                    d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 1200 1200"
                  onClick={handleShowPassword}

                >
                  <path
                    fill="currentColor"
                    d="M669.727 273.516c-22.891-2.476-46.15-3.895-69.727-4.248c-103.025.457-209.823 25.517-310.913 73.536c-75.058 37.122-148.173 89.529-211.67 154.174C46.232 529.978 6.431 577.76 0 628.74c.76 44.162 48.153 98.67 77.417 131.764c59.543 62.106 130.754 113.013 211.67 154.174c2.75 1.335 5.51 2.654 8.276 3.955l-75.072 131.102l102.005 60.286l551.416-960.033l-98.186-60.008zm232.836 65.479l-74.927 129.857c34.47 44.782 54.932 100.006 54.932 159.888c0 149.257-126.522 270.264-282.642 270.264c-6.749 0-13.29-.728-19.922-1.172l-49.585 85.84c22.868 2.449 45.99 4.233 69.58 4.541c103.123-.463 209.861-25.812 310.84-73.535c75.058-37.122 148.246-89.529 211.743-154.174c31.186-32.999 70.985-80.782 77.417-131.764c-.76-44.161-48.153-98.669-77.417-131.763c-59.543-62.106-130.827-113.013-211.743-154.175c-2.731-1.324-5.527-2.515-8.276-3.807m-302.636 19.483c6.846 0 13.638.274 20.361.732l-58.081 100.561c-81.514 16.526-142.676 85.88-142.676 168.897c0 20.854 3.841 40.819 10.913 59.325c.008.021-.008.053 0 .074l-58.228 100.854c-34.551-44.823-54.932-100.229-54.932-160.182c.001-149.255 126.524-270.262 282.643-270.261m168.969 212.035L638.013 797.271c81.076-16.837 141.797-85.875 141.797-168.603c0-20.474-4.086-39.939-10.914-58.155"
                  />
                </svg>
              )}
              <input
                type={showPassword?"text":"password"}
                name="password"
                id="password"
                placeholder="*********"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <div className="password error"></div>
            <br />
            <a className="forgot-register" href="/reset-password" dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.login_page_forgot_password)
                    : createMarkup(dataEn?.login_page_forgot_password)
                }/>
             
            <NavLink
              to="/register"
              className="forgot-register nav-link"
              alt="pas-inscrit"
            >
              <span  dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.login_page_register)
                    : createMarkup(dataEn?.login_page_register)
                } />
                
            </NavLink>
            <br />
            <div className="input-box">
            <button
              id="btn-connexion"
              dangerouslySetInnerHTML={
                language == "Fr"
                  ? createMarkup(dataFr?.login_page_button_name)
                  : createMarkup(dataEn?.login_page_button_name)
              }
              
            />
            </div>
            <div className="note">
              <p dangerouslySetInnerHTML={
                  language == "Fr"
                    ? createMarkup(dataFr?.login_page_description)
                    : createMarkup(dataEn?.login_page_description)
                } />
                
            </div>
          </div>
        </form>
      </div>):(<Loader loading={loading} />)}
    </>
  );
};

export default SignInForm;
