import React, { useState } from 'react'
import '../styles/Loader.scss'

function Loader(props) {
   
  return (
    <>
    {props.loading&&<div style={{marginTop:'100px'}} className='loader'></div>}
    
   
</>
  )
}

export default Loader